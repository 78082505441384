import React from 'react'
import { cuteDate } from '../../utils/parseDate/formatDisplay'
import Button from '../../widgets/button'
import Edit from '../../widgets/icons/edit'
import PowerOff from '../../widgets/icons/powerOff'

const UserItem = ({user, handleUser}) => {
  
  const { name, email, birth_date, is_active } = user.attributes
  const { payment_id, created_at, due_date, tc_accepted_at } = user.attributes
  
  return (
    <tr>
      <td>{name}</td>
      <td>{email}</td>
      <td>{cuteDate(birth_date)}</td>
      <td>
        <input type="checkbox" readOnly checked={is_active} />
      </td>
      <td>-</td>
      <td>{payment_id ? payment_id : "N/A"}</td>
      <td>{cuteDate(created_at)}</td>
      <td>{cuteDate(due_date)}</td>
      <td>{cuteDate(tc_accepted_at)}</td>

      <td className="" onClick={()=> handleUser.edit(user)}>
        <Button color="info">
          <Edit />
        </Button>
      </td>

      <td className="" onClick={()=> handleUser.desactivate(user)}>
        <Button color={is_active ? "success" : "dark"}>
          <PowerOff color='#fff' />
        </Button>
      </td>
    </tr>
  )
}

export default UserItem;