import { getUserByEmail } from "../../../helpers/getDashboardData"
import getUrlParameter from "../../../helpers/getUrlParameter"

export const getStatus = () => ({
  status: getUrlParameter('status'),
  payment_id: getUrlParameter('payment_id'),
})

export const getUser = async (userEmail) => {
  const { attributes: { updated_at, created_at, ...rest }, id } = await getUserByEmail(userEmail)
  return { data: rest, id }
}