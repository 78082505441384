import React from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrencyFormat } from '../../utils'
import { cuteDate } from '../../utils/parseDate/formatDisplay'
import Button from '../../widgets/button'

const BillItem = ({ voucher, createPDF, setSelectedVoucher }) => {

  const history = useHistory()

  const { numeroComprobante, fechaEmision, importeTotal } = voucher
  const { numeroPuntoVenta, codigoTipoComprobante } = voucher

  const ptoVenta = `ptoVenta=${numeroPuntoVenta}`
  const tipoComprobante = `tipoComprobante=${codigoTipoComprobante}`

  const pathToGo = `/facturaciones/${numeroComprobante}?${ptoVenta}&${tipoComprobante}`
  
  const handleDownload = () => {
    setSelectedVoucher(voucher)
    setTimeout(() => createPDF(), 1000)
  }
  
  return (
    <>
      <li>{numeroComprobante}</li>
      <li>{cuteDate(fechaEmision)}</li>
      <li>-</li>
      <li>{getCurrencyFormat(importeTotal)}</li>
      <li className="actions">
        <Button
          text="Ver más detalles"
          color="success py-2 rounded-pill"
          className="flex-fill"
          handleClick={() => history.push(pathToGo)}
        />
        <Button
          text="Descargar Factura"
          color="primary py-2 rounded-pill"
          className="flex-fill"
          handleClick={handleDownload}
        />
      </li>
    </>
  )
}

export default BillItem