import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../widgets/button'

const FormFooterButtons = ({ loading }) => {
  return (
    <div className="Form-Buttons">
      <Button
        type="submit"
        color="secondary"
        loading={loading}
        text="Guardar"
      />

      <Link to="/planes">
        <Button text="Volver" color="danger" />
      </Link>
    </div>
  )
}

export default FormFooterButtons