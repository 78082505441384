import React, { useState, useRef } from 'react'
import InputModal from '../../../widgets/inputModal';
import Button from '../../../widgets/button';
import GuestListItem from './guestListItem';

const WorkshopAddGuests = ({ emails, setEmails }) => {

  const input = useRef(null)
  const [inputtedEmail, setInput] = useState("")

  const handleAddGuest = () => {
    const { value: newGuest } = input.current

    if (!!emails.find(email => email === newGuest)) {

      alert("invitado ya ingresado")

    } else {
      setEmails([...emails, newGuest])
      setInput("")
    }
  }

  const deleteGuest = (guest) => {
    setEmails(emails.filter(email => email !== guest))
  }

  return (
    <div className="workshop-guests">
      <div className="addGuests-inputs">
        <InputModal
          id="emails"
          setRefInputs={input}
          type="email"
          placeholder="correo@gmail.com"
          title="Añadir Invitados"
          className="form-control"
          handleChange={() => { setInput(input.current.value) }}
          value={inputtedEmail}
        />
        <Button
          text="Añadir"
          handleClick={handleAddGuest}
          color="info"
          notLoading
        />
      </div>
      <ul>
        {emails.map(email => 
          <GuestListItem key={email} email={email} handleDelete={deleteGuest} />  
        )}
      </ul>
    </div>
  )
}

export default WorkshopAddGuests;
