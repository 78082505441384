import React, { useState } from 'react'
import { verifyPayment_id, savePayment_id } from '../../helpers/saveToLocalStorage'
import { updateUser } from '../../helpers/sendData'
import { getAdmins, getPlanByID, getUserByEmail } from '../../helpers/getDashboardData';
import { messageToSend, objectToSend } from '../../widgets/utils/objectToSend'
import { sendNotificationToAdmin } from '../../helpers/sendEmails'
import { getStatus, getUser } from './utils/getValues'
import FinishModal from '../../widgets/finishModal'
import SuccessIcon from '../../widgets/icons/success-icon';
import CreditCardIcon from '../../widgets/icons/creditCard-icon'
import WarningIcon from '../../widgets/icons/warning'

const StatusModal = ({ handleClose }) => {

  const [userEmail, setUserEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const sendNotifications = async (user, planName) => {
    //debugger
    try {
      const admins = await getAdmins()
      const attributes = {
        subject: "Renovación de usuario en Vincor Online",
        payment_status: status === 'approved' ? 'aprobado' : 'pendiente',
        plan_name: planName,
        user_name: user.name
      }

      for (const admin of admins) {
        try {
          attributes.admin_name = admin.attributes.name
          const body = messageToSend('admin_user_renewal', admin.attributes.email, attributes)
          await sendNotificationToAdmin(body)

        } catch (error) {
          console.log(error)
          console.log('Hubo un error al notificarle a un admin')
        }
      }
    } catch (error) {
      console.log(error)
      console.log('Hubo un error al notificarle a los admins')
    }
  }

  const updateUser_SendEmail = async (payment_id, statusMessage) => {
    setLoading(true)
    debugger
    try {
      const user = await getUser(userEmail)
      user.data.payment_id = parseInt(payment_id);

      //Se actualiza el usuario
      const body = objectToSend('users', user.data)
      await updateUser(user.id, body)

      //debugger
      //Se envian las notificaciones a los administradores
      const plan = await getPlanByID(user.data.plan_id)
      await sendNotifications(user.data, plan.attributes.name)

      //Se guarda en el localStorage para que impide que el usuario
      // recargue la pagina y vuelva a ver el modal
      savePayment_id(payment_id)

      //Se lanza la notificacion
      alert(statusMessage)

      //Se cierra el modal y termina la carga
      setLoading(false)
      handleClose()
    } catch (error) {
      console.log(error)
      alert('Ha habido un error con el registro del id de su pago, por favor intentelo de nuevo')
    }
    setLoading(false)
  }

  const handle = {
    success: async (event) => {
      event.preventDefault();
      console.log("hola")
      updateUser_SendEmail(payment_id, 'El id de pago se ha registrado en su cuenta con éxito')
    },
    pending: async (event) => {
      event.preventDefault();
      updateUser_SendEmail(payment_id, 'El id de pago se ha registrado en su cuenta con éxito')
    },
    rejected: async (event) => {
      event.preventDefault();
      setLoading(true)
      //debugger
      try {
        const user = await getUserByEmail(userEmail);
        const { previous_plan_id, previous_due_date } = user.attributes;
        user.attributes.plan_id = previous_plan_id;
        user.attributes.due_date = previous_due_date;

        const body = objectToSend('users', user.attributes)
        await updateUser(user.id, body)

        //Se guarda en el localStorage para que impide que el usuario
        // recargue la pagina y vuelva a ver el modal
        savePayment_id(payment_id)

        alert('Su usuario ha vuelto su estado anterior')

      } catch (error) {
        console.log(error)
        console.log('Ha ocurrido un error al actualizar su usuario, por favor intentelo de nuevo')
      }
      setLoading(false)
    }
  }

  const handleChange = ({ target }) => {
    setUserEmail(target.value)
  }

  const { status, payment_id } = getStatus();

  if (status && verifyPayment_id(payment_id)) {

    const modalProps = {
      loading,
      userEmail,
      handleChange,
    }
    
    const finish = {
      success: {
        ...modalProps,
        btnColor: "success",
        handleConfirm: handle.success,
        message: "Su pago se ha realizado con éxito, ingrese su correo para registar su id de pago",
      },
      pending: {
        ...modalProps,
        btnColor: "warning",
        handleConfirm: handle.pending,
        message: "Su pago se está procesando, le enviaremos un email cuando su usuario esté activo. Ingrese su correo para registrar su pago",
      },
      rejected: {
        ...modalProps,
        btnColor: "danger",
        handleConfirm: handle.rejected,
        message: "Su pago se ha rechazado, ingrese su correo para volver a su plan anterior",
      },
    }

    if (status === "approved") {
      return (
        <FinishModal {...finish.success} >
          <SuccessIcon color="#20c997" size={100} />
        </FinishModal>
      )
    }

    if (status === "pending") {
      return (
        <FinishModal {...finish.pending} >
          <WarningIcon color="#ffc107" size={100} />
        </FinishModal>
      )
    }

    if (status === "rejected") {
      return (
        <FinishModal {...finish.rejected} >
          <CreditCardIcon color="#dc3545" size={100} />
        </FinishModal>
      )
    }
  }

  return null;
}

export default StatusModal;
