import React, {Component} from 'react';
import Alert from 'reactstrap/lib/Alert';
import RegisterLayout from '../../layout/registerLayout';
import ResetPasswordForm from './restPasswordForm';
import {getUserByID} from '../../helpers/getDashboardData';
import {updateUser} from '../../helpers/sendData';
import {objectToSend} from '../../widgets/utils/objectToSend'

class ResetPassword extends Component {

  state = {
    user:{},
    password:{
      new: '',
      again: '',
    },
    formVisible: false,
    loading: true,
    errorUser: false,
    errorMessage: '',
    registerSuccess: false,
    registrationError: false,
    successMessage: ''
  }
  
  componentDidMount(){
    const { userID } = this.props.match.params
    this.getUser(userID)
  }
  
  getUser = async(userID) =>{
    try {
      const user = await getUserByID(userID)
      
      this.setState({
        user,
        formVisible: true,
        loading: false,
      })
      
    } catch (error) {
      console.log(error)
      
      if(error.message === 'Network Error'){
        this.setState({
          errorUser: true,
          loading: false,
          errorMessage: 'Ha ocurrido un error en la conexión',
        })
        
      }else{
        
        this.setState({
          errorUser: true,
          loading: false,
          errorMessage: '¡¡¡Usuario invalido!!!',
        })
      }
    }
  }
  
  handleSubmit = async(event) =>{
    event.preventDefault()
    this.setLoading(true)
    const { user, password } = this.state
    
    if(password.new.length > 6 && password.again.length > 6){
      if(password.new === password.again){
        
        const {created_at, updated_at, ...rest} = user.attributes
        const attributes = { ...rest, password: password.new }
        const body = objectToSend('users', attributes)
              
      //debugger
        try {
          await updateUser(user.id, body)        
          this.setLoading(false, 'success', 'La contraseña se ha actualizado con éxito')
          
        } catch (error) {
          this.setLoading(false, 'error', 'Ha habido un error con el reestablecimiento de la contraseña')
        }
  
      }else{
        this.setLoading(false, 'error', 'Las contraseñas no son iguales')
      }
      
    }else{
      this.setLoading(false, 'error', 'La contraseña debe tener un minimo de 6 caracteres')
    }
  }
  handleInvalid = () => this.form.classList.add('was-validated')
  
  handleChange = ({target}) =>{
    
    this.setState({
      password:{
        ...this.state.password,
        [target.name]: target.value
      }
    })
    
    if(target.value.length > 6 ){
      this.setLoading(false, 'success', 'Cumple el minimo de caracteres')
    }else{
      this.setLoading(false, 'error', 'La contraseña debe tener un minimo de 6 caracteres')
    }
    
  }
  
  setLoading = (loading, state, message) =>{
    this.setState({
      registerSuccess: false,
      registrationError: false,
    })
    if(state === 'success'){
      this.setState({
        loading,
        registerSuccess: true,
        successMessage: message
      })
    }else if(state === 'error'){
      this.setState({
        loading,
        registrationError: true,
        errorMessage: message,
      })
    }else{
      this.setState({ loading })
    }
  }
  
  setRef = element =>{
    this.form = element
  }
  
  render() {
    const { registerSuccess, registrationError, errorUser, errorMessage } = this.state
    const { successMessage, loading, formVisible, password } = this.state
    return (
      <RegisterLayout>
        {
          formVisible &&
          <ResetPasswordForm
            setRef={this.setRef}
            handleSubmit={this.handleSubmit}
            handleInvalid={this.handleInvalid}
            handleChange={this.handleChange}
            password={password}
            loading={loading}
            registerSuccess={registerSuccess}
            registrationError={registrationError}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        }
        {
          errorUser && 
          <Alert style={{width: '300px'}} className="text-center" color="danger">
            {errorMessage}
          </Alert>
        }
        {
          loading && !formVisible  &&
          <Alert style={{width: '300px'}} className="text-center" color="dark">
            Cargando...
          </Alert>
        }
      </RegisterLayout>
    )
  }
}

export default ResetPassword;

