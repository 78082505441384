import React, { useEffect, useState } from 'react';
import Layout from '../../layout';
import { Card, Alert } from 'reactstrap';
import WidthLimit from '../../layout/widthLimit';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createPlan, updatePlan } from '../../helpers/sendData';

import { objectToSend } from '../../widgets/utils/objectToSend';
import { createButton, makePaymentButton, makeRenewalButton } from '../../helpers/createButton';
import { currencyType } from './utils/currencyType';
import { getPlanByID } from '../../helpers/getDashboardData';
import EditPlansForm from './editPlansForm';

const { REACT_APP_ADMIN_URL } = process.env;
const EditPlans = (props) => {
  const { history, match } = props

  const INFINITE_SESSIONS = 32000;

  const { dolar, pesoArgentino } = currencyType

  const [plan, setPlan] = useState({
    name: '',
    description: '',
    guests_limit: 0,
    price: 1,
    //Cantidad de sesiones por plan
    sessions_limit: 1,
    scope_days: 0,
    currency: "",
    payment_url: '',
    is_free: false,
    is_renewable: true,
    //Máximo de horas por sesión
    sessions_max_duration: 1, // It must be parsed to int number when it's sent to the API
    is_sessions_limitless: false,
  })

  const [status, setStatus] = useState({
    success: null,
    error: null,
    message: '',
  })

  const [stringLimit, setStringLimit] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      const { params: { id } } = match;

      if (id !== 'add') {

        const plan = await getPlanByID(id)
        const { created_at, updated_at, ...all } = plan.attributes

        setPlan({
          ...all,
          guests_limit: parseFloat(all.guests_limit),
          price: parseFloat(all.price),
        })
      }

    })()
  }, [])

  const handleStatus = (status, message) => {
    if (status) {
      setStatus({
        success: true,
        error: null,
        message,
      })
    } else {
      setStatus({
        success: null,
        error: true,
        message,
      })
    }
  }

  const handleForm = {
    submit: async (event) => {
      event.preventDefault()
      setLoading(true)

      // const plan = {...plan}
      const { id } = match.params;
      const { currency } = plan

      let orderedPlans = [...props.orderedPlans];

      try {
        if (plan.is_free) {

          plan.price = 0;
          plan.payment_url = REACT_APP_ADMIN_URL;

        } else {

          if (currency === dolar.code) {

            plan.payment_url = ""
            plan.payment_renewal_url = ""

          } else if (currency === pesoArgentino.code) {
            debugger
            //Payment Button
            let infoToCreateButton = makePaymentButton(plan.name, plan.price);
            plan.payment_url = await createButton(infoToCreateButton);

            //Renewal Button
            infoToCreateButton = makeRenewalButton(plan.name, plan.price);
            plan.payment_renewal_url = await createButton(infoToCreateButton);
          }
        }

        const sessions_max_duration = parseInt(plan.sessions_max_duration)

        const body = objectToSend('plans', {
          ...plan, sessions_max_duration
        });

        if (id === 'add') {

          //If it's successfull then a message is displayed
          body.data.attributes.display_order = (orderedPlans.length + 1)
          const newPlan = await createPlan(body)

          console.log(newPlan)

          handleStatus(true, 'El plan se creó correctamente')

          //Later It sends to Planes page
          setTimeout(() => history.push('/planes'), 2000)
        } else {

          //If it's successfull then a message is displayed
          const updatedPlan = await updatePlan(body, id)
          handleStatus(true, 'El plan se actualizó correctamente')

          console.log(updatedPlan)
        }

      } catch (error) {
        console.log(error)
        console.log(error.message)
        handleStatus(false, 'Ha habido un error!')
      }

      setLoading(false)
    },
    change: ({ target }) => {
      const { name, type, value } = target;

      const setPlanState = (name, value) => {
        setPlan({
          ...plan, [name]: value
        })
      }

      const numberDefaultAction = () => {
        setPlanState(name, parseFloat(value))
      }

      if (type === "number") {
        numberDefaultAction()
      } else {

        const stringInputs = {
          scope_days: () => {
            numberDefaultAction()
          },
          description: () => {
            debugger
            if (value.length >= 512) {
              setPlanState(name, plan.description);
              setStringLimit(true)

            } else {
              setPlanState(name, value);
              setStringLimit(false)
            }
          },
          is_renewable: () => {
            const newValue = !plan.is_renewable
            setPlanState(name, newValue)
          },
          is_free: () => {
            setPlan({
              ...plan,
              [name]: !plan.is_free,
              currency: pesoArgentino.code
            })
          },
          is_sessions_limitless: () => {
            const newValue = !plan.is_sessions_limitless
            setPlan({
              ...plan,
              [name]: newValue,
              sessions_limit: newValue ? INFINITE_SESSIONS : 1,
            })
          },
          defaultAction: () => {
            setPlanState(name, value);
          }
        }

        const found = !!stringInputs[name]

        if (found) {
          stringInputs[name]()
        } else {
          stringInputs.defaultAction()
        }
      }
    }
  }

  const { id } = match.params;
  const { success, error, message } = status;

  return (
    <Layout>
      <WidthLimit>
        <div className="editPlans">
          <Card className="p-4">

            <h4 className="card_title">{id === 'add' ? 'Crear Plan' : 'Editar Plan'}</h4>

            <EditPlansForm
              plan={plan}
              loading={loading}
              handleForm={handleForm}
              stringLimit={stringLimit}
              INFINITE_SESSIONS={INFINITE_SESSIONS}
            />

            {
              success &&
              <Alert color="success"> {message} </Alert>
            }

            {
              error &&
              <Alert color="danger"> {message} </Alert>
            }
          </Card>
        </div>
      </WidthLimit>
    </Layout>

  );
}

const mapStateToProps = ({ Login: { orderedPlans } }) => {
  return {
    orderedPlans: orderedPlans || []
  }
}

export default withRouter(connect(mapStateToProps)(EditPlans));