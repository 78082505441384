// import axios from "axios"
import {API_MERCADO_PAGO} from "../api/apiURLs";
import {success_payment_url, failure_payment_url, pending_payment_url} from "../api/apiURLs";
import {success_renewal_url, failure_renewal_url, pending_renewal_url} from "../api/apiURLs";

// export const createPaymentButton = async (body) =>{
//   const response = await axios.post(API_MERCADO_PAGO, body)
//   const { body:{ body:{ init_point }}} = response;
//   return init_point;
// }

export const createButton = async (body) =>{
  const response = await fetch(API_MERCADO_PAGO,{
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
  // debugger
  const data = await response.json()
  const { body:{ body:{ init_point }}} = data;
  return init_point;
}

export const makePaymentButton = (description, price) =>{
  return{
    description,
    price,
    quantity: 1,
    success_url: success_payment_url,
    failure_url: failure_payment_url,
    pending_url: pending_payment_url,
  }
}
export const makeRenewalButton = (description, price) =>{
  return{
    description,
    price,
    quantity: 1,
    success_url: success_renewal_url,
    failure_url: failure_renewal_url,
    pending_url: pending_renewal_url,
  }
}