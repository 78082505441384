import React from 'react';
import {createPortal} from 'react-dom';

const $modals = document.getElementById('modals')

const CustomModal = ({zIndex, className, type, children}) =>{
  return createPortal(
    <div className={`Modal ${className}`} style={{zIndex}}>
      <div className={`modal-container ${type}`}>
        {
          children
        }
      </div>
    </div>
  , $modals)
};

export default CustomModal;