import SEARCH_USER_TABLE from './actionTypes';
import { getState_from_LocalStorage } from '../../helpers/utils';
import { setState } from '../utils';

let initialState = {
  user_search: []
}

const FIELD = 'Users'

if (localStorage.Users) {
  initialState = getState_from_LocalStorage(FIELD)
}

const usersReducer = (state = initialState, action) => {
  const commonAction = {
    [SEARCH_USER_TABLE]: "user_search"
  }

  const customAction = {}

  return setState(state, action, FIELD, commonAction, customAction);
}

export default usersReducer;