import { cuteDate, cuteDateUS } from "../../../utils/parseDate/formatDisplay"
import { getNewDueDate } from "./getNewDueDate"
import { messageToSend, objectToSend } from "../../../widgets/utils/objectToSend"

const TODAY = new Date()
const { REACT_APP_ADMIN_EMAIL } = process.env;


export const getRenewalFormat = ({ newPlan, user, id_document }) => {

  const {
    created_at, updated_at, plan,                  // Not used
    plan_id, due_date, available_sessions, ...rest // Used
  } = user.attributes

  
  const { scope_days, sessions_limit } = newPlan.attributes
  
  const new_dueDate = getNewDueDate(scope_days)

  const attributes = {
    ...rest,
    id_document: id_document.toString(),
    is_active: true,

    previous_plan_id: plan_id,
    previous_due_date: due_date,
    previous_available_sessions: available_sessions,

    plan_id: newPlan.id,
    due_date: new_dueDate,
    available_sessions: sessions_limit,
  }

  const body = objectToSend('users', attributes)
  return body;
}

export const getBillingFormat = (params) => {

  const { newPlan, user, id_document, billingOptions, billingAttributes } = params

  const { scope_days } = newPlan.attributes

  const { salesPoints } = billingOptions
  const new_dueDate = getNewDueDate(scope_days)

  const body = {
    email: user.attributes.email,
    observaciones: "",

    codigoConcepto: 2, // siempre será 2, que corresponde a servicios.
    ...billingAttributes,

    numeroDocumento: id_document,
    numeroPuntoVenta: parseInt(salesPoints[0].id), //Se selecciona el primero en la lista de ptsVenta

    fechaServicioDesde: cuteDateUS(TODAY),
    fechaServicioHasta: cuteDateUS(new_dueDate),

    items: [{
      codigo: "ADM001",
      descripcion: newPlan.attributes.name,
      precioUnitario: newPlan.attributes.price,
    }]
  }

  return body;
}

export const getBillingSuccess = ({ newPlan, user, id_document, billingResponse, voucherType }) => {

  const { email } = user.attributes
  const voucherTypeLetter = voucherType.label.split(" ")[1]
  
  const { CAE, fechaVencimientoCAE, numeroComprobante } = billingResponse.comprobanteResponse
  
  const attributes = {
    subject: "Factura",

    nro_factura: numeroComprobante,
    fecha_factura: cuteDate(TODAY),

    letra_tipo_comprobante: voucherTypeLetter,
    tipo_comprobante: voucherType.label,

    user_name: email,
    dni: id_document.toString(),

    cantidad: "1",
    precio_unitario: newPlan.attributes.price,
    categoria_iva: "Responsable inscripto",

    importe_iva: "21.00",
    importe_total_item: "121.00",
    importe_total: "121.00",

    cae: CAE.toString(),
    fecha_vencimiento_cae: cuteDate(fechaVencimientoCAE),

    codigo_articulo: "ADM001",
    nombre_articulo: newPlan.attributes.name,
  }

  const body = messageToSend("invoice", email, attributes)
  return body;
}

export const getBillingFail = ({ newPlan, user, id_document }) => {

  const { name, email } = user.attributes

  const attributes = {
    subject: "Factura fallida",
    admin_name: "Administrador Vincor",

    email: email,
    user_name: name,
    id_document: id_document.toString(),

    codigo_articulo: "ADM001",
    nombre_articulo: newPlan.attributes.name,
    precio_unitario: newPlan.attributes.price.toString(),
  }
  
  const body = messageToSend("failed_invoice", REACT_APP_ADMIN_EMAIL, attributes)
  return body;
}