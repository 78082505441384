export const CHECK_LOGIN = 'check_login';
export const SET_USER_SUCCESSFUL = 'set_user_successfull';
export const UPDATE_LOGGED_USER = 'update_logged_user';
export const NOTIFY_LOGIN_SUCCESSFUL = 'notify_login_successfull';
export const LOGOUT = 'logout';

export const SET_LOADING = 'set_loading';
export const SET_PLANS_AT_STORE = 'set_plans_at_store';
export const SET_TOTAL_USERS_AT_STORE = 'set_total_users_at_store';
export const SET_TOTAL_GUEST_AT_STORE = 'set_total_guests_at_store';
export const SET_GUESTS_AT_STORE = 'set_guests_at_store';
export const SET_USER_IN_LOCAL_STORAGE = 'set_user_in_local_storage';

export const API_FAILED = 'api_failed';
export const KNOWN_TYPE_OF_ERROR = 'known_type_of_error';