export const getCurrencyFormat = (number) => {
  // const currency = new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "ARS"
  // }).format(number)
  const currency = new Intl.NumberFormat().format(number)

  return `$ ${currency}`;
  //Output should be $ 100.000
}

export const getTypeByLabel = (labelName, types) => {
  const type = types.find(({ label }) =>
    label.toLocaleLowerCase() === labelName.toLocaleLowerCase()
  )
  return type;
}