import axios from 'axios';
import { API, workshopUrlRequest, userUrlRequest, guestUrlRequest, plans } from '../api/apiURLs';

export const createWorkshop = async (body) => {
  const { data: { data: workshop } } = await axios.post(`${API}${workshopUrlRequest}`, body)
  //debugger
  return workshop;
}
export const updateWorkshop = async (body, workshopID) => {
  const { data: { data: workshop } } = await axios.put(`${API}${workshopUrlRequest}/${workshopID}`, body)
  return workshop;
}
export const deleteWorkshop = async (workshopID) => {
  await axios.delete(`${API}${workshopUrlRequest}/${workshopID}`)
}

export const createPlan = async (body) => {
  const { data: { data: plan } } = await axios.post(`${API}${plans}`, body)
  return plan;
}
export const updatePlan = async (body, userID) => {
  const { data: { data: plan } } = await axios.put(`${API}${plans}/${userID}`, body);
  return plan;
}
export const deletePlan = async (userID) => {
  await axios.delete(`${API}${plans}/${userID}`);
}


export const updateUser = async (userID, body) => {
  const { data: { data: user } } = await axios.put(`${API}${userUrlRequest}/${userID}`, body)
  return user;
}

export const savePlans = async (body) => axios.put(`${API}${plans}`, { data: body })


export const createGuest = async body => {
  const { data: { data: guest } } = await axios.post(`${API}${guestUrlRequest}`, body)
  return guest;
}
export const updateGuest = async (guestID, body) => await axios.put(`${API}${guestUrlRequest}/${guestID}`, body)

export const inviteGuest = async (workshopID, body) => {
  const { data: { data: { attributes: { sign_in_code } } } } = await axios.post(`${API}${workshopUrlRequest}/${workshopID}${guestUrlRequest}`, body)
  return sign_in_code;
}