import React from 'react'
import UpdatePlanButton from './updatePlanButton';
import DeletePlanButton from './deletePlanButton';
import OrderButton from './orderButton';
import Button from '../../widgets/button';
import PowerOff from '../../widgets/icons/powerOff';
import { updatePlan } from '../../helpers/sendData';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { cuteDate } from '../../utils/parseDate/formatDisplay';

const PlanRow = (props) => {

  const { handleDeleteAlert } = props;
  const { plan, orderedPlans, setChangingOrder, setOrderedPlans } = props;

  const { name, guests_limit, price, display_order } = plan.attributes;
  const { is_free, is_active, currency, sessions_limit, updated_at } = plan.attributes;

  const setOrder = (orderedPlans, save) => {
    setChangingOrder(save)
    setOrderedPlans(orderedPlans)
  };

  const handleActive = async () => {
    try {

      const planToUpdate = { ...plan.attributes, is_active: !is_active }

      delete planToUpdate.created_at;
      delete planToUpdate.updated_at;

      const body = objectToSend("plans", planToUpdate)

      const updatedPlan = await updatePlan(body, plan.id)

      const newOrder = orderedPlans.map(plan => (plan.id === updatedPlan.id) ? updatedPlan : plan)
      setOrderedPlans(newOrder)

      alert(`El plan se ha ${!is_active ? "activado" : "descativado"} correctamente!.`)

    } catch (error) {
      console.log(error)
      console.log(error.message)

      alert(`Hubo un error al actualizar el plan "${name}"`)
    }
  }

  return (
    <tr>
      <th scope="row">{display_order}</th>
      <th scope="row">{name}</th>
      <td>{guests_limit}</td>
      <td>{sessions_limit}</td>
      <td>{currency}</td>
      <td>{is_free ? "Gratis" : `$${price}`}</td>

      <td>
        {cuteDate(updated_at)}
      </td>

      <td>
        <ul className="d-flex justify-content-center align-items-center">

          <li className="mr-3">
            <Button
              handleClick={handleActive}
              color={is_active ? "success" : "dark"}
            >
              <PowerOff color='#fff' />
            </Button>
          </li>

          <li className="mr-3">
            <UpdatePlanButton planID={plan.id} />
          </li>

          <li className="mr-3">
            <DeletePlanButton
              handleDeleteAlert={handleDeleteAlert}
              planName={name}
              planID={plan.id}
            />
          </li>

          <li className="btn-group">
            <OrderButton
              direction="up"
              plan={plan}
              setOrder={setOrder}
              orderedPlans={orderedPlans}
              disabled={display_order - 1 === 0}
            />

            <OrderButton
              direction="down"
              plan={plan}
              setOrder={setOrder}
              orderedPlans={orderedPlans}
              //Si es el display_order del elemento es igual a la cantidad de elementos significa que es el último.
              //Por lo tanto se deshabilita la posibilidad de seguir bajandolo de puesto.
              disabled={display_order === orderedPlans.length}
            />
          </li>

        </ul>
      </td>
    </tr>
  )
}

export default PlanRow;
