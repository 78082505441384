export const REGISTER_GUEST = 'register_guest';
export const REGISTER_GUEST_SUCCESSFUL = 'register_guest_successfull';
export const REGISTER_GUEST_FAILED = 'register_guest_failed';
export const REGISTER_RESET = 'register_reset';

export const CONFIRM_MAIL = 'confirm_mail';
export const CONFIRM_SUCCESS = 'confirm_success';
export const CONFIRM_ERROR = 'confirm_error';

export const EMPTY_ERROR = 'empty_error';


