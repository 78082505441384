import React from 'react'

const ErrorBillingNotification = ({ message }) => {
  return (
    <div className="LoadingNotificationMessage red mt-4">
      <span >
        {message}
      </span>
      <span >
        Refresque la página e intente de nuevo.
      </span>
    </div>
  )
}

export default ErrorBillingNotification;