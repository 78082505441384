import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {activateNonAuthLayout} from '../../store/actions';

class Logout extends Component {

  componentDidMount() {

    this.props.activateNonAuthLayout();

    //============ Empty Local Storage =============*
    localStorage.removeItem('user');
    // debugger
    this.props.history.push('/');
  }

  render() {
    return (
      <Fragment>
        <h1>&nbsp;</h1>
      </Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {activateNonAuthLayout})(Logout));

