import React from 'react'
import Logo from '../assets/images/logoo.png';

const RegisterLayout = props =>(
  <div className="Register-page">
    <div className="admin-cover">
      <div className="login-overlay"></div>
      <div className="brand-img d-flex justify-content-center p-4 pt-md-5 mt-md-5">
        <img className="img-fluid" width="235" src={Logo} alt="Logo" />
      </div>
    </div>
    <div className="Register">
      {
        props.children
      }
    </div>
  </div>
)

export default RegisterLayout;