import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { getGuestByID, getGuestWorkshops, getUserByID, getWorkshopById } from '../../helpers/getDashboardData';
import { updateGuest } from '../../helpers/sendData';
import { sendGuestEmail } from '../../helpers/sendEmails';
import RegisterLayout from '../../layout/registerLayout';
import { objectToSend } from '../../widgets/utils/objectToSend';
import GuestForm from './guestForm';
import MessageModal from '../../widgets/messageModal';
import { handleError } from './utils/handleErrors';
import { validateBirthDay } from './utils/validateForm';
import { getCalendarWorkshop } from '../Workshops/utils/iCalendar'
import SuccesIcon from '../../widgets/icons/success-icon';
import { shortDate, shortTime } from '../../utils/parseDate/formatDisplay';
import { getTodaysDate, getTodayUTC } from '../../utils/parseDate/getValues';
import { getStartOfDay } from '../../utils/parseDate/getValues';

const UpdateGuest = (props) => {

  const { guestID } = props.match.params

  const [guest, setGuest] = useState({
    id: '',
    name: '',
    email: '',
    birth_date: '',
    tc_accepted_at: false,
    firstTime: false,
  })

  const [workshopsInfo, setWorkshopsInfo] = useState({
    workshopsIDs: [],
    guestWorkshops: [],
  })

  const [state, setState] = useState({
    submitted: false,
    registerSuccess: false,
    registrationError: false,
    invitation: '',
    error: false,
    errorMessage: '',
  })

  const [messageVisible, setMessageVisible] = useState(false)
  const [formVisible, setFormVisible] = useState(false)

  const $form = useRef(null)

  const getGuest = async (guestID) => {
    const today = getTodayUTC()
    const guestWorkshops = [];
    let invitation = '';

    try {
      const gottenGuest = await getGuestByID(guestID)
      
      const { id } = gottenGuest
      let { birth_date, tc_accepted_at, updated_at, created_at, guest_id, ...rest } = gottenGuest.attributes

      /*Checking if "birth_date" and "tc_accepted_at" actually exist
      If they don't, it means the guest's never full-registered himself before*/
      
      if (birth_date && tc_accepted_at) {
        setGuest({
          ...guest, 
          ...rest, 
          id, 
          birth_date: shortDate(birth_date), 
          tc_accepted_at,
          firstTime: false
        })

      } else {
        setGuest({ ...guest, ...rest, id, firstTime: true })
      }

      const workshopsIDs = await getGuestWorkshops(guestID)

      //The workshop is searched by its ID
      for (const workshop of workshopsIDs) {
        try {
          const gottenWorkshop = await getWorkshopById(workshop.id)
          const { end } = gottenWorkshop.attributes

          //if the workshop has already expirated then it's not addded
          if (end > today) {
            guestWorkshops.push(gottenWorkshop)
          }

        } catch (error) {
          console.log(error)
          console.log(error.message)
        }
      }

      //The form finish loading then it shows up
      setFormVisible(true)
      setState({ ...state, invitation })
      setWorkshopsInfo({ workshopsIDs, guestWorkshops })

    } catch (error) {
      console.log(error)
      setState({ ...state, error: true })
    }
  }

  const getMessage = async (guest_name, guestWorkshops, workshopsIDs) => {
    const workshopsMessages = []

    for (const guestWorkshop of workshopsIDs) {
      const { sign_in_code } = guestWorkshop.attributes;
      const foundWorkshop = guestWorkshops.find(workshop => workshop.id === guestWorkshop.id);

      if (foundWorkshop) {
        const { name, start, end, user_id } = foundWorkshop.attributes

        let user = await getUserByID(user_id)

        user = {
          name: user.attributes.name,
          email: user.attributes.email,
        }

        const workshop = {
          name, 
          date: shortDate(start),
          start:shortTime(start),
          end: shortTime(end),
        }

        const details = {
          organizer: user,
          workshop, 
          sign_in_code,
          isForOrganizer: false,
          guests: [],
        }

        const calendar = getCalendarWorkshop(details)

        const message = {
          subject: "Invitación al Taller",
          user_name: user.name || "",
          guest_name,
          workshop_name: workshop.name,
          workshop_date: workshop.date,
          workshop_start_time: workshop.start,
          workshop_end_time: workshop.end,
          sign_in_code,
          ...calendar
        }

        workshopsMessages.push(message);
      }
    }

    return workshopsMessages;
  }

  const setLoading = (loading, state, errorMessage) => {
    const defaultMessage = 'Ha habido un error con la actualización del invitado.'
    setState({
      ...state,
      registerSuccess: false,
      registrationError: false,
    })
    if (state === 'success') {
      setState({
        ...state,
        loading,
        registerSuccess: true
      })
    } else if (state === 'error') {
      setState({
        ...state,
        loading,
        registrationError: true,
        errorMessage: errorMessage || defaultMessage,
      })
    } else {
      setState({ ...state, loading })
    }
  }

  useEffect(() => {
    console.log(props)
    getGuest(guestID)
  }, [])
  
  const formActions = {
    handleSubmit: async event => {
      event.preventDefault()
      setLoading(true)
  
      const { guestWorkshops, workshopsIDs } = workshopsInfo
  
      const { firstTime, id, ...rest } = guest
  
      const attributes = {
        ...rest,
        birth_date: getStartOfDay(guest.birth_date),
        tc_accepted_at: getStartOfDay(getTodaysDate())
      }
  
      const body = objectToSend('guests', attributes)

      //debugger
      
      //Every workshop message is created so that they're gonna be sent to guest
      const workshopsMessages = await getMessage(guest.name, guestWorkshops, workshopsIDs)
      
      //debugger

      try {
        await updateGuest(guest.id, body)
  
        //If the guest has workshops to attend and if it's first time he updates his info
        if (workshopsMessages && firstTime) {
          const type = "guest_invitation"
          try {
            for (const workshop of workshopsMessages) {
              const {sign_in_code} = workshop
              const formattedGuest = {id: guest.id, attributes:{...guest, sign_in_code}}
              await sendGuestEmail(type, workshop, formattedGuest)
            }
  
            //Aparece el messageModal
            setMessageVisible(true)
  
          } catch (error) {
            console.log(error)
          }
        }
  
        setLoading(false, 'success')
  
      } catch (error) {
        console.log(error)
        handleError(error, setLoading)
      }
    },
    handleChange: ({ target }) => {
      const setGuestKey = (target) => {
        const { name, value, checked } = target;
    
        if (name === 'Terminos-condiciones') {
        //debugger
    
          //this sets the input validity message
          if(checked)
            target.setCustomValidity('');
    
          setGuest({ ...guest, tc_accepted_at: checked })
    
        } else {
          //Default input action
          setGuest({ ...guest, [name]: value })
        }
      }
    
    
      validateBirthDay(target, setGuestKey)
    },
    handleInvalid: () => {
      $form.current.classList.add('was-validated')
      const terms = $form.current.querySelector('input#Terminos-condiciones')
  
      if (!terms.checked) 
        terms.setCustomValidity('Aceptar Términos y Condiciones')
    },
  }

  const { error, loading, errorMessage, registerSuccess, registrationError, submitted } = state
  const formState = { loading, registerSuccess, registrationError, errorMessage }

  return (
    <RegisterLayout>
      {
        formVisible &&
        <GuestForm
          {...formState}
          {...formActions}
          guest={guest}
          setRef={$form}
          type='actualizacion'
          title='Registro de invitado'
        />
      }

      {
        messageVisible &&
        <MessageModal
          btnColor='success'
          loading={false}
          handleClick={() => {
            //MessageModal disappears and GuestForm too
            setState({ ...state, submitted: true })
            setFormVisible(false)
            setMessageVisible(false)
          }}
          title="Se ha registrado con éxito"
          message="Le llegará por mail la clave de ingreso a la sala"
        >
          <SuccesIcon color="#20c997" size={100} />
        </MessageModal>
      }

      {// Loading, it disappears when there´s an error or it finished loading
        !error && !formVisible && !submitted &&
        <span className="font-weight-bold">Cargando...</span>
      }
      {//It appears when the form is submitted
        submitted &&
        <span className="font-weight-bold">
          ¡Gracias por registrarse, puede cerrar esta pestaña!
        </span>
      }
      {
        error &&
        <Alert style={{ width: '300px' }} className="text-center" color="danger">
          ¡¡¡Invitado invalido!!!
        </Alert>
      }
    </RegisterLayout>
  )
}

export default connect()(UpdateGuest);