export const objectToSend  = (type, attributes) =>{
  const object = {
    data:{
      type: type,
      attributes:{
        ...attributes
      }
    }
  }
  return object
}

export const messageToSend  = (type, email, attributes) =>{
  const object = {
    data: {
      type,
      id: email,
      attributes
    }
  }
  return object;
}

export const objectToSendInvitations  = (id) =>{
  const object = {
    data:{
      attributes:{ id }
    }
  }
  return object;
}
