import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Metismenu
import MetisMenu from 'metismenujs';

class Sidebar extends Component {

  componentDidMount() {
    new MetisMenu("#menu", {});

    var matchingMenuItem = null;
    var ul = document.getElementById("menu");
    var items = ul.getElementsByTagName("a");
    
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  
  activateParentDropdown = (item) => {

    item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li 
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('active');
          }
        }
      }
      return false;
    }
  }

  render() {
    const { is_admin } = this.props

    return (
      <>
        <div className="left side-menu">
          <div className="sidebar-menu pt-3">
            <div className="main-menu pt-5">
              <div className="" id="sidebar_menu">
                <nav>
                  <ul className="metismenu" id="menu">

                    {/* Dashboard */}
                    {
                      // Admin Only can manage this
                      is_admin &&
                      <li>
                        <Link to="/dashboard">
                          <i className="feather ft-home"></i>
                          <span>Dashboard</span>
                        </Link>
                      </li>
                    }

                    {/* Agenda */}
                    <li>
                      {
                        <Link to="/agenda">
                          <i className="feather ft-calendar"></i>
                          <span>Sesiones</span>
                        </Link>
                      }
                    </li>

                    {/* Usuarios */}
                    {
                      // Admin Only can manage this
                      is_admin &&
                      <li>
                        <Link to="/usuarios">
                          <i className="ti-layout-slider-alt"></i>
                          <span>Usuarios</span>
                        </Link>
                      </li>
                    }

                    {/* Invitados */}
                    <li>
                      <Link to="/invitados">
                        <i className="ti-layout-slider-alt"></i>
                        <span>Invitados</span>
                      </Link>
                    </li>

                    {/* Planes */}
                    {
                      // Admin Only can manage this
                      is_admin &&
                      <li>
                        <Link to="/planes">
                          <i className="ti-layout-slider-alt"></i>
                          <span>Planes</span>
                        </Link>
                      </li>
                    }

                    {/* Suscripciones */}
                    <li>
                      <Link to="/suscripciones">
                        <i className="feather ft-dollar-sign"></i>
                        <span>Suscripciones</span>
                      </Link>
                    </li>

                    {/* Facturaciones */}
                    {
                      is_admin &&
                      <li>
                        <Link to="/facturaciones">
                          <i className="feather ft-dollar-sign"></i>
                          <span>Facturaciones</span>
                        </Link>
                      </li>
                    }

                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateTopProps = state => {
  return {
    is_admin: state.Login.user.attributes.is_admin
  }
}

export default withRouter(connect(mapStateTopProps, null)(Sidebar));