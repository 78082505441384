import React from 'react'
import Trash from '../../widgets/icons/trash'
import Button from '../../widgets/button'

const DeletePlanButton = ({planName, planID, handleDeleteAlert}) =>{
  
  const handleDeletePlan = () =>{
    handleDeleteAlert({
      selectedPlan:{
        id: planID,
        name: planName,
      },
      confirmMsg: `¿Está seguro que desea eliminar el plan '${ planName }'?`,
      confirm_both: true
    })
  }
  
  return(
    <Button color="danger" handleClick={handleDeletePlan} >
      <Trash/>
    </Button>
  )
}

export default DeletePlanButton;