import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FullscreenButton from './fullscreenButton';
import Hamburger from './hamgurger';
class Topbar extends Component {

  state = {
    toggle: false,
    menu: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  sidebarToggle = () => {
    const pageClass = document.querySelector('.page-container');
    pageClass.classList.toggle('sidebar_collapsed');
  }

  toggleFullscreen() {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <>
        <div className="header-area">
          <div className="nav-header py-2 px-2 px-md-5">
            <div className="nav-btn-search">
              <Hamburger
                handleClick={this.sidebarToggle}
              />
            </div>

            <ul className="notification-area pull-right">

              <FullscreenButton
                handleClick={this.toggleFullscreen}
              />

              <li className="user-dropdown">

                <Dropdown isOpen={this.state.menu} toggle={this.toggle} tag="div" >

                  <DropdownToggle className="btn dropdown-toggle" tag="div">
                    <span className="">{this.props.user.name} <i className="ti-angle-down"></i></span>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu">

                    <Link to={`/usuario/${this.props.user.id}`} className="p-0">
                      <DropdownItem>
                        <i className="ti-settings"></i> Cuenta
                      </DropdownItem>
                    </Link>

                    <span role="separator" className="divider"></span>

                    <DropdownItem tag="a" className="text-danger" onClick={() => { localStorage.clear() }} href="/logout">
                      <i className="ti-power-off"></i> Cerrar Sesión
                    </DropdownItem>

                  </DropdownMenu>

                </Dropdown>

              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ Login: { user: { id, attributes: { name } } } }) => ({
  user: {
    id,
    name
  }
})

export default connect(mapStateToProps)(Topbar);