import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Button} from 'reactstrap';
import InputModal from '../../widgets/inputModal';
import {registerFailed, registerSuccessful} from '../../store/actions';
import {updateUser} from '../../helpers/sendData';
import {objectToSend} from '../../widgets/utils/objectToSend';
import {withRouter} from 'react-router';

class RegisterPaymentForm extends Component{
  
  state = {
    payment_id: ''
  }
  
  handleSubmit = async event =>{
    event.preventDefault()
    const { user } = this.props
    const { payment_id } = this.state
    const { created_at, updated_at, ...all } = user.attributes
    
  //debugger
    const attributes = { 
      ...all, 
      payment_id: parseInt(payment_id, 10),  
      is_active: true
    }
    
  //debugger
    const body = objectToSend('users', attributes)
    
    console.log(user.id)
    console.log(body)
    
    try {
      await updateUser(user.id, body)
      this.props.dispatch(registerSuccessful())
      alert('Se ha actualizado el id de pago. Se ha activado su cuenta')
      this.props.history.push('/')
    } catch (error) {
      console.log(error)
      this.props.dispatch(registerFailed())
    }
  }
  
  handleChange = ({target}) =>{
    this.setState({
      [target.name]: target.value
    })
  }
  
  handleInvalid = () => this.form.classList.add('was-validated')
  setRef = element => this.form = element
  
  render(){
    return(
      <>
      <h1>Ingreso ID del pago</h1>
      <form
        className="needs-validation"
        ref={this.setRef}
        onSubmit={this.handleSubmit}
        onInvalid={this.handleInvalid}
      >
        <div className="Register__body">
          {
            this.props.registerSuccess && 
            <Alert color="success">
              Registro del pago exitoso
            </Alert>
          }
          {
            this.props.registrationError && 
            <Alert color="danger">
              Ha ocurrido un error con el registro del pago
            </Alert>
          }
          
          <InputModal
            id="payment_id"
            type="number"
            title="Id del pago"
            className="form-control"
            placeholder="Ingrese el ID del pago"
            handleChange={this.handleChange}
            value={this.state.payment_id}
          />
          <div className="submit-btn-area">
            <Button color="primary" className="btn btn-primary" type="submit">
              {this.props.loading ? 'Loading ...' : 'Guardar'} <i className="ti-arrow-right"></i>
            </Button>
          </div>  
        </div>
      </form>
    </>
    )
  }
}

const mapStatetoProps = ({Account:{ registerSuccess, registrationError, loading }}) => ({
  registerSuccess, 
  registrationError, 
  loading
})

export default withRouter(connect(mapStatetoProps)(RegisterPaymentForm));