import React, { useState } from 'react';
import { connect } from 'react-redux';

//Components
import WorkshopFeatures from './workshopFeatures';

//Utils
import { getCalendarWorkshop } from '../utils/iCalendar'
import { sendGuestEmail as sendEmail, workshopNotification } from '../../../helpers/sendEmails';
import { messageToSend } from '../../../widgets/utils/objectToSend';
import { getCuteFormat, shortTime } from '../../../utils/parseDate/formatDisplay';
import { getTodaysDate } from '../../../utils/parseDate/getValues';
import { getYear } from 'date-fns';


const { REACT_APP_BOARD_URL } = process.env

const WorkshopModal = (props) => {
  const { user, guests } = props;

  const [update, setUpdate] = useState(props.update)
  const [emails, setEmails] = useState(guests)

  const [workshop, setWorkshop] = useState({
    ...props.workshop,
    date: props.workshop.date || getTodaysDate(),
    user_id: user.id,
    status: 0
  })

  //Cada vez que se hace una repetición se utiliza un workshop con una fecha diferente 
  const sendEmails = async (guestsEmails, emailsSent, workshop, workshopCode) => {
    const { created, registered, notFullRegistered } = guestsEmails

    const guests = {
      created: {
        emails: created,
        invitation: "guest_registry"
      },
      registered: {
        emails: registered,
        invitation: "guest_invitation"
      },
      notFullRegistered: {
        emails: notFullRegistered,
        invitation: "guest_registry"
      },
    }

    //Método que notifica al creador del taller sobre la creación del mismo
    const sendNotification = async () => {
      const details = {
        organizer: {
          name: user.name,
          email: user.email
        },
        workshop,
        sign_in_code: workshopCode,
        isForOrganizer: true,
        guests: guestsEmails,
      }

      const calendar = getCalendarWorkshop(details)
      const endYear = getYear(workshop.end).toString()
      
      debugger
      
      // true  => workshop.end = "fecha límite"
      // false => workshop.end = "UNREACHABLE_DATE"
      // Si es false la fecha de finalización será la fecha de vencimiento del plan del usuario
      // Si el usuario es admin entonces due_date será undefined
      const endTime = workshop.has_expiration 
          ? (!user.is_admin ? shortTime(user.due_date) : shortTime(workshop.end))
          : (!user.is_admin ? getCuteFormat(user.due_date) : "Indefinido")
          
      const attributes = {
        subject: 'Creación de taller',
        user_name: user.name,
        workshop_name: workshop.name,
        workshop_date: getCuteFormat(workshop.date),
        workshop_start_time: shortTime(workshop.start),
        workshop_end_time: endTime,
        // workshop_end_time: workshop.end,
        sign_in_code: workshopCode,
        board_url: REACT_APP_BOARD_URL,
        ...calendar
      }

      const message = messageToSend("workshop_creation", user.email, attributes)

      try {
        debugger
        await workshopNotification(message)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        alert("Ha habido un error con la notificación al creador del taller")
      }

    }

    const toEachGuest = async (guest, type) => {
      const { email, name, sign_in_code } = guest.attributes;

      const details = {
        organizer: {
          name: user.name,
          email: user.email
        },
        workshop,
        sign_in_code,
        isForOrganizer: false,
        guests: [],
      }

      const calendar = getCalendarWorkshop(details)

      debugger

      // true  => workshop.end = "fecha límite"
      // false => workshop.end = "UNREACHABLE_DATE"
      // Si es false la fecha de finalización será la fecha de vencimiento del plan del usuario
      // Si el usuario es admin entonces due_date será undefined
      const endTime = workshop.has_expiration 
          ? (!user.is_admin ? shortTime(user.due_date) : shortTime(workshop.end))
          : (!user.is_admin ? getCuteFormat(user.due_date) : "Indefinido")

      const attributes = {
        subject: 'Invitación al taller',
        user_name: user.name,
        guest_name: name ? name : '...',
        workshop_name: workshop.name,
        workshop_date: getCuteFormat(workshop.date),
        workshop_start_time: shortTime(workshop.start),
        workshop_end_time: endTime,
        ...calendar
      }

      try {
        await sendEmail(type, attributes, guest)
        emailsSent += 1;

      } catch (error) {
        console.log(error)
        console.log(error.message)
        alert(`Ha ocurrido un error con el envio al correo ${email}`)
      }
    }

    for (const type in guests) {
      for (const guest of guests[type].emails) {
        try {
          await toEachGuest(guest, guests[type].invitation)
        } catch (error) {
          console.log(error)
          console.log(error.message)
          console.log(`No se pudo enviar el mail a ${guest.attributes.email}`)
        }
      }
    }

    //Mensaje de notificación que se le envía al creador del taller
    await sendNotification()

    return emailsSent;
  }

  return (
    <WorkshopFeatures
      {...props}
      update={update}
      emails={emails}
      workshop={workshop}
      setEmails={setEmails}
      setUpdate={setUpdate}
      sendEmails={sendEmails}
      setWorkshop={setWorkshop}
    />
  )
}

const mapStateToProps = ({ Login, Workshops }) => {

  const { user, loading } = Login;
  const { id, attributes: { plan, email, is_admin, name, due_date, available_sessions } } = user
  const { total_workshops } = Workshops;

  return {
    loading,
    total_workshops,
    user: {
      id,
      is_admin,
      name,
      available_sessions,
      email,
      due_date,
      plan: !is_admin ? plan : ''
    },
    userAllAttributes: user
  }
}

export default connect(mapStateToProps)(WorkshopModal);