import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Card, Alert as UserInfo } from 'reactstrap';
import { createBilling, getDocumentTypes, getSalesPoints, getVoucherTypes } from '../../api/afip';
import { renewPaypalPlan } from '../../api/paypal';
import { updateUser } from '../../helpers/sendData';
import { sendBillingNotification } from '../../helpers/sendEmails';
import { useSessionsLeft } from '../../hooks/useInfiniteSessions';
import { getTypeByLabel } from '../../utils';
import { logError } from '../../widgets/utils/error';
import { currencyType } from '../Plans/utils/currencyType';
import { getBillingFail, getBillingFormat, getBillingSuccess, getRenewalFormat } from './utils/getRenewalFormat';

import InputModal from '../../widgets/inputModal';
import NewPlanCard from './newPlanCard';
import PlanButton from './planButton';

const PlanChanging = ({ user, newPlan, handleClose }) => {

  const { name, plan, available_sessions } = user.attributes

  const SESSIONS_LEFT = useSessionsLeft(available_sessions)

  const [id_document, setIdDocument] = useState(user.attributes.id_document)


  // Lógica de Mensaje de alerta
  const [visibleWarning, setVisibleWarning] = useState(false)
  const [warningMessage] = useState("¿Estas seguro de que quieres cambiar tu plan?")


  // Lógica de facturación
  const [billingAvaible, setBillingAvailable] = useState(true)

  const [billingAttributes, setBillingAttributes] = useState({
    codigoTipoDocumento: 0,
    codigoTipoComprobante: 0,
  })

  const [billingOptions, setBillingOptions] = useState({
    documentTypes: [
      {
        "id": 0,
        "label": ""
      },
    ],
    voucherTypes: [
      {
        "id": 0,
        "label": ""
      },
    ],
    salesPoints: [
      {
        "id": 0,
        "label": ""
      }
    ],
  })

  const [options, setOptions] = useState({
    CUIT: {},
    DNI: {}
  })

  useEffect(() => {
    (async () => {
      try {

        const documentTypes = await getDocumentTypes()
        console.log("Tipos de documento", documentTypes)

        const voucherTypes = await getVoucherTypes()
        console.log("Tipos de comprobantes", voucherTypes)

        const salesPoints = await getSalesPoints()
        console.log("Tipos de puntos de venta", salesPoints)

        const CUIT = getTypeByLabel("CUIT", documentTypes)
        const DNI = getTypeByLabel("DNI", documentTypes)

        const FACTURA_A = getTypeByLabel("Factura A", voucherTypes)
        const FACTURA_B = getTypeByLabel("Factura B", voucherTypes)

        const options = {
          CUIT: {
            codigoTipoDocumento: DNI.id,
            codigoTipoComprobante: FACTURA_B.id,
          },
          DNI: {
            codigoTipoDocumento: CUIT.id,
            codigoTipoComprobante: FACTURA_A.id,
          }
        }

        setOptions(options)

        // Aquí se evalúa
        idDocumentValidation(id_document.length, options)

        setBillingOptions({ documentTypes, voucherTypes, salesPoints })

      } catch (error) {
        console.log(error)
        setBillingAvailable(false)
      }
    })()
  }, [])

  const idDocumentValidation = (id_document, options) => {

    // CUIT => 10 - 11
    // DNI  =>  7 - 8

    // CUIT = 80 => Factura A = 1
    // DNI  = 96 => Factura B = 6
    debugger
    
    // Esto evalúa la longitud de caracteres del id_document
    if (id_document >= 7 && id_document <= 8) setBillingAttributes(options.DNI)
    if (id_document >= 10 && id_document <= 11) setBillingAttributes(options.CUIT)
  }

  const handleChange = ({ target }) => {
    const id_document = parseInt(target.value.split(".").join(""))
    console.log("idDocument:", id_document)

    if (billingAvaible) {
      // Si idDocument llega a ser NaN entonces ninguna de las dos condiciones será verdadera.
      idDocumentValidation(id_document.length, options)
    }

    setIdDocument(isNaN(id_document) ? "" : id_document)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const id_document_length = id_document.toString().length

    if (id_document_length !== 9) {
      setVisibleWarning(true);
    } else {
      alert("El documento de Identidad no puede tener 9 caracteres de longitud")
    }
  }

  const handleConfirm = async () => {
    const { dolar, pesoArgentino } = currencyType;

    const { payment_url, price, is_free } = newPlan.attributes;
    const { currency, payment_renewal_url } = newPlan.attributes;

    let payment_page = "";

    const renewalInfo = { newPlan, user, id_document }
    const renewalBody = getRenewalFormat(renewalInfo);

    try {
      //The plan selection panel gets closed
      setVisibleWarning(false)
      handleClose()

      // Renovación
      if (is_free) {

        localStorage.clear()
        await updateUser(user.id, renewalBody)
        payment_page = payment_url;

      } else {

        if (currency === dolar.code) {
          //No es necesario cerrar la sesión, se actualiza el usuario después del pago
          payment_page = await renewPaypalPlan(newPlan, renewalBody, price);

        } else if (currency === pesoArgentino.code) {
          //Se cierra la sesión y se actualiza el usuario
          alert('¡Felicidades, acabas de cambiar tu plan, a continuación se cerrará la sesión y se te enviará a concretar el pago.')

          localStorage.clear()
          await updateUser(user.id, renewalBody)
          payment_page = payment_renewal_url;
        }
      }

      // Facturación
      const billingFailBody = getBillingFail(renewalInfo)
      
      if (billingAvaible) {
        try {

          const billingBody = getBillingFormat({ ...renewalInfo, billingOptions, billingAttributes })
          await createBilling(billingBody)

        } catch (error) {
          
          console.log(error)
          await sendBillingNotification.fail(billingFailBody)
          
        }
      }else{
        await sendBillingNotification.fail(billingFailBody)
      }

      window.location = payment_page;

    } catch (error) {
      alert("Ha ocurrido un error con la actualización de su plan")
      logError(error)
    }
  }

  return (
    <div className="planChanging">

      <form onSubmit={handleSubmit}>
        <Card className="planChanging-container p-2 pb-3 p-md-4 mb-5 card">

          <div className="user-info">
            <div className="user-info_container p-4">
              <h4 className="card_title">Datos del Usuario</h4>
              <UserInfo color="secondary">
                Hola {name}
              </UserInfo>

              <UserInfo color="info">
                Este es tu plan actual: {plan.attributes.name}
              </UserInfo>

              <InputModal
                type="text"
                id="id_document"
                pattern="^[0-9-+\s()]*$"
                title="Documento de Identidad (DNI o CUIT)"
                className="form-control"
                minLength={7}
                maxLength={11}
                value={id_document}
                handleChange={handleChange}
                required={true}
              />

            </div>
          </div>

          <div className="newPlan text-center">
            <div className="newPlan-container">
              <NewPlanCard
                key={1}
                type={'Plan Actual'}
                plan={plan.attributes}
              />
              <NewPlanCard
                key={2}
                type={'Plan Nuevo'}
                plan={newPlan.attributes}
              />
            </div>

            {
              !!SESSIONS_LEFT &&
              <div className="bg-white border border-danger pz-4 py-3 text-danger">
                <p className="m-0">
                  Aún tienes <strong>{SESSIONS_LEFT}</strong> {" "}
                  sesiones disponibles. Si actualizas el plan las sesiones se reiniciaran
                </p>
              </div>
            }

            <div className="buttons-container">
              <PlanButton
                name="Aceptar"
                type="submit"
                className="btn btn-secondary"
              />
              <PlanButton
                name="Cerrar"
                className="btn btn-danger"
                handleClick={handleClose}
              />
            </div>
          </div>

        </Card>
      </form>

      {
        // Le pregunta al usuario si está seguro de querer borrar el plan
        visibleWarning &&
        <SweetAlert
          title={warningMessage}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={handleConfirm}
          onCancel={() => setVisibleWarning(false)}
        >
          No se puede revertir!
        </SweetAlert>
      }

    </div>
  )
}

export default connect()(PlanChanging);