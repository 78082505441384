export const orderPlans = (plans) =>{
  let ordered = [];

  ordered = plans.sort((itemA, itemB)=> {
    return itemA.attributes.display_order - itemB.attributes.display_order;
  })
  
  ordered = orderPlansByIndex(ordered)
  
  ordered.forEach(item => console.log(item.attributes.display_order))
  
  return ordered;
}

export const orderPlansByIndex = (plans) =>{
  const orderedPlans = plans.map((plan, index) => {
    plan.attributes.display_order = index + 1
    return plan;
  });
  
  return orderedPlans;
}
