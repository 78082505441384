import React from 'react'

const InputDateModal = (props) => {
  const {title, className, type, required = true, disabled, labelClassName, handleChange, setRefInputs, id, defaultValue} = props
  return(
  <label className={labelClassName} htmlFor={id}>
    {title}
    <input
      disabled={disabled}
      type={type}
      ref={setRefInputs}
      value={defaultValue}
      onChange={handleChange}      
      className={className}
      name={id}
      id={id}
      required={required}
    />
  </label>
)
}
export default InputDateModal;