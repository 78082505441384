import { connect } from 'react-redux'
import React, { useState } from 'react'
import { getTodaysDate } from '../../utils/parseDate/getValues'
import Select from 'react-select'
import { cuteDateUS } from '../../utils/parseDate/formatDisplay'
import PDFTemplate from '../Facturaciones/PDFTemplate'
import html2pdf from "html2pdf.js"

const MomentTries = () => {

  const options = [
    { label: "Factura A", value: 1 },
    { label: "Factura B", value: 2 },
    { label: "Factura C", value: 3 },
    { label: "Factura D", value: 4 },
    { label: "Factura E", value: 5 },
  ]

  const [state, setState] = useState({
    voucher: "",
  })

  const handleChange = (event) => {
    console.log(event)
  }

  const date = "2020-12-14"

  const createPDF = () => {
    console.log("Se empieza a crear")
    html2pdf()
      .set({
        margin: 0.5,
        filename: "comprobante.pdf",
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          scale: 3,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait"
        }
      })
      .from(document.getElementById("voucher"))
      // .save()
      .output("dataurlnewwindow")
      .then(() => console.log("Guardado!!!"))
      .catch(error => console.log(error))
      .finally(() => console.log("Finally!!!"))
  }

  const documentTypes = [
    {
      "id": 90,
      "label": "LC"
    },
    {
      "id": 91,
      "label": "CI Extranjera"
    },
    {
      "id": 92,
      "label": "en trámite"
    },
    {
      "id": 93,
      "label": "Acta Nacimiento"
    },
    {
      "id": 95,
      "label": "CI Bs. As. RNP"
    },
    {
      "id": 96,
      "label": "DNI"
    },
    {
      "id": 94,
      "label": "Pasaporte"
    },
    {
      "id": 0,
      "label": "CI Policía Federal"
    },
    {
      "id": 1,
      "label": "CI Buenos Aires"
    },
    {
      "id": 2,
      "label": "CI Catamarca"
    },
    {
      "id": 3,
      "label": "CI Córdoba"
    },
    {
      "id": 4,
      "label": "CI Corrientes"
    },
    {
      "id": 5,
      "label": "CI Entre Ríos"
    },
    {
      "id": 6,
      "label": "CI Jujuy"
    },
    {
      "id": 7,
      "label": "CI Mendoza"
    },
    {
      "id": 8,
      "label": "CI La Rioja"
    },
    {
      "id": 9,
      "label": "CI Salta"
    },
    {
      "id": 10,
      "label": "CI San Juan"
    },
    {
      "id": 11,
      "label": "CI San Luis"
    },
    {
      "id": 12,
      "label": "CI Santa Fe"
    },
    {
      "id": 13,
      "label": "CI Santiago del Estero"
    },
    {
      "id": 14,
      "label": "CI Tucumán"
    },
    {
      "id": 16,
      "label": "CI Chaco"
    },
    {
      "id": 17,
      "label": "CI Chubut"
    },
    {
      "id": 18,
      "label": "CI Formosa"
    },
    {
      "id": 19,
      "label": "CI Misiones"
    },
    {
      "id": 20,
      "label": "CI Neuquén"
    },
    {
      "id": 21,
      "label": "CI La Pampa"
    },
    {
      "id": 22,
      "label": "CI Río Negro"
    },
    {
      "id": 23,
      "label": "CI Santa Cruz"
    },
    {
      "id": 24,
      "label": "CI Tierra del Fuego"
    },
    {
      "id": 80,
      "label": "CUIT"
    },
    {
      "id": 86,
      "label": "CUIL"
    },
    {
      "id": 87,
      "label": "CDI"
    },
    {
      "id": 89,
      "label": "LE"
    }
  ]

  const voucherTypes = [
    {
      "id": 1,
      "label": "Factura A",
      "description": "Monotributista"
    },
    {
      "id": 6,
      "label": "Factura B",
      "description": "Consumidor final o Exento"
    }
  ]

  const voucher = {
    fechaEmision: "2023-01-13T00:00:00.000Z",

    codigoConcepto: 2,
    codigoMoneda: "PES",
    codigoTipoDocumento: 80,
    codigoTipoComprobante: 1,
    codigoTipoAutorizacion: "E",
    codigoAutorizacion: 73020074947157,

    numeroPuntoVenta: "1",
    numeroComprobante: "1",
    numeroDocumento: 5555555555,

    importeGravado: "8.00",
    importeNoGravado: "0.00",
    importeExento: "0.00",
    importeSubtotal: "8.00",
    importeTotal: "9.68",
    cotizacionMoneda: 1,
    observaciones: "nada",

    fechaVencimiento: "2023-01-23T00:00:00.000Z",
    fechaServicioDesde: "2022-01-13T00:00:00.000Z",
    fechaServicioHasta: "2022-01-15T00:00:00.000Z",
    fechaVencimientoPago: "2023-01-13T00:00:00.000Z",

    arrayItems: {
      item: [
        {
          unidadesMtx: 1,
          codigoMtx: "1",
          codigo: "A001",
          descripcion: "Este es un item",
          cantidad: "1.000000",
          codigoUnidadMedida: 7,
          precioUnitario: "8.000000",
          codigoCondicionIVA: 5,
          importeIVA: "1.68",
          importeItem: "9.68"
        }
      ]
    },
    arraySubtotalesIVA: {
      subtotalIVA: [
        {
          codigo: 5,
          importe: "1.68"
        }
      ]
    }
  }

  return (
    <div className="m-5">
      <Select
        name="voucher"
        onChange={handleChange}
        options={options}
        required
      />
      <div>
        <span>{cuteDateUS(getTodaysDate())}</span>
      </div>
      <div>
        <span>date: {date}</span>
      </div>
      <div>
        <span>format: {cuteDateUS(date)}</span>
      </div>
      <div>
        <span>Just Trying: {cuteDateUS("")}</span>
      </div>
      <button className="btn btn-success my-3" onClick={createPDF}>
        Download PDF
      </button>
      <PDFTemplate
        id="voucher"
        voucher={voucher}
        voucherTypes={voucherTypes}
        documentTypes={documentTypes}
      />
    </div>
  )
}

const mapStateToProps = ({ Login: { user: { attributes: { due_date } } } }) => {
  return {
    user: { due_date }
  }
}

export default connect(mapStateToProps)(MomentTries);
