import { SET_WORKSHOPS_AT_STORE } from './actionTypes';
import { SET_UTC_WORKSHOPS, SET_WORKSHOPGUEST_AT_STORE } from './actionTypes';
import { getState_from_LocalStorage } from '../../helpers/utils'

import { setState } from '../utils';

let initialState = {
  total_workshops: [],
  workshopsGuests: []
}

const FIELD = 'Workshops';

if (localStorage.Workshops) {
  initialState = getState_from_LocalStorage('Workshops')
}

const workshopReducer = (state = initialState, action) => {
  const commonAction = {
    [SET_UTC_WORKSHOPS]: "utc_workshops",
    [SET_WORKSHOPS_AT_STORE]: "total_workshops",
    [SET_WORKSHOPGUEST_AT_STORE]: "workshopsGuests",
  }

  const customAction = {}

  return setState(state, action, FIELD, commonAction, customAction);
}

export default workshopReducer;