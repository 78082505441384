import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../widgets/button';
import Edit from '../../widgets/icons/edit'

const UpdatePlanButton = ({history, planID}) =>(
  
  <Button 
    handleClick={()=>{history.push(`/planes/${planID}`)}} 
    color="secondary"
  >
    <Edit />
  </Button>
)

export default withRouter(UpdatePlanButton);