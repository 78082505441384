import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import Layout from '../../layout'
import { connect } from 'react-redux';
import Button from '../../widgets/button';
import InputDateModal from '../../widgets/inputDateModal';
import InputModal from '../../widgets/inputModal';
import InputCheck from '../../widgets/inputCheck';
import { validateBirthDay } from '../Auth/utils/validateForm';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { updateUser } from '../../helpers/sendData';
import ProfileInfo from './profileInfo';
import { updateLoggedUser } from '../../store/actions';
import { getUserPlan } from '../../helpers/getDashboardData';
import { shortDate } from '../../utils/parseDate/formatDisplay';
import { getStartOfDay } from '../../utils/parseDate/getValues';

class Profile extends Component {
	state = {
		userID: this.props.user.id,
		user: {
			...this.props.user.attributes,
			birth_date: shortDate(this.props.user.attributes.birth_date)
		},
		loading: false
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.loading(true)
		const { user, userID } = this.state
		const { dispatch } = this.props
		const { created_at, updated_at, plan, ...rest } = user

		const attributes = {
			...rest,
			birth_date: getStartOfDay(rest.birth_date)
		}
		const body = objectToSend('users', attributes)
		console.log(body)

		debugger

		try {
			const user = await updateUser(userID, body)

			//añade el plan a user.attributes
			await getUserPlan(user)
			debugger

			dispatch(updateLoggedUser(user))
			alert('Se ha actualizado el usuario')

		} catch (error) {
			console.log(error)
			console.log(error.message)
			alert('Ha habido un error con la actualización del usuario')
		}

		this.loading(false)
	}

	handleChange = event => {
		const { target } = event
		validateBirthDay(target, this.setUserKey);
	}

	setUserKey = (target) => {
		if (target.name === 'Terminos-condiciones') {
			this.setState({
				user: {
					...this.state.user,
					tc_accepted_at: target.checked,
				}
			})
		} else {
			this.setState({
				user: {
					...this.state.user,
					[target.name]: target.value
				}
			})
		}
	}

	loading = (loading) => {
		this.setState({ loading })
	}

	handleInvalid = () => this.form.classList.add('was-validated')
	setRef = element => this.form = element

	render() {
		const { user, loading } = this.state;
		return (
			<Layout>
				<div className="profile p-0 p-md-5">
					<Card className="workshops-section p-4">
						<CardBody className="p-1 p-md-3">
							<ProfileInfo user={user} />
							<form
								ref={this.setRef}
								className="needs-validation profile__form"
								onSubmit={this.handleSubmit}
								onInvalid={this.handleInvalid}
							>
								<InputModal
									id="name"
									type="text"
									title="Nombre"
									autoComplete="name"
									className="form-control"
									placeholder="Ingresa tu nombre"
									handleChange={this.handleChange}
									value={user.name}
								/>

								<InputModal
									id="email"
									type="email"
									title="Correo Electrónico"
									className="form-control"
									handleChange={this.handleChange}
									value={user.email}
									multiple
								/>

								<InputDateModal
									type="date"
									id="birth_date"
									title="Fecha de Nacimiento"
									autoComplete="bday"
									className="form-control"
									handleChange={this.handleChange}
									defaultValue={user.birth_date}
								/>

								<InputCheck
									id="Terminos-condiciones"
									title="Acepto lo términos y condiciones"
									containerClass="TyC"
									handleChange={this.handleChange}
									checked={user.tc_accepted_at}
									link='/terminos-condiciones'
									required={true}
								/>

								<Button loading={loading} color="success" text="Guardar Cambios" />
							</form>
						</CardBody>
					</Card>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = ({ Login: { user } }) => ({
	user
})

export default connect(mapStateToProps)(Profile);