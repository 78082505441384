import React, { useState } from 'react'
import InputModal from '../../widgets/inputModal'
import UserItem from './UserItem'

const UserTable = ({ users, loading, searchedUsers, handleUser, setSearchedUsers }) => {

  const titles = [
    "Nombre",
    "Email",
    "Nacimiento",
    "Activo",
    "Plan",
    "ID de pago",
    "Fecha de creación",
    "Fecha de vencimiento",
    "Fecha A. TyC",
  ]

  const [search, setSearch] = useState("")

  const handleChange = ({ target: { value }}) => {

    const usersFiltered = users.filter(item => {
      const itemNameLowerCase = item.name.toLowerCase()
      const searchLowerCase = value.toLowerCase()

      return itemNameLowerCase.includes(searchLowerCase)
    })

    console.log(usersFiltered)
    setSearch(value)
    setSearchedUsers(usersFiltered)
  }

  return (
    <div data-test="datatable-table">
      <div data-test="table" className="table-responsive scrollea">

        <div className="input-search">
          <InputModal
            type="text"
            id="Buscar"
            className="form-control"
            placeholder="Buscar"
            value={search}
            handleChange={handleChange}
          />
        </div>

        {
          users.length ?
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  {
                    titles.map((item, i) =>
                      <th key={i} className="sorting">{item}</th>
                    )
                  }
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>

              <tbody data-test="table-body">
                {
                  users.map((user, i) =>
                    <UserItem
                      key={i}
                      user={user}
                      handleUser={handleUser}
                    />
                  )
                }
              </tbody>
            </table>
            :
            <span className="d-block text-center">
              Aún no tienes usuarios
            </span>
        }

      </div>
    </div>
  )
}

export default UserTable