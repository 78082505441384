import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './404.css'


class PageNotFound extends Component {
  render() {
    return (
      <>
        <div className="container-404 p-3">
          <h1 className="title-404">404</h1>
          <h2>Página no encontrada!</h2>
          <p>La página que estás buscando debió haber sido removida, le han cambiado el nombre o está temporalmente inaccesible.</p>
          <Link to="/" className="btn btn-success text-dark font-weight-bold btn-rounded">Volver</Link>
        </div>
      </>
    );
  }
}

export default PageNotFound;