import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {getUserData} from '../../helpers/getUserData';
import {registerFailed, registerReset} from '../../store/actions';
import VerifyUserForm from './verifyUserForm';

class VerifyUser extends Component{
  
  state={
    user:{
      email: '',
      password: ''
    },
    loading: false
  }
    
  loading = (loading) =>{
    this.setState({loading})
  }
  
  handleSubmit = async event =>{
    this.loading(true)
    event.preventDefault()
    const user = this.state.user
    
    try {
      const data = await getUserData(user.email, user.password)
      const { attributes:{ is_admin }} = data
      
      if(!is_admin){
        this.props.dispatch(registerReset())
        this.props.setVerification(data)
      }else{
        this.props.dispatch(registerFailed())
      }
      
    } catch (error) {
      console.log(error)
      this.loading(false)
      this.props.dispatch(registerFailed())
    }
  }
  
  handleChange = ({target}) =>{
    if(target.name === "email"){
      
      this.setState({
        user: {
          ...this.state.user,
          [target.name]: target.value.toLowerCase()
        }
      })
      
    }else{
      this.setState({
        user: {
          ...this.state.user,
          [target.name]: target.value
        }
      })
    }
  }
  
  handleInvalid = () => this.form.classList.add('was-validated')
  setRef = element => this.form = element
  
  render(){
    return(
      <VerifyUserForm
        user={this.state.user}
        setRef={this.setRef}
        handleSubmit={this.handleSubmit}
        handleInvalid={this.handleInvalid}
        handleChange={this.handleChange}
        loading={this.state.loading}
        registerSuccess={this.props.registerSuccess}
        registrationError={this.props.registrationError}
      />
    )
  }
}

const mapStatetoProps = ({Account:{ registerSuccess, registrationError }}) => ({
  registerSuccess, 
  registrationError, 
})


export default connect(mapStatetoProps)(VerifyUser);