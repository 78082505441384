import SEARCH_USER_TABLE from './actionTypes';
import {filterSearch} from '../../helpers/filterSearch'

export const userSearch = (search, users) =>{

  const searchFiltered = filterSearch(search, users)

  return{
    type: SEARCH_USER_TABLE,
    payload: searchFiltered
  } 
}