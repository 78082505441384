import React from 'react'
import CustomModal from './customModal';
import Button from './button';

const MessageModal = ({message, btnColor, handleClick, loading, title, children}) => {
  return (
    <CustomModal className="center" type="small" zIndex={9999}>
      <div className="confirmModal">
        <div className="confirmModal__description">
          <div className="pb-4">
            {children}
          </div>
          <h2><strong>{title}</strong></h2>
          <p>{message}</p>
        </div>
        <div className="buttons-container">
          <Button 
            handleClick={handleClick} 
            color={`${btnColor} font-weight-bold`}
          >
            {loading? 'Cargando...' : 'Aceptar'}
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default MessageModal
