import { combineReducers  } from 'redux';
import Layout from './layout/reducer';

//============ Auth Reducers =============*
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

//============ Guests Reducers =============*
import Guests from './guests/reducer'

//============ Users Reducers =============*
import Users from './users/reducer'

//============ Workshops Reducers =============*
import Workshops from './workshops/reducer'


const rootReducer = combineReducers({ Layout, Account, Login, Forget, Guests, Users, Workshops });

export default rootReducer;