import { API, userUrlRequest, guestUrlRequest, emails, workshopUrlRequest } from '../api/apiURLs'
import getColumns from './changeKeyNames';
import Axios from 'axios';

export const getUserByEmail = async (userEmail) =>{
  const { data:{ data }} = await Axios.get(`${API}${userUrlRequest}${emails}/${userEmail}`)
  return data;
}
export const getUserByID = async (userID) =>{
  const { data:{ data }} = await Axios.get(`${API}${userUrlRequest}/${userID}`)
  return data;
}
export const getGuestByEmail =  async (guestEmail) => {
  const { data: { data }} = await Axios.get(`${API}${guestUrlRequest}${emails}/${guestEmail}`);
  return data;
}
export const getGuestByID =  async (guestID) => {
  const { data: { data }} = await Axios.get(`${API}${guestUrlRequest}/${guestID}`);
  return data;
}
export const getGuestWorkshops =  async (guestID) => {
  const { data: { data }} = await Axios.get(`${API}${workshopUrlRequest}/guest/${guestID}`);
  return data;
}

//Esta funcion trae a los invitados
export const getGuest = async (user) =>{
  
//debugger
  
  const dataForGuestTable = {};      
  // console.log(userID)
  if(user.is_admin){
    
    //Si es admin se traen todos los invitados
    const {data: { data: guests }} = await Axios.get(`${API}${guestUrlRequest}`)
  
    // Los datos de los invitados se colocan en rows y columns para que lo pueda leer el componente 
    // que los va a renderizar
    
    dataForGuestTable.rows = guests.map(guest => guest)
    
    // parseAllDatesAttributes()
    
  }else{ 
    //Si no es admin los se traen solo los invitados de ese usuario
    const { data: { included }} = await Axios.get(`${API}${userUrlRequest}/${user.id}${guestUrlRequest}`)
  //debugger
    if(included){
      dataForGuestTable.rows = included.map(guest => guest)
    }else{
      //En el caso de que el usuario no tenga invitados aún
      dataForGuestTable.rows = []
    }
  }
  
  if(dataForGuestTable.rows.length){
    
    //Se obtienen las columnas a partir de los atributos
    const commonGuest = dataForGuestTable.rows[0].attributes
    dataForGuestTable.columns = getColumns(commonGuest)
    
  }else{
    //En el caso de que el usuario no tenga invitados aún
    dataForGuestTable.columns = []
  }
  
  return dataForGuestTable;
}

//Esta funcion trae todos los planes
export const getPlans = async () => {
  const { data: { data: plans }} = await Axios.get(`${API}/plans`)
  return plans;
}

export const getUserPlan = async (userData) =>{
  try {
    
    const {plan_id} = userData.attributes
    const plan = await getPlanByID(plan_id)
    return plan;
    
  } catch (error) {
    return 'N/A';
  }
}

//Esta funcion trae el plan por ID
export const getPlanByID = async planID => {
  const { data:{ data: plan }} = await Axios.get(`${API}/plans/${planID}`)
  return plan;
}

// funcion usada por getGuestsByUsers y filterAndGetUsers en esta archivo
export const getUsers = async () => {
  const {data: { data }} = await Axios.get(`${API}${userUrlRequest}`)
  const users = data.filter(user => user.attributes.is_admin !== true)
  return users;
}

export const getAdmins = async () => {
  const {data: { data }} = await Axios.get(`${API}${userUrlRequest}`)
  const users = data.filter(user => user.attributes.is_admin === true)
  return users;
}

// La info que trae esta funcion se mostrará en el dashboard
export const getGuestsByUsers = async()=>{
  const users = await getUsers()
  const guestsByUsers = [] 
  // debugger
  
  for (const user of users) {
    try {
     
      const { data } = await Axios.get(`${API}${userUrlRequest}/${user.id}${guestUrlRequest}`)
      const { data:{ relationships:{ guests:{ data: guests }}}} = data
      
      guestsByUsers.push({ user, data: guests })
      
    } catch (error) {
      console.log(error)
    }
  }
  
  return guestsByUsers;
}

// La info que trae esta funcion se mostrará en el dashboard
export const getTotalGuest = async () => {
  const { data:{ data }} = await Axios.get(`${API}${guestUrlRequest}`)
  return data;
}

// Esta funcion trae a todos los usuarios y los filtra para solo mostrar a los facilitadores
export const filterAndGetUsers = async () =>{
  let users = {}
  users.rows = []
  
  let totalUsers = await getUsers()
  
  for (const user of totalUsers) {
    
    //Updated_at se desecha
    const { updated_at, payment_id, plan_id, ...rest } = user.attributes
    
    user.attributes = {
      ...rest,
      plan_id,
      payment_id: payment_id || "N/A",
    }
    
    try {
      const { attributes: { name: userPlan }} = await getPlanByID(plan_id)
      user.attributes = {
        ...user.attributes,
        plan: userPlan,
      }
    } catch (error) {
      user.attributes = {
        ...user.attributes,
        plan: 'N/A',
      }
    }
    // debugger
    users.rows.push(user)
  }
  
  const commonUser = users.rows[0].attributes
  users.columns = getColumns(commonUser)
  
  return users;
}

//Esta funcion trae a todos los talleres
export const getWorkshops = async () =>{
  const { data:{ data }} = await Axios.get(`${API}${workshopUrlRequest}`)
  return data;
}

export const getWorkshopById = async (workshopID) =>{
  const { data: { data }} = await Axios.get(`${API}${workshopUrlRequest}/${workshopID}`)
  return data;
}

export const getWorkshopGuests = async (workshopID) => {
  const { data:{ data }} = await Axios.get(`${API}${workshopUrlRequest}/${workshopID}${guestUrlRequest}`)
  return data;
}

export const getAllWorkshopsGuests = async (workshops) =>{
  const guests = [];
  
  try {
    
    for (const workshop of workshops) {
      const workshopGuests = await getWorkshopGuests(workshop.id)
      guests.push(workshopGuests)
    }
    
  } catch (error) {
    console.log(error)
    alert('Hubo un error')
  }
  return guests;
}

//Esta funcion trae a todos los talleres por usuarios
export const getUserWorkshops = async (user) =>{
  // debugger
  
  if(user.is_admin){
    const data = await getWorkshops();
    return data;
  }else{
    const { data:{ data }} = await Axios.get(`${API}${workshopUrlRequest}/user/${user.id}`)
    return data;
  }
}

//Esta funcion trae a todos los usuarios con sus respectivos talleres
export const getWorkshopsByEveryUser = async () =>{
  const users = await getUsers()
  const workshopsByUser = []
  
  for (const user of users) {
    user.is_admin = false;
    const data = await getUserWorkshops(user)
    
    delete user.is_admin;
    workshopsByUser.push({ user, data })
  }
  
  return workshopsByUser;
}