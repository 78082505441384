import Axios from "axios";
import { API, userUrlRequest } from "../api/apiURLs";
import { setDataToLogin } from "./utils";

export const getUserData = async (email, password) => {
  const dataToPost = setDataToLogin(email, password)
  const { data: { data } } = await Axios.post(`${API}${userUrlRequest}/login`, dataToPost)
  return data;
}

export const formatUserData = async (user) => {
  const { is_admin, is_active, due_date } = user.attributes
  return ({
    id: user.id, //ID del constelador para pasarselo al fetch
    is_admin,
    is_active,
    due_date,
  })
}