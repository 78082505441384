export const filterSearch = (search, users) =>{
  
  // debugger
  
  const usersFiltered = users.filter(item =>{
    const itemNameLowerCase = item.name.toLowerCase()
    const searchLowerCase = search.toLowerCase()
    
    return itemNameLowerCase.includes(searchLowerCase)
  })
  
  console.log(usersFiltered)
  
  return usersFiltered
}