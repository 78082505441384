
const getColumns = user =>{
  const columns = []
  
  for (const attribute in user) {
    
    switch (attribute) {
      case 'name':
        columns.push({
          label: 'Nombre',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
        
      case 'email':
        columns.push({
          label: 'Email',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
        
      case 'plan':
        columns.push({
          label: 'Plan',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
        
      case 'birth_date':
        columns.push({
          label: 'Nacimiento',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
    
      case 'due_date':
        columns.push({
          label: 'Fecha de vencimiento',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
    
      case 'created_at':
        columns.push({
          label: 'Creado',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
    
      case 'tc_accepted_at':
        columns.push({
          label: 'Fecha A. TyC',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
    
      case 'payment_id':
        columns.push({
          label: 'id pago',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
        
      case 'is_active':
        columns.push({
          label: 'Activo',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
        
      case 'guest_id':
        columns.push({
          label: 'ID Invitado',
          field: attribute,
          sort: 'asc',
          width: 170
        })
        break;
    
      default:
        break;
    }
  }
  
  return columns
}

export default getColumns;