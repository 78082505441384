import React from 'react'

const UserBodyTable = props => (
  <tbody data-test="table-body">
    {
      props.children
    }
  </tbody>
)

export default UserBodyTable;