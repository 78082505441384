import React from 'react'

const LoadingMessage = ({ loading, itemList, message }) => {
  return (
    <>
      {
        loading ?
          <div className="LoadingNotificationMessage column-direction">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <span>
              Cargando...
            </span>
          </div>
          :
          (itemList.length === 0) &&
          <span className="LoadingNotificationMessage">
            {message}
          </span>
      }
    </>
  )
}

export default LoadingMessage;