import React from 'react'
import Button from '../../widgets/button';
import ArrowDown from '../../widgets/icons/arrowDown';
import ArrowUp from '../../widgets/icons/arrowUp';
import { orderPlansByIndex } from './utils/orderPlans';

const OrderButton = ({ direction, plan, orderedPlans, setOrder, disabled }) => {

  const handleChangeOrder = () => {
    let planToBeMoved = { ...plan }
    let newOrderPlans = [];
    let { display_order: order } = planToBeMoved.attributes

    //debugger

    newOrderPlans = orderedPlans.filter(plan => plan.id !== planToBeMoved.id)

    if (direction === "up") {
      order--
    } else if (direction === "down") {
      order++
    }

    planToBeMoved.attributes.display_order = order;

    //coloca el plan en la posicion order - 1
    newOrderPlans.splice((order - 1), 0, planToBeMoved)

    newOrderPlans = orderPlansByIndex(newOrderPlans)

    setOrder(newOrderPlans, true)
  }

  return (
    <Button handleClick={handleChangeOrder} loading={disabled} color="secondary">
      {
        (direction === 'up') ?
          <ArrowUp /> :
          (direction === 'down') ?
            <ArrowDown /> :
            null
      }
    </Button>
  )
}

export default OrderButton;