import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';


// Layout After Login
const AuthLayoutContent = (props) => {
  var pathname = window.location.pathname.replace('/', ''); 
  return <>
    <div className="page-container">
      <Sidebar />
      <div 
        className={(pathname === 'blank') ? 
          'is_blank_page main-content' 
        : 
          'main-content'} id="main_content_sec"
      >
                
        <Header />
        <div className="main-content-inner">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  </>
}

class Layout extends Component {
  render() {
    const { is_logged } = this.props
    // debugger
    return (
      <>
        {
          (is_logged) ? 
            <AuthLayoutContent {...this.props} /> 
          : 
            // <NonAuthLayoutContent {...this.props} />
            <Redirect to="/"/>
          }
      </>
    );
  }
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
    is_logged: state.Login.is_logged
  };
}

export default withRouter(connect(mapStatetoProps)(Layout));
