import React, { Component } from 'react';
import Modal from '../../widgets/modal';
import Button from '../../widgets/button';
import FooterModal from '../../widgets/footerModal';
import InputModal from '../../widgets/inputModal';
import InputDateModal from '../../widgets/inputDateModal';
import { updateGuest } from '../../helpers/sendData';
import { setLoading, updateGuestsState } from '../../store/actions';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { connect } from 'react-redux';
import { shortDate } from '../../utils/parseDate/formatDisplay';
import { aboveAge, isNot_a_FutureDay } from '../../utils/parseDate/validation';
import { getStartOfDay } from '../../utils/parseDate/getValues';

class GuestModal extends Component {

  state = {
    guest: {
      ...this.props.guest,
      attributes: {
        ...this.props.guest.attributes,
        birth_date: shortDate(this.props.guest.attributes.birth_date)
      }
    },
    error: null,
  }

  handleSubmit = async event => {
    event.preventDefault()
    const { guest } = this.state;
    const { created_at, updated_at, ...rest } = guest.attributes;
    const { userGuests, total_guests, dispatch, handleCloseModal, setNotification, messages } = this.props;

    dispatch(setLoading(true))

    const attributes = {
      ...rest,
      birth_date: getStartOfDay(rest.birth_date)
    }

    //debugger

    const body = objectToSend('guest', attributes)

    try {
      await updateGuest(guest.id, body)
      dispatch(updateGuestsState(userGuests, guest, total_guests, 'EDIT'))
      setNotification(true, messages.successUpdate, 'success')
      dispatch(setLoading(false))
      handleCloseModal()

    } catch (error) {

      console.log(error)
      setNotification(true, messages.errorUpdate, 'danger')
      dispatch(setLoading(false))
      handleCloseModal()
    }
  }

  handleInvalidSubmit = () => {
    this.form.classList.add('was-validated')
  }
  asignValue = target => {
    this.setState({
      ...this.state,
      guest: {
        ...this.state.guest,
        attributes: {
          ...this.state.guest.attributes,
          [target.name]: target.value
        }
      }
    })
  }

  handleChange = event => {
    const { target } = event

    if (target.name === 'guest_birth_date') {
      const date = target

      if (isNot_a_FutureDay(date.value)) {

        if (aboveAge(date.value)) {
          this.asignValue(target)

        } else {
          alert('El invitado debe ser mayor de edad')
          date.value = this.state.guest_birth_date
        }

      } else {
        alert('Fecha invalida')
        date.value = this.state.guest_birth_date
      }

    } else {
      this.asignValue(target)
    }
  }

  setRef = element => {
    this.form = element
  }

  render() {
    const { loading } = this.props;
    const { handleCloseModal, user } = this.props;
    const { guest } = this.state;
    return (
      <Modal
        title="Editar Invitado"
        setRef={this.setRef}
        handleSubmit={this.handleSubmit}
        handleInvalidSubmit={this.handleInvalidSubmit}
      >
        <InputModal
          type="text"
          id="name"
          title="Nombre de usuario"
          className="form-control"
          handleChange={this.handleChange}
          value={guest.attributes.name}
        />
        <InputModal
          type="email"
          id="email"
          title="Email"
          className="form-control"
          handleChange={this.handleChange}
          value={guest.attributes.email}
        />
        {
          user.is_admin &&
          <InputDateModal
            type="date"
            title="Fecha de Nacimiento"
            id="birth_date"
            className="form-control"
            handleChange={this.handleChange}
            defaultValue={guest.attributes.birth_date}
          />
        }
        <FooterModal>
          <Button loading={loading} text="Guardar" color="success" type="submit" />
          <Button loading={loading} text="Cerrar" color="danger" handleClick={handleCloseModal} />
        </FooterModal>
      </Modal>
    )
  }
}

const mapStateToProps = ({ Login: { loading, userGuests, total_guests, user: { id, attributes: { is_admin } } } }) => {
  return {
    loading,
    userGuests,
    total_guests,
    user: {
      id, is_admin
    }
  }
}

export default connect(mapStateToProps)(GuestModal);