import React from 'react';
import { Card, CardBody } from 'reactstrap';
import WidthLimit from '../../layout/widthLimit';

const TyC = () => {
  return (
    <WidthLimit>
      <Card className="width-limit__container mt-md-5 pt-4 px-md-5">
        <CardBody>
          <h1 className="card_title font-weight-bold" style={{ color: '#ec7967' }}>Términos y Condiciones de Uso de la Tecnología de Vincor Online y sus Servicios Asociados</h1>

          <section className="text-justify">
            <p>Por favor lea con cuidado estos Términos de Servicio antes de acceder o usar este sitio web (el "Sitio").</p>
            <p>Los presentes Términos y Condiciones pertenecen a Damián Romero en su carácter de titular de Vincor Online Online (en adelante <strong>“Vincor Online”</strong> o la <strong>“Empresa”</strong>) y rigen el uso por parte de los Usuarios (según se define a dicho término en el presente documento) de la Tecnología de propiedad de Vincor Online S.A (en adelante la <strong>“Tecnología”</strong> o la <strong>“Tecnología de Vincor Online”</strong>), consistente en:</p>

            <ul className="tyc-list">
              <li>1.- La plataforma virtual Vincor Online (en adelante la <strong>"Plataforma”</strong>) a través de la cual se accede a los productos y servicios de Vincor Online disponibles para Usuarios registrados.</li>
              <li>2.- Las Salas Virtuales de Vincor Online para la realización de sesiones de constelaciones remotas (en adelante las <strong>“Salas Virtuales”</strong> o las <strong>“Salas”</strong>) a las que acceden los Usuarios registrados tanto Anfitrión como Participantes de los Anfitrión, según se definen en el presente. Las Salas Virtuales a su vez, contienen diversos productos y servicios tecnológicos asociados.</li>
              <li>3.- El Sitio Web de Vincor Online que brinda información, y acceso a diversos servicios, y permite el acceso a la Plataforma y a las Salas (en adelante el <strong>“Sitio”</strong>) y que a su vez cuenta con sus propios Términos y Condiciones y Políticas de privacidad que forma parte integral del presente documento.</li>
              <li>4.- La Aplicación Móvil de Vincor Online que permite el acceso a la Plataforma, a las Salas Virtuales, al Sitio en su versión móvil (en adelante la <strong>“Aplicación Móvil”</strong>).</li>
              <li>5.- Todo otro producto y/o servicio disponible o accesible a través de la Plataforma, las Salas Virtuales y/o el Sitio.</li>
            </ul>

            <p>Quien utilice y/o acceda a la Plataforma y/o a las Salas Virtuales provistas por Vincor Online de cualquier forma, será considerado un Usuario (en adelante, el <strong>“Usuario”</strong> o los <strong>“Usuarios”</strong>). Además, se entiende por Usuario tanto a los Usuarios que se registren como <strong>Usuario Anfitrión</strong> (según se define más adelante) como aquellos Usuarios que no habiéndose registrado como Usuarios Anfitrión utilicen la Tecnología de Vincor Online por invitación de algún Usuario Anfitrión (en adelante los <strong>“Participantes”</strong>) y/o de cualquier otra forma. En igual sentido, se entenderán como Usuarios a los visitantes del Sitio y/o a los Usuarios de la Aplicación Móvil y/o de la Tecnología de Vincor Online según se describe a la misma en el presente documento. El uso y/o descarga de la Tecnología de Vincor Online por el Usuario implica la lectura, entendimiento y aceptación de los presentes Términos y Condiciones.</p>
            <p>Los Usuarios declaran ser plenamente capaces y competentes para aceptar y cumplir los Términos y Condiciones, utilizar los servicios y productos habilitados en la Plataforma y poder celebrar acuerdos jurídicamente vinculantes conforme a las leyes vigentes. Si los Usuarios no estuvieran de acuerdo con estas condiciones, deberán abstenerse de utilizar la Plataforma.</p>
            <p><strong>TODO AQUEL QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES SON OBLIGATORIOS Y VINCULANTES, DEBERÁ ABSTENERSE DE INGRESAR Y/O UTILIZAR LA PLATAFORMA, LAS SALAS VIRTUALES, EL SITIO, LA APLICACIÓN MOVIL, ASÍ COMO TODOS LOS PRODUCTOS, SERVICIOS Y/O FUNCIONALIDADES ASOCIADOS A ESTOS. Asimismo, se hace saber que la Tecnología de Vincor Online se encuentra sujeta, en igual medida, a todos los avisos, instrucciones, políticas de privacidad, términos particulares, términos y condiciones complementarias presentes o que en el futuro pueda publicar la Empresa en la Plataforma, en las Salas Virtuales y/o, en el Sitio y/o en la Aplicación Móvil, las cuales serán comunicadas a los Usuarios.</strong></p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">1. La Plataforma y las Salas Virtuales</h2>
            <p>La Empresa pone a disposición de los Usuarios salas de reunión virtuales diseñadas para que las utilicen aquellas personas que se dediquen a realizar constelaciones familiares, constelaciones organizacionales, terapias tales como, counseling, couching, psicólogía y/o terapias holísticas y/o terapias corporales para sus actividades y servicios (en adelante los <strong>“Anfitriones”</strong> o el <strong>“Anfitrión”</strong> y los <strong>“Servicios Terapéuticos”</strong>) e inviten a tales efectos, a sus propios Participantes a dichas salas. Así, además de poner a disposición un espacio virtual a través del cual los participantes de la reunión podrán realizar un llamado de tipo videoconferencia, interactuar de forma remota, chatear y/o compartir imágenes, videos y otro tipo de contenidos en línea, se pondrán funcionalidades específicas a disposición para desarrollar actividades relacionadas con este tipo de sesiones (en adelante, todos ellos, los <strong>“Servicios”</strong>).</p>
            <p>Con relación a los Servicios Terapéuticos brindados por los Anfitriones, la Empresa se limita a prestar el espacio virtual para los Anfitriones quienes son los únicos y exclusivos responsables por la praxis y uso que éstos hagan de la Plataforma y de las Salas Virtuales.</p>
            <p>En este sentido, la Empresa no tiene relación de tipo sociedad conjunta, “joint-venture”, empleador, franquiciante, agente y/o de cualquier otro tipo, ni tiene el Usuario o Usuario Anfitrión facultad de obligar a la Empresa en relación a la prestación de sus Servicios Terapéuticos.</p>
            <p>La Empresa no controla ni fiscaliza las facultades, titulaciones y habilitaciones profesionales, técnicas y/o de cualquier otro tipo que pudiera arrogarse el Anfitrión y/o que sea regulatoriamente necesaria para la prestación de los Servicios Terapéuticos que los Anfitrión brinden a través de las Salas Virtuales provistas en la Plataforma. En consecuencia, los Anfitrión y/o Usuarios son los únicos y exclusivos responsables por las interacciones o usos que de la Plataforma éstos y sus Participantes hagan.</p>
            <p><strong>SE DEJA EXPRESAMENTE ACLARADO QUE LA EMPRESA NO ES UNA ENTIDAD DE MEDICINA PREPAGA, NI OBRA SOCIAL, NI INSTITUTO DE ADMINISTRACIÓN MIXTA, NI RESULTA SUJETO OBLIGADO POR LA LEY DE MEDICINA PREPAGA N° 26.682, LEY DE SEGURO DE SALUD N° 23.661, LA LEY DE OBRAS SOCIALES N° 23.660, LA LEY Nº 27.553, NI PRESTA POR SÍ O A TRAVÉS DE TERCEROS, SERVICIOS DE TELEMEDICINA, SALUD MENTAL PSICOTERAPÉUTICOS, TERAPÉUTICOS, MÉDICOS, ASISTENCIALES Y/O DE URGENCIA DE NINGÚN TIPO. EN IGUAL SENTIDO, LA EMPRESA NO ESTÁ SUJETO AL CONTROL DE LA SUPERINTENDENCIA DE SERVICIOS DE SALUD NI NINGÚN OTRO ORGANISMO QUE EN EL FUTURO LA REEMPLACE.</strong></p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">2. Uso de la Plataforma y de las Salas Virtuales por los Usuarios</h2>
            <p>Para utilizar los Servicios y dar acceso a otros Usuarios las Salas Virtuales, los Anfitrión deberán crear una cuenta de Usuario en la Plataforma, completando la información solicitada por la Empresa con datos veraces y actuales, y seguir las indicaciones previstas en el punto 4 del presente, y aceptar los presentes Términos y Condiciones (en adelante <strong>“Usuario Anfitrión”</strong> o <strong>“Usuarios Anfitrión”</strong>). Los Usuarios Anfitriones podrán invitar a la Sala Virtual mediante el mecanismo allí previsto a los Participantes, los que también se encuentran alcanzados por estos Términos y Condiciones. Los Usuarios Anfitriones deberán dar el acceso a la Sala Virtual a los Participantes en los términos que la Plataforma prevé a tal efecto. Asimismo, Vincor Online requerirá el registro de los Participantes en los términos que se describen en el punto 5.2 a fin de habilitarles el acceso a la Plataforma.</p>
            <p>Un Usuario Anfitrión podrá registrarse como “Administrador” de los Usuarios Anfitriones que relacionen su cuenta como dependientes a este Administrador. Este Usuario Administrador podrá acceder a la información de suscripción de los Usuarios Anfitriones relacionados, así como a la información acerca del número de sesiones que llevan realizadas estos Usuarios, información de sus Participantes, entre otras cuestiones relacionadas a la cuenta del Usuario Anfitrión relacionada al Usuario Anfitrión Administrador.</p>
            <p>La Plataforma y las Salas Virtuales y todos sus Servicios deberán ser utilizados por los Usuarios Anfitrión de forma responsable y con la única intención de prestar servicios vinculados a los Anfitriones en tal carácter, sin generar otro tipo de actividades ni vínculos ajenos a esta finalidad. Además, la Plataforma deberá ser utilizada por los Usuarios de conformidad con estos Términos y Condiciones, los Términos y Condiciones del Sitio y sus Políticas de Privacidad, y toda otra normativa que resulte aplicable, siendo los Usuarios los únicos responsables del uso de la Plataforma y las Salas Virtuales y sus Servicios asociados, y debiendo así garantizar el cumplimiento de las normas que le resulten aplicables, incluyendo, pero no limitando a aquellas relacionadas con el respeto de la ética de sus respectivas prácticas terapéuticas, así como con la confidencialidad, privacidad, protección de datos personales de sus Participantes, y/o con propiedad intelectual de terceros, entre otras. </p>
            <p>Los Usuarios se obligan a hacer un uso adecuado de la Plataforma y de las Salas Virtuales y sus Servicios asociados, utilizándolos exclusivamente para las actividades y servicios relacionados a los Anfitrión, y a no emplearlos para realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros y/o que infrinjan la regulación aplicable, así como tampoco para los fines y usos prohibidos que se detallan a continuación. Los Usuarios serán los únicos responsables del contenido compartido y de la información ingresada, liberando a la Empresa, y a las entidades relacionadas a ella, de cualquier responsabilidad legal o de hecho y manteniéndolas indemnes en todo momento (aun habiéndose finalizado o desinstalado la aplicación) ante cualquier reclamo de terceros.</p>

            <span class='subtitle1'>2.1. Operatoria de la Sala</span>
            <p>El Anfitrión puede crear tantas Salas Virtuales como desee teniendo en cuenta que la creación de una Sala equivale a una sesión para el sistema por lo que no podrá crear Salas de forma simultánea.</p>
            <p>Los Participantes, invitados de un Anfitrión, deberán registrarse conforme el punto 5.2. para acceder a la Plataforma y a la Sala. Además, los Participantes no se encontrarán habilitados al ingreso a la Sala hasta tanto el Anfitrión así lo permita una vez que el Anfitrión haya ingresado a la Sala y brinde acceso de ese modo a los Participantes. En este sentido, se hace saber que los Participantes deberán aguardar a que el Anfitrión habilite el acceso a la Sala en cuestión.</p>
            <p>El Anfitrión sólo podrá admitir en la Sala a la cantidad de Participantes que su suscripción permita. Si hubiera invitado más Participantes, una vez cumplido el cupo de la suscripción los restantes Participantes no podrán ingresar a la Sala. Con relación a esto, se aclara que el número de Participantes se determina de acuerdo a la cantidad de Participantes efectivamente conectados y admitidos en la Sala.</p>
            <p>El Anfitrión podrá admitir Participantes en la misma Sala sin necesidad de cerrar sesión. Lo anterior siempre de acuerdo con las condiciones de la suscripción que haya contratado y entendiendo que para el caso de suscripciones gratuitas la Sala se cerrará a las 12 hs desde que la misma haya sido creada.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">3. Usos Prohibidos</h2>
            <ul className="tyc-list pl-4">
              <li>Los siguientes usos, actividades y finalidades con relación a la Tecnología de Vincor Online se encuentran terminantemente prohibidos:</li>
              <li>i. Participar en actividades ilegales, fraudulentas, falsas o engañosas;</li>
              <li>ii. Declarar falsamente o tergiversar la competencia del Usuario Anfitrión, su título, competencia, habilitación, filiación con cualquier persona o afiliación a entidad, expresar o publicar información falsa o inexacta sobre sí, sobre los Servicios Terapéuticos brindados por sí, o sobre la Empresa;</li>
              <li>iii. Transmitir, reproducir cualquier material que pueda infringir la propiedad intelectual u otros derechos de terceros;</li>
              <li>iv. Construir o comparar un producto o servicio de la competencia, o copiar cualquier característica, función o gráfico de los Servicios;</li>
              <li>v. Utilizar la Tecnología de Vincor Online o sus Servicios para comunicar cualquier mensaje, contenido o material que sea acosador, abusivo, difamatorio, calumnioso, amenazador, obsceno, indecente, discriminatorio por razones raciales, religiosas, sexuales, de género o que puedan resultar de cualquier otra manera desagradable u ofensivo para tercero; que infrinja los derechos de propiedad intelectual de cualquier parte o que sea ilegal de cualquier forma, que derive en responsabilidad civil, o que constituya o fomente una conducta que pueda constituir un delito, bajo cualquier ley o reglamento aplicable;</li>
              <li>vi. Cargar o transmitir cualquier software, contenido o código que dañe o tenga intención de dañar, deshabilitar, destruir o afectar adversamente al rendimiento de los Servicios de alguna forma o que dañe o tenga intención de dañar o extraer información o datos de otro hardware, software o redes de la Empresa o de otros usuarios de la Tecnología de Vincor Online o sus Servicios; </li>
              <li>vii. Llevar a cabo acciones que impongan o pueda imponer, a nuestro criterio, una carga elevada en la infraestructura de la Plataforma o de las Salas Virtuales;</li>
              <li>viii. Evadir las medidas de seguridad físicas o informáticas que la Empresa, o que las empresas relacionadas a ella dispongan a los fines de evitar o restringir el acceso a la Plataforma o a las Salas Virtuales;</li>
              <li>ix. Participar en cualquier actividad o utilizar la Tecnología de Vincor Online o sus Servicios de forma que puedan dañar, deshabilitar, sobrecargar, perjudicar, interferir o afectar de cualquier forma a la Tecnología de Vincor Online o sus Servicios, o a cualquier servidor o redes conectados a los Servicios o a los sistemas de la Empresa; </li>
              <li>x. Copiar, modificar, alterar, adaptar, poner a disposición, traducir, realizar ingeniería inversa, descompilar y desensamblar cualquier parte de la Tecnología de Vincor Online, de cualquier manera; crear un browser, marco (framing), u otros desarrollos alrededor de la Tecnología de Vincor Online sin autorización expresa y por escrito de la Empresa;</li>
              <li>xi. Reproducir, duplicar, copiar, vender, revender o explotar, total o parcialmente la Tecnología de Vincor Online o sus Servicios, herramientas o contenidos sin el consentimiento expreso y por escrito de la Empresa;</li>
              <li>xii. Reproducir, grabar, copiar y/o de cualquier forma violar la confidencialidad de las comunicaciones que se entablen entre los Usuarios a través de la Tecnología de Vincor Online. En particular, se encuentra terminantemente prohibido a los Usuarios grabar y/o de cualquier forma capturar la imagen, identidad, voz y/o cualquier otro aspecto de los Usuarios que interactúan en la Plataforma y en las Salas Virtuales;</li>
              <li>xiii. Interferir y/o violar los derechos de otros Usuarios, recopilar datos e información sobre otros Usuarios sin su consentimiento expreso, ya sea manual o automáticamente con el uso de cualquier tecnología, robot o similar, utilizar otro dispositivo automático, proceso o método de acceso a la Plataforma o a las Salas Virtuales, recuperar, indexar y/o extraer cualquier información de la Tecnología de Vincor Online o de sus Servicios y/o de otros Usuarios; y</li>
              <li>xiv. Utilizar la Tecnología de Vincor Online o sus Servicios de manera que contravengan cualquier política de la Empresa o de alguna forma que suponga una infracción de la ley aplicable, incluidas, entre otras, las leyes y reglamentos contra correo no deseado, de privacidad y antiterrorismo, de confidencialidad, de propiedad intelectual, y/o leyes que requieran el consentimiento de los participantes de las grabaciones de audio y vídeo.</li>
            </ul>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">4. Registro de Cuenta de Usuarios Anfitrión</h2>
            <p>Los Anfitriones que deseen brindar sus Servicios Terapéuticos a favor de sus Participantes, a través de la Plataforma y utilizar las Salas Virtuales y sus Servicios deberán registrarse a tal efecto en ella, dar de alta su cuenta y abonar el precio de la membresía que le resulte aplicable. Las tarifas y condiciones de tales planes estarán disponibles en www.vincoronline.com.ar </p>
            <p>A los efectos del registro, se le solicitará completar el formulario de registración con ciertos datos como su nombre, apellido, tipo y número de documento, fecha de nacimiento, domicilio, correo electrónico, matrícula profesional (si correspondiera), entre otros. El Anfitrión se obliga a proveer información precisa, verdadera y exacta y mantenerla actualizada. Asimismo, se deja expresa constancia que toda la información provista por el Anfitrión tendrá carácter de declaración jurada.</p>
            <p>Al remitir el formulario de registración y escoger una contraseña el sistema enviará un email de confirmación con un enlace, a través del cual el Anfitrión validará su dirección de correo y se procesará el alta de la cuenta (en adelante la <strong>“Cuenta de Anfitrión”</strong>) a través de la cual podrá operar los Servicios siempre que haya abonado el precio de la membresía que correspondan a través de los medios de pago disponibles a tales efectos.</p>
            <p>La Empresa estará facultada por sí o a través de terceros, a verificar la exactitud y veracidad de toda información provista por los Usuarios y en particular a requerirle al Anfitrión documentación o información adicional en todo momento.</p>
            <p>Por último, la Empresa se reserva el derecho de rechazar una solicitud de registro, cancelar y/o suspender temporal o indefinidamente al Usuario Anfitrión en el caso en que se detectare incongruencias o inconsistencias en la información provista por éste provista.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">5. Cuenta de Usuario</h2>

            <span class='subtitle1'>5.1. Cuenta de Usuario</span>
            <p>El Usuario cuya solicitud de Cuenta de Anfitrión haya sido aceptada podrá acceder a la misma a través de la Plataforma ingresando su usuario y contraseña (en adelante las <strong>“Credenciales”</strong>).</p>
            <p>La Cuenta de Anfitrión es personal, única, intransferible y de exclusivo uso del Usuario Anfitrión. En vistas de lo anterior, queda prohibido para éste ceder o permitir el uso de su Cuenta de Anfitrión a un tercero o compartir o publicar por cualquier medio sus Credenciales.</p>
            <p>Atento a que el Usuario Anfitrión es el único autorizado a usar su Cuenta de Anfitrión y que sus Credenciales son de su exclusivo uso y conocimiento, se entenderán realizadas por el Usuario Anfitrión todas las actuaciones, operaciones, actos, transacciones y/o cualquier otro acto que se realice a través de su Cuenta de Anfitrión y bajo sus Credenciales. En consecuencia, será el Usuario Anfitrión el único responsable por todas las actividades que se produzcan en o a través de su Cuenta de Anfitrión, y éste deberá responder ante cualquier daño o perjuicio que el uso de su Cuenta de Anfitrión por parte de terceros no autorizados pudiera causar a la Empresa, a otros Usuarios y/o a cualquier tercero.</p>
            <p>El Usuario Anfitrión está obligado a guardar sus Credenciales bajo el más estricto secreto, debiendo notificar inmediatamente a la Empresa en el supuesto de conocimiento o sospecha de cualquier acceso o uso indebido por parte de un tercero de su Cuenta de Anfitrión y/o de violación de la confidencialidad de sus Credenciales, y debiendo modificar su contraseña de forma inmediate en tales circunstancias.</p>
            <p>La Empresa se reserva el derecho de bloquear el acceso a la Tecnología de Vincor Online y/o limitar temporal o definitivamente al Usuario Anfitrión, suspender o interrumpir la cuenta del Anfitrión, sus contenidos asociados, por cualquier motivo, a su entera discreción, además de otros recursos que puedan estar disponibles bajo cualquier ley que resulte aplicable. En particular, la Empresa podrá tomar este tipo de acciones cuando considere que el Anfitrión ha violado los presentes Términos y Condiciones.</p>

            <span class='subtitle1'>5.2. Usuario Participante</span>
            <p>El Usuario Anfitrión deberá completar ciertos datos del Participante a los efectos de poder enviarles a cada uno de ellos su respectiva invitación para acceder a la Sala según el procedimiento dispuesto en la Plataforma a tal efecto. Así, el Participante recibirá un correo o mensaje externo con los datos de la Sala y/o el taller al que fuera invitado y una clave de ingreso a la Sala en cuestión.</p>
            <p>Previo al ingreso a la Sala se le requerirá al Participante que se registre en la Plataforma. A tales fines, deberá indicar su nombre, apellido, tipo y número de documento, correo electrónico, fecha de nacimiento, entre otros y deberá designar una contraseña. La contraseña será asociada al correo electrónico del Participante y estas credenciales servirán de credenciales para el acceso a la Plataforma y a las Salas. Además, la aceptación de los presentes Términos y Condiciones es requisito obligatorio junto con la proporción de la información mencionada para el acceso a la Sala y los Servicio. Con relación a este proceso de registro se hace saber que el Anfitrión que envía la invitación se encuentra facultado para completar antes del envío de la invitación los datos listados necesarios para el registro del Participante, en tal caso, el Participante sólo deberá corroborar que la información sea correcta, designar una contraseña y aceptar los Términos y Condiciones.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">6. Obligaciones del Anfitrión</h2>
            <p>El Anfitrión es el único y exclusivo responsable por los servicios que preste a través de la Tecnología de Vincor Online y de cara a sus Participantes, debiendo velar por el cumplimiento de estos Términos y Condiciones por parte de los Participantes y por el buen uso de las Salas Virtuales tanto por sí como por sus Participantes. En particular, el Anfitrión estará obligado a informar los deberes de confidencialidad y secreto que rigen respecto del contenido que en la Sala Virtual se transmita, produzca y/o reproduzca. Así, y sin perjuicio de que los Participantes deberán aceptar estos Términos y Condiciones para acceder a la Sala Virtual y/o los Servicios, el Anfitrión se obliga a hacer saber a los Participantes de la existencia y contenido de estos Términos y Condiciones y de la obligación de secreto que alcana a los Participantes.</p>
            <p>Además, el Anfitrión se compromete a denunciar el uso indebido de la Tecnología de Vincor Online, de la Sala Virtual y/o de cualquiera de los Servicios por parte de cualquier Participante y/o tercero a través los canales de denuncia que a continuación se detallan. En igual sentido, se obliga a interrumpir cualquier sesión de Sala Virtual en la que se reproduzca o sucedan acciones indebidas y/o prohibidas por estos Términos y Condiciones.</p>
            <p>Canal de denuncia: legales@vincoronline.com</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">Costo de la Membresía. Acceso sin cargo para Usuarios Participantes del Anfitrión a las Salas Virtuales</h2>
            <p>Ingresando en www.vincoronline.com.ar, se detallan los precios y características de las membresías</p>
            <p>Asimismo, se hace saber que serán a cargo del Anfitrión todos los impuestos, cargos y tasas presentes o futuras que -de forma directa o indirecta- graven la documentación, transacción, operación y/o los Servicios Terapéuticos brindados por éstos a sus Participantes.</p>
            <p>Será de aplicación el mecanismo previsto en el apartado titulado “Modificaciones” de estos Términos y Condiciones en caso de que se modifiquen los precios aquí detalladas.</p>
            <p>El uso de la Sala Virtual por parte de los Participantes no tendrá cargo alguno para los Participantes, es decir que Vincor Online no les cobrará a los Participantes ningún precio por registrarse en el sitio, ni por el acceso y uso de las Salas Virtuales en el marco de una Invitación de un Anfitrión. </p>
            <p>Sin embargo, se hace saber a los Usuarios que el Anfitrión podrá cobrarle honorarios o aranceles por sus servicios los que son independientes de Vincor Online, y nada tienen que ver con los Servicios de Vincor Online. En ese sentido, se reitera que Vincor Online no tiene relación alguna con la actividad de Servicios Terapéuticas brindados por los Anfitrión, ni promociona sus servicios, ni cobra comisiones por ellos. </p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">7. Responsabilidad</h2>
            <p>A través de la Plataforma, los Anfitrión prestarán sus Servicios Terapéuticos, siendo éstos los exclusivos responsables por esos servicios que presten, y debiendo mantener indemne a la Empresa, quien sólo se limita a prestar la Tecnología de Vincor Online y sus Servicios asociados para la interacción entre los Anfitrión y sus Participantes. Cualquier relación contractual y/o de cualquier otro tipo que se genere entre los Participantes y los Anfitrión, es de exclusiva responsabilidad de los contratantes.</p>
            <p>La Empresa, sus accionistas, directores, empleados, subsidiarias, afiliadas, continuadoras y/o sucesoras, no se hacen responsables por los Servicios Terapéuticos de los Anfitrión, ni por ningún acto u omisión de los Participantes ni de los Anfitrión que pueda ocasionar al Participante o a otras personas un daño y/o perjuicio como consecuencia de dicha contratación.</p>
            <p>Las consultas o reclamos que los Participantes tengan respecto de las prestaciones recibidas en el marco de los Servicios Terapéuticos brindados por los Anfitrión deberán ser hechas directamente ante el Anfitrión, en tanto éstos son entidades completamente independientes de la Empresa. En consecuencia, la Empresa bajo ninguna circunstancia es ni será responsable de los daños directos, indirectos, incidentales, especiales, ejemplares, punitivos o emergentes, incluidos el lucro cesante derivados del uso de la Tecnología de Vincor Online y sus Servicios asociados.</p>
            <p>Los Anfitrión mantendrán indemne a la Empresa por todos los daños y perjuicios que le sean ocasionados como consecuencia del uso de la Tecnología de Vincor Online y sus Servicios asociados.</p>
            <p>La Empresa, al no ser una empresa de medicina prepaga, ni obra social, ni un prestador médico, ni prestador de asistencia psíquica u otra, ni aseguradora, no se encuentra obligada frente a los Participantes por los Servicios Terapéuticos y/o cualquiera otra prestación brindada por los Anfitrión a través de la Tecnología de Vincor Online y sus Servicios asociados. </p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">8. Disponibilidad de la Tecnología de Vincor Online y sus Servicios asociados</h2>
            <p>La disponibilidad y funcionalidad de la Tecnología de Vincor Online y sus Servicios asociados depende de varios factores, tales como las redes de comunicación, software, hardware y los proveedores de servicios de los Usuarios. EN FUNCIÓN DE LA NATURALEZA PROPIA DE INTERNET Y LOS SISTEMAS INFORMÁTICOS EN GENERAL, LOS QUE PUEDEN RESULTAR VULNERABLES, Y SIN PERJUICIO DE QUE LA EMPRESA HARÁ SUS MEJORES ESFUERZOS PARA QUE ELLO NO SUCEDA, LOS USUARIOS ENTIENDEN Y ACEPTAN QUE LA EMPRESA NO GARANTIZA QUE LA TECNOLOGÍA DE VINCOR ONLINE Y SUS SERVICIOS ASOCIADOSSE ENCUENTREN LIBRES DE VICIOS, ERRORES O DEFECTOS, NI TAMPOCO QUE LOS MISMOS SEA ÚTILES PARA UN RESULTADO O FIN ESPECÍFICO, SEAN SIEMPRE SEGUROS O ESTÉN LIBRES DE ERRORES, NI QUE FUNCIONEN SIEMPRE SIN INTERRUPCIONES, RETRASOS O IMPERFECCIONES, NO ASUMIENDO LA EMPRESA NINGÚN TIPO DE RESPONSABILIDAD EN ESTE SENTIDO Y UTILIZANDO EL USUARIO LA TECNOLOGÍA DE VINCOR ONLINE Y SUS SERVICIOS ASOCIADOS BAJO SU PROPIO Y EXCLUSIVO RIESGO. LA EMPRESA NO SERÁ RESPONSABLE POR CUALQUIER DAÑO O PERJUICIO DERIVADO DIRECTA O INDIRECTAMENTE DEL USO DE LA TECNOLOGÍA DE VINCOR ONLINE Y SUS SERVICIOS ASOCIADOS NI DEL CONTENIDO ALLÍ DISPONIBLE.</p>
            <p>La Empresa no garantiza en todo momento la disponibilidad de acceso y continuidad del funcionamiento de la Tecnología de Vincor Online y/o de sus Servicios asociados, por lo que no será responsable, bajo ninguna circunstancia, de los daños y perjuicios causados al Usuario como consecuencia de la indisponibilidad, fallos de acceso y/o falta de continuidad de los mismos.</p>
            <p>La descarga, acceso y uso de la Tecnología de Vincor Online y sus Servicios asociados en los dispositivos de escritorio o en los dispositivos móviles o similares, no implica la obligación por parte de la Empresa de controlar la ausencia de virus o cualquier otro elemento informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas informáticos dañinos. </p>
            <p>La Empresa no será responsable si el Usuario no cuenta con una computadora, un teléfono celular inteligente o una tablet que sea compatible con el uso de la Tecnología de Vincor Online y sus Servicios asociados.</p>
            <p>La Empresa se reserva el derecho a revisar, modificar, mejorar o interrumpir la Tecnología de Vincor Online y sus Servicios asociados tales como sus funcionalidades u operatoria cuando lo considere oportuno y sin previo aviso a los Usuarios. Por lo tanto, el acceso a la Plataforma, a las Salas Virtuales y/o sus funcionalidades y/o al Sitio y/o a la Aplicación Móvil, entre otros, podrá verse suspendido momentáneamente y sin previo aviso en el caso de un fallo del sistema, operaciones de mantenimiento, revisión o reparación o por otros motivos. La Empresa no será responsable por cualquier perjuicio que la suspensión o interrupción de la Plataforma pudiera ocasionar al Usuario.</p>
            <p>La Empresa no estará obligada a proporcionar servicios de mantenimiento o de asistencia técnica a los Usuarios.</p>
            <p>El Usuario entiende y acepta que la Empresa podrá dejar de proporcionar la Tecnología y/o sus Servicios asociados en cualquier momento y sin necesidad de preaviso alguno. Sin embargo, y sin por ello asumir responsabilidad alguna, se realizan los mejores esfuerzos para mantener siempre informados a los Usuarios de cualquier cambio o modificación de la Tecnología o de los Servicios que pudiera afectarlos de cualquier forma.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">9. Privacidad y Confidencialidad</h2>
            <p>El uso por parte de los Usuarios de la Tecnología de Vincor Online y sus Servicios asociados está sujeto al presente documento y asimismo a los Términos y Condiciones del sitio de Vincor Online y las Política de Privacidad que forma parte de estos Términos y Condiciones y a la que se puede acceder a través del siguiente link: [www.vincoronline.com.ar]</p>
            <p>Las comunicaciones entabladas a través de la Plataforma están protegidas y sujetas al deber de secreto y confidencialidad, la Empresa no monitorea, graba, ni accede de ninguna forma a las mismas, y son los Usuarios exclusivamente responsables por el contenido transmitido a través de la Plataforma, sus Salas Virtuales y sus Servicios asociados. En consecuencia, los Usuarios deberán procurar salir de cada sesión según el mecanismo dispuesto por la Plataforma y notificar inmediatamente a la Empresa vía mail a legales@vincoronline.com en caso de accesos no autorizados a las comunicaciones en la Plataforma, violación a las Credenciales y/o cualquier incidente de seguridad.</p>
            <p>Se recuerda a los Usuarios que la confidencialidad y el secreto de las comunicaciones están protegidas por normas de orden público y que la violación de las comunicaciones, revelación parcial o total del contenido de las mismas sin la autorización de los intervinientes de la comunicación puede ser susceptible de configurar un delito penal.</p>
            <p>Sin perjuicio de lo hasta aquí expuesto, los Usuarios entienden que la configuración de los tableros que hayan dispuesto en la Sala podrá ser guardada en la Plataforma por el Usuario Anfitrión a su entera discreción.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">10. Propiedad Intelectual</h2>
            <p>La Tecnología de Vincor Online y sus Servicios asociados, las marcas y logotipos y todos los derechos de propiedad intelectual relacionados a los mismos, incluyendo, pero sin limitarse a, las invenciones, patentes y solicitudes de patentes, diseños, modelos, marcas, nombres comerciales, logotipos, material de derecho de autor, gráficos, textos, imágenes, diseños (incluyendo el "look and feel" de los Servicios), especificaciones, métodos, procedimientos, algoritmos, datos, datos técnicos, características interactivas, fuente y código objeto, archivos, interfaz, interfaz gráfica de usuario, secretos comerciales; todos ellos estén o no registrados; son propiedad y/o licencia de las empresas vinculadas a la Empresa y están sujetas a derechos de autor y otros derechos de propiedad intelectual en virtud de las leyes y los convenios internacionales aplicables.</p>
            <p>A menos que esté expresamente permitido por la Empresa, se encuentra terminantemente prohibido copiar, distribuir, mostrar, ejecutar públicamente, poner a disposición del público, reducir a un formato legible para las personas, descompilar, desensamblar, adaptar, sublicenciar, hacer ningún uso comercial, vender, alquilar, prestar, realizar algún proceso de compilación, realizar ingeniería inversa, combinar con otro software, traducir, modificar o crear trabajos derivados de cualquier material que sea objeto de derechos de propiedad de la Empresa, o de sus empresas relacionadas, ya sea por sí mismo o por cualquier persona en nombre del Usuario, en cualquier forma o por cualquier medio.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">11. Contenido generado por Usuarios</h2>
            <p>Ni la Empresa, ni sus empresas relacionadas, participan ni intervienen en el contenido compartido por los Usuarios en el Sitio, en la Plataforma ni en las Salas Vrirtuales. En consecuencia, el Usuario reconoce, garantiza y acepta que el contenido compartido a través de éstos es de su propiedad y/o cuenta con las autorizaciones y/o licencias y/o permisos necesarios y se ajusta en un todo estos Términos y Condiciones y a la normativa vigente.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">12. Vínculos a sitios web fuera de la Plataforma</h2>
            <p>El Sitio, la Aplicación Móvil, la Plataforma, las Salas Virtuales y los Servicios asociados a éstos puede contener enlaces a sitios web de terceros. El Usuario reconoce que la Empresa, y las empresas relacionadas a ella, no tienen control sobre tales sitios de terceros, y en consecuencia, reconoce y acepta que ni la Empresa, ni las empresas ni entidades relacionadas a ella, son responsables de la disponibilidad de los sitios de terceros y no avala ni se hace responsable de cualquier servicio, contenido, anuncios, productos o materiales disponibles en dichos sitios de terceros. Además, el Usuario reconoce y acepta que ni la Empresa, ni las empresas ni entidades relacionadas a ella, serán responsables ni estarán obligadas, directa o indirectamente, por cualquier daño o pérdida causada o supuestamente causada por o en conexión con el uso de la Tecnología de Vincor Online y sus Servicios asociados, ni por contenidos, productos, servicios u otros materiales disponibles en y/o a través de sitios de terceros.</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">13. Modificaciones</h2>
            <p>Los Términos y Condiciones pueden ser revisados y actualizados por la Empresa frecuentemente. El uso de la Tecnología de Vincor Online y sus Servicios asociadosse encuentra sujeto, asimismo, a todos los avisos, instrucciones, políticas de privacidad, términos y condiciones complementarias presentes o que en el futuro pueda publicar la Empresa en su sitio web, las cuales serán comunicadas a los Usuarios. El Usuario puede revisar y descargar la versión más actualizada y vigente de los Términos y Condiciones en cualquier momento en el Sitio.</p>
            <p>La Empresa declara que la Tecnología de Vincor Online y sus Servicios asociados podrá ser administrada y operada en el futuro por una persona o entidad distinta de la actual, y que para dicha transferencia en la administración y operación de la Plataforma, no será necesaria notificación fehaciente ni consentimiento previo o posterior por parte del Usuario, quien lo acepta de conformidad</p>
          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">14. Contacto</h2>
            <p>Para preguntas sobre los Términos y Condiciones, los Usuarios podrán contactarse a través de correo electrónico a: [ legales@vincoronline.com ]</p>

          </section>

          <section className="text-justify">
            <h2 className="h5 pb-3">15. Jurisdicción y Ley Aplicable</h2>
            <p>Las cuestiones relativas a los Términos y Condiciones y al tratamiento de información de carácter personal, así como todas aquellas cuestiones que radican y tengan relación en parte o en su totalidad con los servicios suministrados a través de la aplicación, se rigen en todos y cada uno de sus extremos por la ley argentina, sometiéndose a los Juzgados y Tribunales ordinarios con asiento en la Ciudad de Buenos Aires.</p>
          </section>

        </CardBody>
      </Card>
    </WidthLimit>
  )
}

export default TyC;