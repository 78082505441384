import React from 'react'

const NewPlanCard = ({plan, type}) =>{
  const {name, description, price, currency}= plan
  return(
    <div className="main_pricing_conatiner p-3 wow fadeInLeftBig" data-wow-delay="0.3">
      <div className="price">
        <h2>
          <span className="price_icon">
            <i className="icon-wallet"></i>
          </span>
          {name}
        </h2>
        <span className="h3 text-black">{price} ${currency}</span>
      </div>
      <div className="py-2 card-description">
        <p>{description}</p>
      </div>
      <div className="text-center font-weight-bbold">
      </div>
      <div className="type-of-plan">
        <span className="btn btn-flat btn-fixed-w btn-success">
          {type}
        </span>
      </div>
    </div>
  )
}

export default NewPlanCard;