import React from 'react'

const InputModal = (props) => {
  const { title, titleStyle, className, required, disabled, validation = false, validationMessage, type, autoComplete, setRefInputs, multiple, id, value, handleChange, placeholder, children, ...rest } = props
  return (
    <label htmlFor={id}>
      <span className={titleStyle}>
        {
          (typeof title === "string") && title
        }
        {
          (typeof title === "function") && title()
        }
      </span>
      <input
        type={type}
        ref={setRefInputs}
        placeholder={placeholder}
        onChange={handleChange}
        autoComplete={autoComplete}
        value={value}
        multiple={multiple}
        className={className}
        name={id}
        id={id}
        disabled={disabled}
        required={required}
        {...rest}
      />
      {
        validation && (
          <span className="warning-string-limit">
            {validationMessage}
          </span>
        )
      }
      {children}
    </label>
  )
}

export default InputModal;