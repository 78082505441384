import { REGISTER_GUEST, REGISTER_GUEST_SUCCESSFUL, REGISTER_GUEST_FAILED } from './actionTypes';
import { EMPTY_ERROR,CONFIRM_MAIL,CONFIRM_SUCCESS,CONFIRM_ERROR, REGISTER_RESET } from './actionTypes';

export const registerGuest = () => {
    return {
        type: REGISTER_GUEST
    }
}

export const registerSuccessful = () => {
    return {
        type: REGISTER_GUEST_SUCCESSFUL
    }
}

export const registerFailed = () => {
    return {
        type: REGISTER_GUEST_FAILED,
    }
}
export const registerReset = () => {
    return {
        type: REGISTER_RESET,
    }
}

export const emptyError = () => {
    return {
        type: EMPTY_ERROR
    }
}

export const confirmMail = (token,userId) => {
    return {
        type: CONFIRM_MAIL,
        payload: { userId,token }
    }
}

export const confirmSuccess = (user) => {
    return {
        type: CONFIRM_SUCCESS,
        payload: { user }
    }
}

export const confirmError = (error) => {
    return {
        type: CONFIRM_ERROR,
        payload: error
    }
}
