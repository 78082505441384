import React from 'react'
import { Col, Card, CardBody } from 'reactstrap';
import StatisticsByUserItem from './statisticsByUserItem'

const StatisticsByUser = ({ title, guests_by_user }) => (
  <Col lg="6" className="stretched_card mt-4">
    <Card>
      <h5 className="card-header">{title}</h5>
      <CardBody className="p-0">
        <ul className="social-sales list-group list-group-flush">
          {
            guests_by_user.map(({ user: { attributes }, data }, index) => (
              <StatisticsByUserItem
                key={index + 1}
                user={attributes}
                guests={data.length}
              />
            ))
          }
        </ul>
      </CardBody>
    </Card>
  </Col>
)

export default StatisticsByUser;