import React from 'react'

const InputSelect = (props) => {

  const { title, titleStyle, className, type, autoComplete, setRefInputs } = props
  const { multiple, id, defaultValue, handleChange, placeholder, required = true, children, options, ...rest } = props
  
  console.log(id, options)
  return (
    <label htmlFor={id}>
      <span className={titleStyle}>{title}</span>
      <select
        type={type}
        ref={setRefInputs}
        placeholder={placeholder}
        onChange={handleChange}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        multiple={multiple}
        className={className}
        name={id}
        id={id}
        {...rest}
        required={required}
      >
        {options.map(({ value, label }, i) =>
          <option key={i} value={value}>{label}</option>
        )}
      </select>
    </label>
  )
}

export default InputSelect;