import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <>
                <footer>
                    <div className="footer-area">
                        <p>&copy; Copyright {new Date().getFullYear()}. Todos los derechos reservados.</p>
                    </div>
                </footer>
            </>
        );
    }
}
export default Footer;






