import { SET_WORKSHOPS_AT_STORE } from './actionTypes';
import { SET_UTC_WORKSHOPS } from './actionTypes';
import { SET_WORKSHOPGUEST_AT_STORE } from './actionTypes';

import { getUserWorkshops, getAllWorkshopsGuests } from '../../helpers/getDashboardData'
import { apiError } from '../actions'
import { sortByEarliestDate } from '../../helpers/utils';

export const setWorkshopsAtStore = workshops => {
  return {
    type: SET_WORKSHOPS_AT_STORE,
    payload: workshops
  }
}

export const set_UTC_Workshops = workshops => {
  return {
    type: SET_UTC_WORKSHOPS,
    payload: workshops
  }
}

export const set_WorkshopGuests_AtStore = (workshopsGuests) =>{
  return {
    type: SET_WORKSHOPGUEST_AT_STORE,
    payload: workshopsGuests
  }
}

export const updateWorkshops = (user) =>{
  return async dispatch =>{
    try {
      
      let userWorkshops = await getUserWorkshops(user);
      userWorkshops = sortByEarliestDate(userWorkshops)
      dispatch(setWorkshopsAtStore(userWorkshops));
      
      const workshopsGuests = await getAllWorkshopsGuests(userWorkshops)
      dispatch(set_WorkshopGuests_AtStore(workshopsGuests))
      
    } catch (error) {
      dispatch(apiError(error))
    }
  }
}