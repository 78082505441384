import { ICalendar, GoogleCalendar, OutlookCalendar, YahooCalendar } from 'datebook'
import { getDateFrom } from '../../../utils/parseDate/getValues'

const getCalendarLinks = (calendarInfo) => {
  const iCalendar = new ICalendar(calendarInfo)

  const googleCalendar = new GoogleCalendar(iCalendar)
  const yahooCalendar = new YahooCalendar(iCalendar)
  const outlookCalendar = new OutlookCalendar(iCalendar)

  return {
    calendar_link_outlook: outlookCalendar.render(),
    calendar_link_gmail: googleCalendar.render(),
    calendar_link_yahoo: yahooCalendar.render(),
  }
}

const getCalendarInfo = ({ name, date, start, end }, organizer, sign_in_code) => {
  return {
    title: name,
    description: `Invitación a taller "${name}".\n 
                  Su código de ingreso al tablero es el siguiente: ${sign_in_code}`,
    start: getDateFrom(date, start),
    end: getDateFrom(date, end),
    attendees: [organizer]
  }
}

export const getCalendarWorkshop = (calendarDetails) => {

  let { guests } = calendarDetails
  const { workshop, organizer } = calendarDetails
  const { sign_in_code, isForOrganizer } = calendarDetails

  guests = Object.values(guests).flat();
  
  const attendees = guests.map(guest => {
    const { name, email } = guest.attributes;
    return { name, email }
  })

  let calendarInfo = getCalendarInfo(workshop, organizer, sign_in_code);

  //Si el email es para el organizador entonces se le muestran todos invitados
  if (isForOrganizer) {
    attendees.unshift(organizer)
    calendarInfo = { ...calendarInfo, attendees }
  }

  const calendarLinks = getCalendarLinks(calendarInfo);

  return calendarLinks;
}