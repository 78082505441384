import React, { useState } from 'react'
import { connect } from 'react-redux';
import { renewPaypalPlan } from '../../api/paypal';
import { updateUser } from '../../helpers/sendData';
import { logError } from '../../widgets/utils/error';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { getNewDueDate } from './utils/getNewDueDate';
import { currencyType } from '../Plans/utils/currencyType';
import Button from '../../widgets/button';
import SweetAlert from 'react-bootstrap-sweetalert';

const RenewalButton = ({ user }) => {
  
  const { dolar, pesoArgentino } = currencyType
  const [visibleAlert, setVisibleAlert] = useState(false)

  const handleConfirm = async () => {
   
    const { currency, price } = plan.attributes
    const { due_date, updated_at, created_at, plan_id, plan, ...rest } = user.attributes

    const attributes = {
      ...rest,
      is_active: true,
      previous_plan_id: plan_id,
      previous_due_date: due_date,
      due_date: getNewDueDate(plan.attributes.scope_days),
    }

    let payment_page = "";
    const body = objectToSend('users', attributes)

    try {
      setVisibleAlert(false)
      
      if (currency === dolar.code) {
        const payment_url = await renewPaypalPlan(plan, body, price)
        payment_page = payment_url;

      } else if (currency === pesoArgentino.code) {

        await updateUser(user.id, body)
        alert('¡Felicidades, acabas de renovar tu plan, a continuación se cerrará la sesión y se te enviará a mercadopago.')

        //The plan selection panel gets closed
        localStorage.clear()
        payment_page = plan.attributes.payment_renewal_url;
      }

      window.location = payment_page;

    } catch (error) {
      logError(error)
    }
  }
  
  const handleRenewPlan = () => {
    const { is_renewable } = user.attributes.plan.attributes
    
    if(is_renewable){
      setVisibleAlert(true)
    }else{
      alert("Este plan no es renovable, debes adquirir un plan de pago")      
    }
  }

  return (
    <>
      <div className="RenewalButton">
        <Button handleClick={handleRenewPlan} color="success">
          Renovar plan actual
        </Button>
      </div>
      
      {
        visibleAlert && (
          <SweetAlert
            title="¿Estas seguro de que quieres renovar tu plan?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleConfirm}
            onCancel={() => setVisibleAlert(false)}
          >
            No se puede revertir!
          </SweetAlert>
        )
      }
    </>
  )
}

export default RenewalButton;
