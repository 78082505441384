import {getGuestByEmail} from "../../../helpers/getDashboardData"
import {createGuest, inviteGuest} from "../../../helpers/sendData"
import {objectToSend, objectToSendInvitations} from "../../../widgets/utils/objectToSend"

export const getGuestsAccessCode = async (workshopID, emails) =>{
  console.log(emails)
  
  const guests = {
    created: [],
    registered: [],
    notFullRegistered: [],
    notRegistered: [],
  }
  
  const codes = {
    created: [],
    registered: [],
    notFullRegistered: [],
  }
  
  //Se tienen los guests, los codes y una determinada key...
  //A cada key se le asigna a cada unos de sus guests su respetivo code
  const asignCode = (guests, codes, key) =>{
    // debugger
    const newArray = guests[key].map((guest, index) =>{
      guest.attributes.sign_in_code = codes[key][index]
      return guest;
    })
    
    return newArray;
  }
  
  //Verificando si los correos ya están registrados como invitados
  for (const guestEmail of emails) {
    try {
      const guest = await getGuestByEmail(guestEmail)
      
      if(guest.attributes.tc_accepted_at){
        guests.registered.push(guest)
        
      }else{
        guests.notFullRegistered.push(guest)
      }
      
    } catch (error) {
      guests.notRegistered.push(guestEmail)
    }
  }
  
  //Creando invitados no registrados
  for (const email of guests.notRegistered) {
    try {
      const body = objectToSend('guests', {email})
      console.log('Creando invitados no registrados', body)
      const guest = await createGuest(body)
      guests.created.push(guest)
      // console.log('Invitado creado')
    } catch (error) {
      console.log(error)
      console.log(error.status)
      console.log(error.message)
    }
  }
  
  console.log('Registered', guests.registered)
  console.log('Created', guests.created)
  
  //Invitando a los que se acaban de crear automaticamente, no se les enviará aún el código
  for (const guest of guests.created) {
    try {
      const body = objectToSendInvitations(guest.id)
      console.log('Invitando a los que se acaban de registar automaticamente', body)
      const sign_in_code = await inviteGuest(workshopID, body)
      codes.created.push(sign_in_code)
      
    } catch (error) {
      console.log(error)
      console.log(error.status)
      console.log(error.message)
    }
  }
  
  //Invitando a los que ya estaban registrados
  for (const guest of guests.registered) {
    try {
      const body = objectToSendInvitations(guest.id)
      console.log('Invitando a los que ya estaban registrados', body)
      const sign_in_code = await inviteGuest(workshopID, body)
      codes.registered.push(sign_in_code)
      
    } catch (error) {
      console.log(error)
      console.log(error.status)
      console.log(error.message)
    }
  }
  
  //Invitando a los que no están completamente registrados, no se les enviará aún el código
  for (const guest of guests.notFullRegistered) {
    try {
      const body = objectToSendInvitations(guest.id)
      console.log('Invitando a los que no se han terminado de registrar', body)
      const sign_in_code = await inviteGuest(workshopID, body)
      codes.notFullRegistered.push(sign_in_code)
      
    } catch (error) {
      console.log(error)
      console.log(error.status)
      console.log(error.message)
    }
  }
  
  delete guests.notRegistered;
  
  //Se obtienen las keys de los guests y se ejecuta la funcion por cada una
  Object.keys(guests).forEach( key =>{
    // debugger
    guests[key] = asignCode(guests, codes, key)
  })

  return guests;
}