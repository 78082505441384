import React, { useRef, useState } from 'react'
import { getBillings } from '../../api/afip'
import { cuteDateUS } from '../../utils/parseDate/formatDisplay'
import Button from '../../widgets/button'
import InputDateModal from '../../widgets/inputDateModal'
import InputSelect from '../../widgets/inputSelect'
// import html2pdf from "html2pdf.js"

const BillingSearch = ({ availableOptions, loading, setVouchers, setLoading }) => {

  const $form = useRef(null)

  const initialDateSearch = {
    dateFrom: "",
    dateTo: "",
  }

  const [searchDates, setSearchDates] = useState(initialDateSearch)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const form = new FormData($form.current)

      const ptoVenta = form.get("ptoVenta")
      const tipoComprobante = form.get("tipoComprobante")

      const dateFrom = cuteDateUS(searchDates.dateFrom)
      const dateTo = cuteDateUS(searchDates.dateTo)
      
      debugger
      const dates = { dateFrom, dateTo }
      const billingFeatures = { ptoVenta, tipoComprobante }

      if (
        dateFrom === "" || dateTo === "" ||
        dateFrom < dateTo &&
        dateFrom !== dateTo
      ) {

        const vouchers = await getBillings({ all: true, ...dates, ...billingFeatures })
        console.log("vouchers:", vouchers)

        setVouchers(vouchers)

      } else {
        alert(
          '- Las "Fecha desde..." no puede ser mayor a la "Fecha hasta..."\n' +
          '- Las "Fecha desde..." y "Fecha hasta..." no pueden ser iguales'
        )
        setSearchDates(initialDateSearch)
      }

      setLoading(false)

    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleChange = ({ target }) => {
    const { name, value } = target

    setSearchDates({
      ...searchDates,
      [name]: value
    })
  }

  const setSelectFormatValues = (items) => (
    items.map(({ id, label }) => ({ label, value: id }))
  )

  const { dateFrom, dateTo } = searchDates
  const { salesPoints, voucherTypes } = availableOptions

  return (
    <form ref={$form} onSubmit={handleSubmit} className="BillingSearch pb-3 pt-4">

      <InputDateModal
        type="date"
        id="dateFrom"
        title="Fecha desde..."
        className="form-control"
        defaultValue={dateFrom}
        handleChange={handleChange}
        required={false}
      />

      <InputDateModal
        type="date"
        id="dateTo"
        title="Fecha hasta..."
        className="form-control"
        defaultValue={dateTo}
        handleChange={handleChange}
        required={false}
      />

      <InputSelect
        id="tipoComprobante"
        title="Tipo de Comprobante"
        className="form-control w-100"
        options={setSelectFormatValues(voucherTypes)}
      />

      <InputSelect
        id="ptoVenta"
        title="Tipo de punto de venta"
        className="form-control"
        options={setSelectFormatValues(salesPoints)}
      />

      <Button
        text="Buscar"
        type="submit"
        loadingLabel="Buscando..."
        color="success rounded-pill"
        className="flex-fill"
        loading={loading}
      />

    </form>
  )
}

export default BillingSearch