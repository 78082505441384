import React from 'react'
import Checkbox from './checkbox'

const OptionalFieldInput = ({ checkbox, condition, optionalInput }) => {
  return (
    <>
      {
        condition && optionalInput()
      }
      <Checkbox {...checkbox}/>
    </>
  )
}

export default OptionalFieldInput