import React from 'react'

const PlansCard = ({ handleSelectPlan, plan }) => {

  const { name, currency, price, description } = plan.attributes

  const descriptionList = description.split('-').filter(item => item !== '')

  return (
    <div className="main_pricing_conatiner p-3 wow fadeInLeftBig" data-wow-delay="0.3">

      <div className="price">
        <h2>
          <span className="price_icon">
            <i className="icon-wallet"></i>
          </span>
          {name}
        </h2>
        <span className="h3 text-black">{price} ${currency}</span>
      </div>

      <div className="py-2 card-description">
        <ul>
          {
            descriptionList.map((item, index) => (
              <li className="pb-1" key={index}>- {item}</li>
            ))
          }
        </ul>
      </div>

      <div className="choose_plan_btn">
        <button
          onClick={() => handleSelectPlan(plan)}
          className="btn btn-flat btn-fixed-w btn-success"
        >
          Lo quiero!
        </button>
      </div>

    </div>
  )
}

export default PlansCard;