import React from 'react'
import { connect } from 'react-redux';

const UserColumnsTable = ({ columns, is_admin }) => {
  return (
    <thead className="thead-dark">
      <tr>
        {
          columns.map((itemField, index) => <th key={index + 1} className="sorting">{itemField.label}</th>)
        }
        <th colSpan={is_admin ? "2" : "1"}>
          Actions
        </th>
      </tr>
    </thead>
  )
}

const mapStateToProps = ({ Login: { user: { attributes: { is_admin } } } }) => ({
  is_admin
})

export default connect(mapStateToProps)(UserColumnsTable);