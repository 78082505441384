import axios from "axios"
import { API_PAYPAL } from "./apiURLs"

const API = {
  auth: `${API_PAYPAL}/authentication`,
  createPlan: `${API_PAYPAL}/createPlan`,
  executePay: `${API_PAYPAL}/executePay`,
}

const { REACT_APP_ADMIN_URL, REACT_APP_MODE } = process.env

export const approvePaypalUrl = "/paypal-payment-capture"

const findUrl = ({ links }) => {
  debugger
  return links.find(({ rel, method }) => rel === "approve" && method === "GET")
}

export const saveAccess_token = (access_token) => {
  localStorage.setItem('access_token', JSON.stringify(access_token))
}
export const getAccess_token = () => {
  return JSON.parse(localStorage.getItem("access_token"))
}

export const paypalAuthentication = async () => {
  const { data: { body } } = await axios.post(API.auth)
  return body.access_token;
}
export const paypalCreatePlan = async (access_token, monto) => {

  const is_development = REACT_APP_MODE.toLowerCase() !== "development";
  const localhost = "http://localhost:3000";

  const { data: { body } } = await axios.post(API.createPlan, {
    access_token,
    monto,
    // url_aprobada: `${"http://localhost:3000"}${approvePaypalUrl}`,
    url_aprobada: `${is_development ? REACT_APP_ADMIN_URL : localhost}${approvePaypalUrl}`,
    url_cancelada: "https://developer.mozilla.org/es/docs/Learn/JavaScript/Objects/JSON"
  })

  debugger
  const paypal_payment = findUrl(body)
  return paypal_payment.href;
}
export const paypalExecutePay = async (token, access_token) => {
  const { data: { body } } = await axios.post(`${API.executePay}?token=${token}`, { access_token })
  console.log(body)
  return body.purchase_units[0].payments.captures[0].amount;
}

export const renewPaypalPlan = async (newPlan, body, price) => {
  alert('A continuación se te enviará a concretar el pago.')

  const access_token = await paypalAuthentication()
  const payment_url = await paypalCreatePlan(access_token, price)

  saveAccess_token({
    body,
    newPlan,
    due_date: body.data.attributes.due_date,
    access_token,
  })
  
  return payment_url;
}