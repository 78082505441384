import React, {Component} from 'react'
import RegisterLayout from '../../layout/registerLayout';
import RegisterPaymentForm from './registerPaymentForm';
import VerifyUser from './verifyUser';

class PaymentIdRegister extends Component{
  
  state={
    user: {},
    userVerification: false
  }
  
  setVerification = (user) =>{
    this.setState({
      user,
      userVerification: true
    })
  }
  
  render(){
    return(
      <RegisterLayout>
        {
          !this.state.userVerification ?
            <VerifyUser
              setVerification={this.setVerification}
            />
          :
            <RegisterPaymentForm
              user={this.state.user}
            />
        }
        
      </RegisterLayout>
    )
  }
}

export default PaymentIdRegister;