import React from 'react';
import { cuteDate, cuteTime } from '../../utils/parseDate/formatDisplay';

const WorkshopItemInfo = ({ workshop }) => {
  
  const { start, end, has_expiration } = workshop;
  
  return (
    <p className="workshopItemInfo">
      <span className="m-0">
        {cuteDate(start)}
      </span>
      <span className="m-0">
        {cuteTime(start)} {has_expiration ? `- ${cuteTime(end)}` : ""}
      </span>
    </p>
  )
}

export default WorkshopItemInfo;