import React from 'react'

const Checkbox = ({title, className, checked, setRefInputs, id, containerClass, defaultValue, handleChange, onInvalid, required}) =>(
  <div className={containerClass}>
    <label className={containerClass} htmlFor={id}>
      <input 
        type="checkbox"
        checked={checked}
        ref={setRefInputs}
        onChange={handleChange}
        defaultValue={defaultValue}
        className={className}
        name={id}
        id={id}
        onInvalid={onInvalid}
        required={required}
      />
      <span className="pl-2" >
        {title}
      </span>
    </label>
  </div>
)
export default Checkbox;