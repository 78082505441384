export const savePayment_id = (payment_id) =>{
  localStorage.setItem('payment_id', payment_id)
}

export const verifyPayment_id= (payment_id) =>{
  return localStorage.getItem('payment_id') !== payment_id
}

export const getUser = () =>{
  return JSON.parse(localStorage.getItem('vincor_webs_new_user'))
}