import React from 'react'
import Button from '../../widgets/button';

const SaveButton = ({handleClick, loading, className}) => {
  return (
    <Button 
      type="submit"
      handleClick={(handleClick)}
      color="success"
      className={className}
      loading={loading} 
      text="Guardar"
    />
  )
}

export default SaveButton;
