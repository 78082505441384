import React from "react"

export const currencyType = {
  dolar: {
    name: "Dólar Estadounidense",
    code: "USD"
  },
  pesoArgentino: {
    name: "Peso Argentino",
    code: "ARS"
  }
}

export const paintCurrencyTypes = () =>{
  return Object.values(currencyType).map(({ name, code }, i) =>
    <option
      key={`${code}-${i}`}
      value={code}
    >
      {name} ({code})
    </option>
  )
}
