import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import {createPortal} from 'react-dom';

const alert = document.getElementById('alert')

const WorkshopAlert = ({handleDelete}) =>{
  return createPortal(
    <SweetAlert
      title="¿Este seguro de que quieres eliminar este taller?"
      warning
      showCancel
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={handleDelete.confirm}
      onCancel={handleDelete.cancel}
    >
      ¡Esta acción no se puede revertir!
    </SweetAlert>
  , alert)
}

export default WorkshopAlert;