import React from 'react'
import UserColumnsTable from './userColumnsTable'
import UserRowTable from './userRowTable'
import UserBodyTable from './userBodyTable'

const DataTable = ({ users, type, search, searchData, usersRenderStart, usersRenderEnd, handleEdit, handleDesactivate }) => {
  
  console.log(users.rows)
  
  return (
    <table className="table">
      <UserColumnsTable
        columns={users.columns}
      />

      {
        //Si hay busqueda
        search &&
        <UserBodyTable>
          {
            searchData.map((user, index) => (
              <UserRowTable
                user={user}
                type={type}
                columns={users.columns}
                handleEdit={handleEdit}
                handleDesactivate={handleDesactivate}
                key={index + 1}
              />
            ))
          }
        </UserBodyTable>
      }

      {
        //Si no hay ninguna busqueda
        !search &&
        <UserBodyTable>
          {
            users.rows
              .slice(usersRenderStart, usersRenderEnd)
              .map((user, index) =>
                <UserRowTable
                  user={user}
                  type={type}
                  columns={users.columns}
                  handleEdit={handleEdit}
                  handleDesactivate={handleDesactivate}
                  key={index + 1}
                />
              )
          }
        </UserBodyTable>
      }
    </table>
  )
}

export default DataTable;