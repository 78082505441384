import React from 'react';
import Redirection from '../Router/redirection'
import { Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../layout'
import { Component } from 'react';
import Table from './table';
import { setLoading, updateUsers, updateUsersState, userSearch } from '../../store/actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import UserModal from './userModal';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { updateUser } from '../../helpers/sendData';
import Alert from 'reactstrap/lib/Alert';
import Button from '../../widgets/button';
import { getStartOfDay } from '../../utils/parseDate/getValues';
class Users extends Component {

  state = {
    user: null,
    visibleModal: false,
    visibleAlert: false,
    confirmMsg: '¿Estás seguro de que quieres activar a este usuario?',
    messages: {
      successUpdate: 'El usuario se ha actualizado con éxito',
      errorUpdate: 'Ha ocurrido un error con la actualización del usuario',
    },
    notification: {
      state: false,
      color: '',
      message: '',
    }
  }

  setNotification = (state, message, color) => {
    this.setState({
      notification: { state, color, message }
    })
  }

  resetNotification = () => {
    this.setNotification(false, '', '')
  }

  handleUpdateUsers = async () => {
    const { dispatch } = this.props;

    dispatch(setLoading(true))

    try {
      await dispatch(updateUsers())
      alert('Se han actualizado los consteladores')
      dispatch(setLoading(false))

    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch(setLoading(false))
    }
  }

  handleUser = {
    edit: (user) => {
      this.setState({ user, visibleModal: true })
      this.resetNotification()
    },
    desactivate: (user) => {
      debugger
      this.setState({
        user,
        visibleAlert: true,
        confirmMsg: `¿Estás seguro de que quieres ${!user.attributes.is_active ? 'activar' : 'desactivar'}  a este usuario?`
      })

      this.resetNotification()
    }
  }

  handleAlert = {
    confirm: async () => {

      const { user } = this.state;
      const { users, dispatch } = this.props;

      dispatch(setLoading(true))

      const { created_at, updated_at, payment_id, ...rest } = user.attributes;

      const attributes = {
        ...rest,
        is_active: !user.attributes.is_active,
        birth_date: getStartOfDay(rest.birth_date),
        due_date: getStartOfDay(rest.due_date),
      }

      const body = objectToSend('user', attributes)

      //debugger

      try {
        await updateUser(user.id, body)
        await dispatch(updateUsersState(users, user, 'IS_ACTIVE'))

        this.setNotification(true, `El usuario se ha ${user.attributes.is_active ? 'activado' : 'desactivado'} correctamente`, 'success')
        dispatch(setLoading(false))

      } catch (error) {
        console.log(error)
        this.setNotification(true, `Ha ocurrido un error con la ${!user.attributes.is_active ? 'activación' : 'desactivación'} del usuario`, 'danger')
        dispatch(setLoading(false))
      }

      this.handleAlert.close()
    },
    close: () => {
      this.setState({ visibleAlert: false })
    },
  }

  render() {
    const { loading } = this.props;
    const { users, user_search } = this.props;
    const { user, notification, messages } = this.state;
    const { visibleAlert, visibleModal, confirmMsg } = this.state;

    const { handleAlert, handleUser, handleUpdateUsers, setNotification, resetNotification } = this

    console.log(users)

    return (
      <Redirection>
        <Layout>
          {
            visibleAlert &&
            <SweetAlert
              title={confirmMsg}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handleAlert.confirm}
              onCancel={handleAlert.close}
            >
              Puede desahacerse en cualquier momento
            </SweetAlert>
          }

          {
            visibleModal &&
            <UserModal
              user={user}
              messages={messages}
              setNotification={setNotification}
              resetNotification={resetNotification}
              handleCloseModal={() => this.setState({ visibleModal: false })}
            />
          }

          <div className="users-container">
            <Card>
              <CardBody>

                <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                  <h4 className="card_title pb-3 m-0">Consteladores</h4>
                  <Button
                    type="submit"
                    handleClick={handleUpdateUsers}
                    color="success mb-3"
                    loading={loading}
                    text="Actualizar"
                  />
                </div>

                <Table
                  start={0}
                  end={20}
                  type="users"
                  action={userSearch}
                  users={users}
                  searchData={user_search}
                  handleEdit={handleUser.edit}
                  handleDesactivate={handleUser.desactivate}
                />

                {
                  notification.state &&
                  <Alert
                    onClick={resetNotification}
                    className="mt-4"
                    color={notification.color}
                  >
                    {notification.message}
                  </Alert>
                }

              </CardBody>
            </Card>
          </div>
        </Layout>
      </Redirection>
    )
  }
}

const mapStateToProps = ({ Login, Users }) => {

  const { loading, total_users, user } = Login
  const { user_search } = Users

  return {
    loading,
    users: total_users,
    user_search,
    userID: user.id,
    user: user.attributes.is_admin
  }
}
export default withRouter(connect(mapStateToProps)(Users));