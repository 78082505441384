import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Redirection from '../Router/redirection'
import Layout from '../../layout'

import StatisticsByUser from './statisticsByUser'
import StatisticsBoard from './statisticsBoard'
import StatisticsCard from './statisticsCard'

// import OtherMetrics from './otherMetrics'
import WidthLimit from '../../layout/widthLimit';
import { getGuestsByUsers, getTotalGuest, getUsers, getWorkshops, getWorkshopsByEveryUser } from '../../helpers/getDashboardData';

const DashboardV3 = () => {

  const [state, setState] = useState({
    users: [],
    guests: [],
    workshops: [],
    guestsByUsers: [],
    workshopsByEveryUser: []
  })

  useEffect(() => {
    (async () => {

      const users = await getUsers()
      const guests = await getTotalGuest()
      const workshops = await getWorkshops()

      const guestsByUsers = await getGuestsByUsers()
      const workshopsByEveryUser = await getWorkshopsByEveryUser();

      setState({
        users,
        guests,
        workshops,
        guestsByUsers,
        workshopsByEveryUser
      })
    })()
  }, [])

  const { users, guests, workshops, guestsByUsers, workshopsByEveryUser } = state

  return (
    <Redirection>
      <Layout>
        <WidthLimit>
          <div className="width-limit__container no-shadow">

            <StatisticsBoard>
              <StatisticsCard
                cardName={'Total Consteladores'}
                number={users.length}
              />
              <StatisticsCard
                cardName={'Total Invitados'}
                number={guests.length}
              />
              <StatisticsCard
                cardName={'Total Talleres'}
                number={workshops.length}
              />
            </StatisticsBoard>

            <Row>
              <StatisticsByUser
                title={'Invitados de cada constelador'}
                guests_by_user={guestsByUsers}
              />
              <StatisticsByUser
                title={'Talleres de cada constelador'}
                guests_by_user={workshopsByEveryUser}
              />
              {/* <OtherMetrics/> */}
            </Row>

          </div>
        </WidthLimit>
      </Layout>
    </Redirection>
  )
}

export default withRouter(DashboardV3);