import React, {Component} from 'react';
import RegisterLayout from '../../layout/registerLayout';
import {objectToSend} from '../../widgets/utils/objectToSend';
import {createGuest} from '../../helpers/sendData';
import GuestForm from './guestForm';
import {validateBirthDay} from './utils/validateForm';
import {handleError} from './utils/handleErrors';
import { getTodaysDate } from '../../utils/parseDate/getValues';
import { getStartOfDay } from '../../utils/parseDate/getValues';

class Register extends Component {

  state = {
    guest:{
      name: '',
      email: '',
      birth_date: '',
      tc_accepted_at: false
    },
    registerSuccess: false,
    registrationError: false,
    loading: false,
    errorMessage: '',
  }
  
  handleSubmit = async event => {
    event.preventDefault()
    this.setLoading(true)
    
  //debugger
    const attributes = {
      ...this.state.guest,
      birth_date: getStartOfDay(this.state.guest.birth_date),
      tc_accepted_at: getStartOfDay(getTodaysDate())
    }
    
    const body = objectToSend('guests', attributes)
    
    try {
      await createGuest(body)  
      this.setLoading(false, 'success')
      
    } catch (error) {
      console.log(error)
      handleError(error, this.setLoading)
    }
  }
  
  setLoading = (loading, state, errorMessage) =>{
    const defaultMessage = 'Ha habido un error con la creacion del invitado.'
    this.setState({
      registerSuccess: false,
      registrationError: false,
    })
    if(state === 'success'){
      this.setState({
        loading,
        registerSuccess: true
      })
    }else if(state === 'error'){
      this.setState({
        loading,
        registrationError: true,
        errorMessage: errorMessage || defaultMessage,
      })
    }else{
      this.setState({ loading })
    }
  }
  
  setGuestKey = (target) =>{
    if(target.name === 'Terminos-condiciones'){
      
      target.setCustomValidity(target.checked? '' : 'Aceptar Términos y Condiciones')
      
      this.setState({
        guest:{
          ...this.state.guest,
          tc_accepted_at: target.checked,
        }
      })
    }else{
      this.setState({
        guest:{
          ...this.state.guest,
          [target.name]: target.value
        }
      })
    }
  }
  
  handleChange = event => {
    const { target } = event
    validateBirthDay(target, this.setGuestKey);
  }
  
  handleInvalid = () => this.form.classList.add('was-validated')
  setRef = element => this.form = element
  setRefCheck = element => {
    this.check = element
    this.check.setCustomValidity('Aceptar Términos y Condiciones')
  }
  
  render() {
    return (
      <RegisterLayout>
        <GuestForm
          title='Registro de Invitados'
          type='creacion'
          guest={this.state.guest}
          setRef={this.setRef}
          setRefCheck={this.setRefCheck}
          handleSubmit={this.handleSubmit}
          handleInvalid={this.handleInvalid}
          handleChange={this.handleChange}
          registerSuccess={this.state.registerSuccess}
          registrationError={this.state.registrationError}
          errorMessage={this.state.errorMessage}
          loading={this.state.loading}
        />
      </RegisterLayout>
    );
  }
}


export default Register;

