import React, { useEffect, useState } from 'react'
import Layout from '../../layout';
import { Card, CardBody } from 'reactstrap';
import WidthLimit from '../../layout/widthLimit';
import Button from '../../widgets/button';
import BillItem from './BillItem';
import BillingSearch from './BillingSearch';
import { useHistory } from 'react-router-dom';
import ErrorBillingNotification from './ErrorBillingNotification';
import Redirection from '../Router/redirection';
import { getBillings, getDocumentTypes, getSalesPoints, getVoucherTypes } from '../../api/afip';
import PDFTemplate from './PDFTemplate';
import html2pdf from "html2pdf.js"
import LoadingMessage from '../Others/LoadingMessage';

const Billing = () => {

  const history = useHistory()

  const [isError, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [vouchers, setVouchers] = useState([])

  const [selectedVoucher, setSelectedVoucher] = useState({
    codigoTipoComprobante: 0,
    numeroPuntoVenta: "0",
    numeroComprobante: "0",
    fechaEmision: "2022-11-28T00:00:00.000Z",
    codigoTipoAutorizacion: "E",
    codigoAutorizacion: 0,
    fechaVencimiento: "2022-12-08T00:00:00.000Z",
    codigoTipoDocumento: 0,
    numeroDocumento: 0,
    importeGravado: "0",
    importeNoGravado: "0",
    importeExento: "0",
    importeSubtotal: "0",
    importeTotal: "0",
    codigoMoneda: "PES",
    cotizacionMoneda: 1,
    codigoConcepto: 2,
    fechaServicioDesde: "2022-11-03T00:00:00.000Z",
    fechaServicioHasta: "2022-11-28T00:00:00.000Z",
    fechaVencimientoPago: "2022-11-28T00:00:00.000Z",
    arrayItems: {
      item: [
        {
          unidadesMtx: 0,
          codigoMtx: "0123456779914",
          codigo: "P0001",
          descripcion: "Descripción del producto P000",
          cantidad: "0",
          codigoUnidadMedida: 0,
          precioUnitario: "0",
          codigoCondicionIVA: 0,
          importeItem: "0"
        }
      ]
    },
    arraySubtotalesIVA: {
      subtotalIVA: [
        {
          codigo: 0,
          importe: "0"
        }
      ]
    }
  })

  const titles = [
    "Nro. de Comprobante",
    "Fecha de Generación",
    "Receptor",
    "Monto",
    "Más detalles",
  ]

  const [availableOptions, setAvailableOptions] = useState({
    documentTypes: [
      {
        id: 0,
        label: "",
      },
    ],
    voucherTypes: [
      {
        id: 0,
        label: "",
        description: ""
      },
    ],
    salesPoints: [
      {
        id: 0,
        label: "",
      },
    ],
  })

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)

        const salesPoints = await getSalesPoints()
        console.log("Tipos de puntos de venta", salesPoints)

        const voucherTypes = await getVoucherTypes()
        console.log("Tipos de comprobantes", voucherTypes)

        const documentTypes = await getDocumentTypes()
        console.log("Tipos de documentos", documentTypes)

        setAvailableOptions({ salesPoints, voucherTypes, documentTypes })

        const vouchers = await getBillings({
          all: true,
          ptoVenta: salesPoints[0].id,
          tipoComprobante: voucherTypes[0].id,
        })

        setVouchers(vouchers)
        setLoading(false)

      } catch (error) {
        console.log(error)
        setError(true)
        setLoading(false)
      }
    })()
  }, [])

  const createPDF = () => {
    console.log("Se empieza a crear")
    html2pdf()
      .set({
        margin: 0.5,
        filename: "comprobante.pdf",
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          scale: 3,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait"
        }
      })
      .from(document.getElementById("voucher"))
      // .save()
      .output("dataurlnewwindow")
      .then(() => console.log("Guardado!!!"))
      .catch(error => console.log(error))
      .finally(() => console.log("Finally!!!"))
  }

  console.log("vouchers:", vouchers)

  return (
    <Redirection>
      <Layout>
        <WidthLimit>
          <Card className="width-limit__container">
            <CardBody className="plans">

              <h1 className="card_title">Facturaciones</h1>

              <section className="Billing">

                <div className="main-action">
                  <Button
                    text="Generar Factura"
                    color="primary py-2 rounded-pill"
                    className="flex-fill"
                    handleClick={() => history.push("/facturaciones/generar")}
                  />
                </div>

                {
                  isError ?
                    <ErrorBillingNotification message="Ha habido un error con la carga de los comprobantes y/o los filtros." />
                    :
                    <>
                      <BillingSearch
                        loading={loading}
                        availableOptions={availableOptions}
                        setLoading={setLoading}
                        setVouchers={setVouchers}
                      />

                      {
                        (vouchers.length > 0 && loading === false) &&
                        <ul className="BillList">

                          {/* Títulos de los atributos */}
                          {
                            titles.map((item, i) =>
                              <li key={i} className="title_item">{item}</li>
                            )
                          }

                          {/* Items */}
                          {
                            vouchers.map((voucher, i) => (
                              <BillItem
                                key={i}
                                voucher={voucher}
                                createPDF={createPDF}
                                setSelectedVoucher={setSelectedVoucher}
                              />
                            ))
                          }

                        </ul>
                      }

                      {/* Template de la factura, éste permanece oculto siempre */}
                      <PDFTemplate
                        id="voucher"
                        voucher={selectedVoucher}
                        {...availableOptions}
                      />

                      <LoadingMessage
                        loading={loading}
                        itemList={vouchers}
                        message="No hay comprobantes que concuerden con la busqueda"
                      />
                    </>
                }

              </section>



            </CardBody>
          </Card>
        </WidthLimit>
      </Layout >
    </Redirection>
  )
}

export default Billing;