import React from 'react'
import { Card, CardBody } from 'reactstrap';

const StatisticsCard = ({cardName, number}) => (
  <Card className="card-icon rt_icon_card text-center">
    <CardBody>
      <div className="icon_rt">
        <i className="feather ft-users"></i>
      </div>
      <div className="icon_specs">
        <p>{cardName}</p>
        <span>{number}</span>
      </div>
    </CardBody>
  </Card>
)

export default StatisticsCard;