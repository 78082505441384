export const getEmailsCount = (emails) =>{
  return Object.values(emails).flat().length;
}

export const displayNotification = (emailsCount, emailsSent) =>{
  if(emailsCount){
    if(emailsCount === emailsSent){
      alert('Se han enviado todos los correos')
      
    }else if(emailsSent !== 0){
      alert('Algunos correos no se enviaron')
      
    }else if(emailsSent === 0){
      alert('Hubo un error con el envio de los correos')
    }
  } 
}