import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { getAccess_token, paypalExecutePay } from '../../api/paypal'
import { logError } from '../../widgets/utils/error'
import { updateUser } from '../../helpers/sendData'

import logo from "../../assets/images/logo-nuevo.png"
import getUrlParameter from '../../helpers/getUrlParameter'
import { setUserSuccessful } from '../../store/actions'

const PaypalPaymentCapture = ({ user, dispatch }) => {

  const [redirectToHome, setRedirectToHome] = useState(false)

  const [payment, setPayment] = useState({
    currency_code: "",
    value: "00.00"
  })

  const [newPlanInfo, setNewPlanInfo] = useState({
    userInfo:{
      name: "",
      email: "",
    },
    newPlan:{
      attributes:{ name: "" }
    }
  })

  useEffect(() => {
    (async () => {
      const { access_token, ...info } = getAccess_token()
      const { body, newPlan, due_date } = info
      const token = getUrlParameter("token")
      
      try {
        debugger
        const payment = await paypalExecutePay(token, access_token)
        await updateUser(user.id, body)
        setPayment(payment)
        setNewPlanInfo({
          userInfo: { ...user.attributes },
          newPlan: {...newPlan.attributes}
        })
        
        user.attributes.due_date = due_date;
        user.attributes.plan = newPlan;
        
        dispatch(setUserSuccessful(user))

      } catch (error) {
        logError(error)
        setRedirectToHome(true)
      }
    })()
  }, [])

  const { userInfo, newPlan } = newPlanInfo

  return (
    <>
      {
        redirectToHome ?
          <Redirect to="/" />
          :
          <main className="paypalPayment pb-5 pt-3 pt-md-5 px-2 p-md-5">
            <div className="card border-primary text-white p-3">

              <h1 className="pb-3">Información de Pago</h1>
              <ul>
                <li>
                  <span>Nombre:</span> {userInfo.name}
                </li>
                <li>
                  <span>Correo:</span> {userInfo.email}
                </li>
                <li>
                  <span>Nombre del Plan:</span> {newPlan.name}
                </li>
                <li>
                  <span>Moneda:</span> {payment.currency_code}
                </li>
                <li>
                  <span>Cantidad:</span> $ {payment.value}
                </li>
              </ul>
              <div className="d-flex justify-content-end pt-3">
                <Link to="/agenda" className="btn bg-white font-weight-bold">Volver</Link>
              </div>
            </div>
            <div className="d-flex justify-content-end pt-3">
              <img src={logo} className="img-fluid pb-3" alt="vincor-logo" />
            </div>
          </main>
      }
    </>
  )
}

const mapStateToProps = ({ Login: { user } }) => ({ user })

export default connect(mapStateToProps)(PaypalPaymentCapture);
