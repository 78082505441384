import React from 'react';
import { connect } from 'react-redux';
import { validateIsDate } from '../../helpers/utils';
import { cuteDate } from '../../utils/parseDate/formatDisplay';
import Button from '../../widgets/button';
import Edit from '../../widgets/icons/edit';
import OptionButton from '../../widgets/optionButton';

const UserRowTable = ({ user, type, handleEdit, columns, handleDesactivate, is_admin }) => {
  
  const handleEditClick = () => {
    handleEdit(user)
  }
  
  const handleDesactivateClick = () => {
    handleDesactivate(user)
  }
  
  return (
    <tr>
      {
        columns.map((type, index) => {
          //Si es el atributo is_active
          const item = user.attributes[type.field]

          if (item === '' || item === undefined) {
           
            return (
              <td key={index + 1}>
                N/A
              </td>
            )
            
          } else if (item === true || item === false) {
            
            const is_active = item
            return (
              <td key={index + 1}>
                <input type="checkbox" readOnly checked={is_active} />
              </td>
            )
            
            //Si es una fecha
          } else if (validateIsDate(item)) {
           
            const date = cuteDate(item)
            
            return (
              <td key={index + 1}>
                {date}
              </td>
            )
            
            //Si no es ni fecha ni is_active
          } else {
            
            return (
              <td key={index + 1}>
                {item}
              </td>
            )
          }
        })
      }
      
      <td className="" onClick={handleEditClick}>
        <Button color="info">
          <Edit  />
        </Button>
      </td>
      
      {
        <OptionButton
          type={type}
          user={user}
          is_admin={is_admin}
          handleOptionAction={handleDesactivateClick}
        />
      }
      
    </tr>
  )
}

const mapStateToProps = ({ Login: { user: { attributes: { is_admin } } } }) => ({
  is_admin
})

export default connect(mapStateToProps)(UserRowTable);