import axios from "axios";
import { API, guestRegister, guestInvitation, messages } from "../api/apiURLs";
import { resetPassword, adminUserRenewal, workshopCreation } from "../api/apiURLs";
import { messageToSend } from "../widgets/utils/objectToSend";

const { REACT_APP_ADMIN_URL, REACT_APP_BOARD_URL, REACT_APP_ADMIN_EMAIL } = process.env;

export const resetPasswordEmail = async (message) => {
  const response = await axios.post(`${API}${messages}${resetPassword}`, message)
  console.log(response)
}

export const newGuest = async (message) => {
  const response = await axios.post(`${API}${messages}${guestRegister}`, message)
  console.log(response)
}

export const registeredGuest = async (message) => {
  const response = await axios.post(`${API}${messages}${guestInvitation}`, message)
  console.log(response)
}

export const workshopNotification = async (message) => {
  const response = await axios.post(`${API}${messages}${workshopCreation}`, message)
  console.log(response)
}

export const sendGuestEmail = async (type, attributes, guest) => {
  const { email, sign_in_code } = guest.attributes

  const send_invitations = {
    guest_registry: newGuest,
    guest_invitation: registeredGuest
  }

  if (type === 'guest_registry') {
    //Email to those guests who are not full-registered yet
    attributes.registry_url = `${REACT_APP_ADMIN_URL}/updateGuest/${guest.id}`

  } else if (type === 'guest_invitation') {
    //Email to those guests who are already full-registered
    attributes.sign_in_code = sign_in_code;
    attributes.board_url = REACT_APP_BOARD_URL;
  }

  //The attributes build the object to send that contains the message
  const message = messageToSend(type, email, attributes)

  await send_invitations[type](message)
}

export const sendNotificationToAdmin = async (message) => {
  await axios.post(`${API}${messages}${adminUserRenewal}`, message)
}

export const sendBillingNotification = {
  // Mensaje de Notificación al admin
  fail: async (body) => {
    try {
      debugger
      await axios.post(`${API}${messages}/failedinvoice`, body)
    } catch (error) {
      console.log(error)
      console.log("Error notificación admin")
    }
  },

}