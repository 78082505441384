import SEARCH_GUESTS_TABLE from './actionTypes'
import { getState_from_LocalStorage } from '../../helpers/utils'
import { setState } from '../utils'

let initialState = {
  guests_search: []
}

const FIELD = 'Guests'

if (localStorage.Guests) {
  initialState = getState_from_LocalStorage(FIELD)
}

const guestsReducer = (state = initialState, action) => {

  const commonAction = {
    [SEARCH_GUESTS_TABLE]: "guests_search"
  }

  const customAction = {}
  
  return setState(state, action, FIELD, commonAction, customAction);
}

export default guestsReducer;