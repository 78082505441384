import { getDateFrom } from "../../../utils/parseDate/getValues";
import { objectToSend } from "../../../widgets/utils/objectToSend"

export const getWorkshopObject = (workshop) => {
  const UNREACHABLE_DATE = "3000-10-25";

  //El workshop no se debe modificar por eso se hace una copia
  let workshopInfo = { ...workshop }
  let { date, start, end, id, expired, ...rest } = workshopInfo
  
  debugger
  
  start = getDateFrom(date, start).toISOString()
  end = getDateFrom(
    workshop.has_expiration ? date : UNREACHABLE_DATE,
    end
  ).toISOString()

  const attributes = { ...rest, start, end }
  const workshopObject = objectToSend('workshops', attributes)

  workshopInfo = { ...workshopInfo, start, end }

  return { workshopObject, workshopInfo };
}