import React, { useState, useRef } from 'react';
import InputModal from '../../widgets/inputModal';
import InputDateModal from '../../widgets/inputDateModal';
import Button from '../../widgets/button';
import { connect } from 'react-redux';
import { filterGuests, findWorkshopGuests } from './utils/workshopsUtils';
import { shortDate } from '../../utils/parseDate/formatDisplay';

const WorkshopFilter = ({ workshops, workshopsGuests, total_guests, setSorted, search, setSearch }) => {
  const initialFilter = { date1: "", date2: "", guests: "" }

  const [filterItems, setFilterItems] = useState(initialFilter)
  const guestsInput = useRef(null)

  const handleChange = ({ target: input }) => {
    const { name } = input
    let filtered = workshops

    const handleDate = (workshops, { name, value }) => {
      const filter = () => {
        const sorted = workshops.filter(workshop => {
          const start = shortDate(workshop.attributes.start)

          const date = {
            date1: value <= start,
            date2: start <= value,
          }

          return date[name];
        })

        return sorted;
      }
      const filtered = (value !== "") ? filter() : []
      //debugger
      return filtered
    }

    const handleGuests = (workshops, input) => {

      const filtered = workshops.filter(workshop => {
        let guests = findWorkshopGuests(workshop.id, workshopsGuests)
        guests = filterGuests(guests, total_guests)
        return !!guests.find(guest => guest.attributes.email.includes(input.value))
      })

      return filtered;
    }

    const inputs = {
      date1: handleDate,
      date2: handleDate,
      guests: handleGuests
    }

    //debugger
    if (input.value) {
      filtered = inputs[name](workshops, input)
    }

    for (const item in filterItems) {
      //debugger
      //si filterItems[item] es true y si es diferente de name de tal manera de que no se haga la misma operación
      if (filterItems[item] && input.name !== item) {
        const input = {
          name: item,
          value: filterItems[item]
        }

        filtered = inputs[item](filtered, input)
      }
    }

    setFilterItems({
      ...filterItems,
      [input.name]: input.value
    })

    setSearch(true)
    setSorted(filtered)
  }

  const clearSearch = () => {
    setSearch(false)
    setSorted([])
    setFilterItems(initialFilter)
    guestsInput.current.value = ""
  }

  const { date1, date2, guests } = filterItems

  return (
    <div className="WorkshopFilter mt-3">
      <InputDateModal
        id="date1"
        type="date"
        title="Desde:"
        className="form-control"
        defaultValue={date1}
        handleChange={handleChange}
      />

      <InputDateModal
        id="date2"
        type="date"
        title="Hasta:"
        className="form-control"
        defaultValue={date2}
        handleChange={handleChange}
      />
      
      <InputModal
        id="guests"
        type="email"
        title="Invitados:"
        placeholder="Buscar"
        value={guests}
        setRefInputs={guestsInput}
        className="form-control"
        handleChange={handleChange}
      />
      
      {
        search &&
        <Button
          text="Limpiar"
          color="info"
          handleClick={clearSearch}
        />
      }
    </div>
  )
}

const mapStateToProps = ({ Login: { total_guests }, Workshops: { workshopsGuests } }) => ({
  total_guests,
  workshopsGuests
})

export default connect(mapStateToProps)(WorkshopFilter);
