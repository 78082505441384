import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../widgets/button'
 
const AddPlanButton = ({history, url}) => {
  return (
    <Button
      handleClick={()=>{history.push(url)}} 
      type="button" 
      color="success" 
      className="btn-fixed-w mb-3 mr-2 font-weight-bold" 
      align="right"
    >
      Agregar
    </Button>
  )
}

export default withRouter(AddPlanButton);