import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Auth/Login';
import Logout from './pages/Auth/Logout';
import Register from './pages/Auth/Register';
import ForgetPassword from './pages/Auth/forgetPassword';
import ResetPassword from './pages/Auth/resetPassword';

import PaypalPaymentCapture from './pages/Auth/paypalPaymentCapture';
import PaymentIdRegister from './pages/Auth/paymentIdRegister';
import UpdateGuest from './pages/Auth/updateGuest';

import TyC from './pages/Others/tyc';
import TyCInter from './pages/Others/tyc-international';

// =================================================================
//                          ADMIN PAGES
// =================================================================

import DashboardV3 from './pages/Dashboard/dashboardv3';
import Profile from './pages/Users/profile';
import Workshops from './pages/Workshops/workshops';
import Users from './pages/Users/users';
import Guests from './pages/Guess/guess';
import Plans from './pages/Plans/plans';
import EditPlans from './pages/Plans/editPlans';
import Pricing from './pages/Others/pricing';

// =================================================================
//                          ADMIN PAGES
// =================================================================

import MomentTries from './pages/Tries/momentTries';
import PageNotFound from './pages/Error/404';

//============ Routes File =============*
// import routes from './routes';
//============ Font Awesome Css =============*
import './assets/css/font-awesome.min.css';
//============ Flag Icons Css =============*
import './assets/css/flag-icon.min.css';
//============ Themify Icons Css =============*
import './assets/css/themify-icons.css';
//============ Ionicons Css =============*
import './assets/css/ionicons.min.css';
//============ Ladda Buttons Css =============*
import './assets/css/ladda.min.css';
//============ EtLine Css =============*
import './assets/css/et-line.css';
//============ Feather Css =============*
import './assets/css/feather.css';
//============ Main App Scss =============*
import './App.scss';
import { approvePaypalUrl } from './api/paypal';
import Billing from './pages/Facturaciones/Billing';
import BillingDetails from './pages/Facturaciones/BillingDetails';
import NewBilling from './pages/Facturaciones/NewBilling';
import NewUsers from './pages/Users/NewUsers';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/resetPassword" component={ForgetPassword} />
        <Route exact path="/resetPassword/:userID" component={ResetPassword} />

        <Route exact path={approvePaypalUrl} component={PaypalPaymentCapture} />
        <Route exact path="/payment-id-register" component={PaymentIdRegister} />
        <Route exact path="/updateGuest/:guestID" component={UpdateGuest} />
        <Route exact path="/terminos-condiciones" component={TyC} />
        <Route exact path="/terminos-condiciones-international" component={TyCInter} />

        {/*ADMIN-ROUTES*/}
        <Route exact path="/dashboard" component={DashboardV3} />
        <Route exact path="/agenda" component={Workshops} />

        <Route exact path="/usuario/:id" component={Profile} />
        <Route exact path="/usuarios" component={Users} />
        <Route exact path="/usuarios-nuevo" component={NewUsers} />

        <Route exact path="/invitados" component={Guests} />

        <Route exact path="/planes" component={Plans} />
        <Route exact path="/planes/:id" component={EditPlans} />

        <Route exact path="/momentTries" component={MomentTries} />
        <Route exact path="/suscripciones" component={Pricing} />

        <Route exact path="/facturaciones" component={Billing} />
        <Route exact path="/facturaciones/generar" component={NewBilling} />
        <Route exact path="/facturaciones/:nroComprobante" component={BillingDetails} />

        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}



export default App; 