import { ACTIVATE_AUTH_LAYOUT, ACTIVATE_NON_AUTH_LAYOUT, IS_LARGE } from './actionTypes';

let initialState = {}
if(localStorage.getItem('Layout')){
    initialState = JSON.parse(localStorage.getItem('Layout'))
}else{
    initialState = {
        topbar: true,
        sidebar: true,
        footer: true,
        is_large_state: false,
    }
}
const layout = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVATE_AUTH_LAYOUT:
            state = {
                ...state,
                ...action.payload
            }
            window.localStorage.setItem('Layout', JSON.stringify(state))
            break;
            
        case ACTIVATE_NON_AUTH_LAYOUT:
            state = {
                ...state,
                ...action.payload
            }
            window.localStorage.setItem('Layout', JSON.stringify(state))
            break;
            
        case IS_LARGE:
            state = {
                ...state,
                is_large_state: action.payload
            }
            window.localStorage.setItem('Layout', JSON.stringify(state))
            break;
            

        default:
            state = { ...state };
            window.localStorage.setItem('Layout', JSON.stringify(state))
            break;
            
    }
    return state;
}

export default layout;