import React, { useState } from 'react'
import { Card } from 'reactstrap'
import WidthLimit from '../../layout/widthLimit';
import { getTodayUTC } from '../../utils/parseDate/getValues';
import { paintCurrencyTypes } from '../Plans/utils/currencyType';
import PlansCard from './plansCard'
import RenewalButton from './renewalButton';

const SuscriptionsBoard = ({ user, plans, handleSelectPlan }) => {

  const [currency, setCurrency] = useState("")
  const [filteredPlans, setfilteredPlans] = useState(plans)

  const { is_admin, due_date } = user.attributes;
  const userPlan = user.attributes.plan

  if (!is_admin) {
    // Se quita el plan actual del usario de tal manera que no sea seleccionable
    plans = plans.filter(plan => plan.id !== userPlan.id)

    //Se filtran los planes dejando sólo los que están activos
    plans = plans.filter(plan => !!plan.attributes.is_active)
  }

  const handleChange = ({ target }) => {
    setCurrency(target.value)

    //Se filtra por el tipo de moneda
    let newFiltered = plans.filter(plan => plan.attributes.currency === target.value)

    newFiltered = newFiltered.length ? newFiltered : plans

    setfilteredPlans(newFiltered)
  }
  
  const today = getTodayUTC()

  return (
    <WidthLimit>
      <Card className="width-limit__container suscriptions p-4">
        
        <div className="Suscriptions-title">
          <h4 className="card_title m-0">Suscripciones</h4>
          {
            // Solo aparece cuando la fecha del plan ya haya expirado
            !is_admin && (today > due_date) && (
              <RenewalButton user={user} />
            )
          }
        </div>
        
        <div className="Suscriptions-filter">

          {
            !is_admin &&
            <div className="btn bg-info">
              {userPlan.attributes.name}
            </div>
          }
          
          <select
            name="currency"
            value={currency}
            onChange={handleChange}
            className="form-control filter"
          >
            <option value="">Seleccione...</option>
            {
              paintCurrencyTypes()
            }
          </select>
        </div>
        
        <div id="mt_pricing">
          <div className="px-md-3">
            <div className="suscriptions-container">
              {
                filteredPlans.map((plan, index) => (
                  <PlansCard
                    key={index}
                    plan={plan}
                    handleSelectPlan={handleSelectPlan}
                  />
                ))
              }
            </div>
          </div>
        </div>
        
      </Card>
    </WidthLimit>
  )
}

export default SuscriptionsBoard;