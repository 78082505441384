import { setState_At_LocalStorage } from "../helpers/utils"

export const setState = (state, action, FIELD, commonAction, customAction) => {
  const {type} = action
  
  const setType = (name) => {
    state = { ...state, [name]: action.payload }
  }

  const DEFAULT_ACTION = () => {
    state = {...state}
  }

  if (commonAction[type]) {
    //Sólo asigna un valor al state
    setType(commonAction[type])
    
  } else if (customAction[type]) {
    //Asigna múltiples valores y posiblemente ejecute alguna función
    state = customAction[type]()

  } else {
    DEFAULT_ACTION()
  }

  setState_At_LocalStorage(FIELD, state);

  return state;
}
