import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { filterAndGetUsers, getAdmins } from '../../helpers/getDashboardData';
import WorkshopsList from './workshopsList';

const { REACT_APP_BOARD_URL } = process.env;

const WorkshopsCreated = ({ user, sortedWorkshops, search, workshopsGuests, handleWorkshop }) => {

  const [users, setUsers] = useState({
    admins: [],
    notAdmins: [],
  })

  useEffect(() => {
    (async () => {
      try {

        const admins = await getAdmins()
        const users = await filterAndGetUsers()

        setUsers({ admins, notAdmins: users.rows })

      } catch (error) {
        console.log(error)
      }
    })()
  }, [])


  return (
    <div className="no-shadow">

      <div className="d-flex flex-column flex-sm-row pt-3">
        <h4 className="d-inline">
          {user.is_admin ? 'Todas las sesiones' : 'Tus sesiones'}
        </h4>
        <a href={REACT_APP_BOARD_URL} rel="noopener noreferrer" target="_blank" className="pl-3 buttom_sala">
          Accede a la sala aquí
        </a>
      </div>

      <ul className="workshopsCreated py-3 px-0">
        {
          search ?
            <WorkshopsList
              users={users}
              workshops={sortedWorkshops}
              workshopsGuests={workshopsGuests}
              handleWorkshop={handleWorkshop}
              noResultMessage="No hay resultados que coincidan con la busqueda"
            />
            :
            <WorkshopsList
              users={users}
              workshops={user.workshops}
              workshopsGuests={workshopsGuests}
              handleWorkshop={handleWorkshop}
              noResultMessage="Aún no tienes Sesiones agendadas"
            />
        }
      </ul>

    </div>
  )
}

const mapStateToProps = ({ Workshops: { workshopsGuests } }) => ({
  workshopsGuests
})

export default connect(mapStateToProps)(WorkshopsCreated);