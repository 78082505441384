import React from 'react';
import { Link } from 'react-router-dom';
import { cuteDate } from '../../utils/parseDate/formatDisplay';
import { getTodayUTC } from '../../utils/parseDate/getValues';
import Button from '../../widgets/button';

const ProfileInfo = ({ user }) => {
  const today = getTodayUTC();
  return (
    <div className="profile__card pb-3">
      
      <h3 className="font-weight-bold ">
        Perfil de {user.name} {user.is_admin ? <span className="text-info">(Admin)</span> : null}
        {
          !user.is_admin &&
          <Link to="/suscripciones">
            <Button color="success ml-4">
              <strong>Renovar Plan</strong>
            </Button>
          </Link>
        }
      </h3>
      
      {
        !user.is_admin &&
        <>
          <span className="profile__card--title mt-4">
            <span>Plan actual:</span> {" "}
            <span className="badge bg-info">{user.plan.attributes.name}</span>
          </span>

          <span className="profile__card--title">
            <span>Fecha de vencimiento de suscripción:</span> {" "}
            <span className="badge bg-danger">
              {(today > user.due_date) ? 'VENCIDO' : cuteDate(user.due_date)}
            </span>
          </span>
        </>
      }
    </div>
  )
}

export default ProfileInfo;