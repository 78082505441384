import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../../layout"
import PlanChanging from "./planChaging";
import SuscriptionsBoard from "./suscriptionsBoard";
import { getTodayUTC } from "../../utils/parseDate/getValues";
import { getPlans } from "../../helpers/getDashboardData";
import LoadingMessage from "./LoadingMessage";

const Pricing = ({ user }) => {

  const [loading, setLoading] = useState(false)

  const [plans, setPlans] = useState([])

  const today = getTodayUTC()
  const { due_date } = user.attributes;

  const [newPlan, setNewPlan] = useState({})
  const [changePlanVisible, setChangePlan] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const plans = await getPlans()
        setPlans(plans)

        setLoading(false)

      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    })()
  }, [])

  const handleOpenNewPlan = selectedPlan => {
    const { attributes: { is_admin, available_sessions } } = user;

    if (!is_admin) {
      //Se puede renovar si el plan ya venció o cuando las sesiones se agoten
      if (today > due_date || available_sessions <= 0) {
        window.scrollTo(0, 0)

        setNewPlan(selectedPlan)
        setChangePlan(true)

      } else {
        alert("Sólo puedes renovar tu suscripción una vez vencido el plan actual o cuando se agoten las sesiones disponibles")
      }

    } else {
      alert("Estas suscripciones son solo de muestra")
    }
  }

  const handleCloseNewPlan = () => {
    setChangePlan(false)
  }

  return (
    <Layout>
      {
        changePlanVisible &&
        <PlanChanging
          user={user}
          newPlan={newPlan}
          handleClose={handleCloseNewPlan}
        />
      }
      {
        !loading &&
        <SuscriptionsBoard
          user={user}
          plans={plans}
          handleSelectPlan={handleOpenNewPlan}
        />
      }
      <LoadingMessage
        loading={loading}
        itemList={plans}
        message="No se ha encontrado ningún plan registrado"
      />
    </Layout>
  );
}

const mapStateToProps = ({ Login: { user } }) => {
  return ({ user })
}

export default withRouter(connect(mapStateToProps)(Pricing));