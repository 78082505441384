import React from 'react'
import {Component} from 'react';
import DataTable from './dataTable.js'
import Button from '../../widgets/button';
import InputModal from '../../widgets/inputModal'
import {connect} from 'react-redux';

class Table extends Component{
  
  state={
    start: this.props.start,
    end: this.props.end,
    backButtonDisabled: true,  
    AdvanceButtonDisabled: false,  
    search: false,
    searchData: this.props.searchData,
  }
  
  changePage = (start, end) =>{
    this.setState({
      start: start,
      end: end
    })
  }
  
  advance = () =>{
    if(this.state.end < this.props.users.rows.length){
      // debugger
      const pageAdvanceStart = this.state.start + this.props.end //Se le suma el numero de usuarios por pagina
      const pageAdvanceEnd = this.state.end + this.props.end //Se le suma el numero de usuarios por pagina
      this.changePage(pageAdvanceStart, pageAdvanceEnd)
    }else{
      alert('Ya ha alcanzado el limite')
    }
  }
  
  goBack = () =>{
    if(this.state.start !== 0 || this.state.start < 0){
      // debugger
      const pageBackStart = this.state.start - this.props.end //Se le resta el numero de usuarios por pagina
      const pageBackEnd = this.state.end - this.props.end //Se le resta el numero de usuarios por pagina
      this.changePage(pageBackStart, pageBackEnd)
    }else{
      alert('Ya ha alcanzado el limite')
    }
  }
  
  checkAdvance = () =>{
    //Chequea si pueda avanzar más en la paginacion
  //debugger
    return !(this.state.end < this.props.users.rows.length)
  }
  
  checkBack = () =>{
    //Chequea si pueda retorcer más en la paginacion
    return !(this.state.start !== 0 || this.state.start < 0)
  }
  
  handleChange = () =>{
    console.log(this.search.value)
    const action = this.props.action
    // pasa el valor d
    this.props.dispatch(action(this.search.value, this.props.users.rows))
    
    this.search.value ? this.setState({search: true}) : this.setState({search: false})
  }
  
  setRef = element =>{
    this.search = element
  }
  
  render(){
    const {users, type} = this.props
  //debugger
    return(
      <>
        <div data-test="datatable-table">
          <div data-test="table" className="table-responsive scrollea">
            
            <div className="input-search">
              <InputModal
                type="text"
                id="Buscar" 
                className="form-control"
                placeholder="Buscar"
                value={this.state.workshopName}
                setRefInputs={this.setRef}
                handleChange={this.handleChange}
              />
            </div>
            
            {
              (users.rows.length)? 
                <DataTable
                  type={this.props.type}
                  users={this.props.users}
                  search={this.state.search}
                  searchData={this.props.searchData}
                  usersRenderStart={this.state.start}
                  usersRenderEnd={this.state.end}
                  handleEdit={this.props.handleEdit}
                  handleDesactivate={this.props.handleDesactivate}
                />
              : 
                <span className="d-block text-center">
                  Aún no tienes {(type === 'users')? 'usuarios' : 'invitados'}
                </span>
            }
            
          </div>
        </div>
        
        {
          //Paginación aparece si hay los suficientes
          !this.state.search && !(this.checkBack() === true && this.checkAdvance() === true) &&
          <div className="d-flex justify-content-end pt-3">
            <Button
              color="info"
              className="rounded-pill mr-2"
              loading={this.checkBack()}
              handleClick={this.goBack}
              notLoading
              text="Atras"
            />
            
            <Button
              color="info"
              className="rounded-pill"
              loading={this.checkAdvance()}
              handleClick={this.advance}
              notLoading
              text="Adelante"
            />
            
          </div>
        }
      </>
    )
  }
}

export default connect()(Table);