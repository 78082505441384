import SEARCH_GUESTS_TABLE from './actionTypes'
import {filterSearch} from '../../helpers/filterSearch'

export const searchGuests = (search, users) =>{
  
  const searchFiltered = filterSearch(search, users)
  
  return{
    type: SEARCH_GUESTS_TABLE,
    payload: searchFiltered
  }
}