import React from 'react'

const Button = (props) => {

  const { color, handleClick, setRef, type = "button", loadingLabel = "'Cargando..." } = props
  const { children, notLoading, loading = false, text = "", className = "" } = props
  return (
    <button
      type={type}
      ref={setRef}
      disabled={loading}
      onClick={handleClick}
      className={`btn btn-${color} ${className}`}
    >
      {
        (() => {
          if (children) {
            return children
          }
          return loading && !notLoading ?  loadingLabel : text;
        })()
      }

    </button>
  )
}

export default Button;