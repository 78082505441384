import React from 'react'

const InputCheck = ({ title, className, checked, required, setRefInputs, id, containerClass, defaultValue, handleChange, onInvalid, link }) => (
  <div className={containerClass}>
    <label className={containerClass} htmlFor={id}>
      <input
        type="checkbox"
        checked={checked}
        ref={setRefInputs}
        onChange={handleChange}
        defaultValue={defaultValue}
        className={className}
        name={id}
        id={id}
        onInvalid={onInvalid}
        required={required}
      />
      
      {
        link
          ?
            <a href={link} rel="noopener noreferrer" target="_blank" className="text-info pl-2">
              {title}
            </a>
          :
            <span className="pl-2">{title}</span>
      }

    </label>
  </div>
)
export default InputCheck;