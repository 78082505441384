import React from 'react'

const Hamburger = ({handleClick}) =>(
  <div 
    className="nav-btn" 
    onClick={handleClick}
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
)

export default Hamburger;