import React, { Component } from 'react'
import { connect } from 'react-redux';
import Modal from '../../widgets/modal'
import FooterModal from '../../widgets/footerModal'
import Button from '../../widgets/button'
import InputModal from '../../widgets/inputModal'
import InputDateModal from '../../widgets/inputDateModal'
import { objectToSend } from '../../widgets/utils/objectToSend'
import { updateUser } from '../../helpers/sendData'
import { setLoading, updateUsersState } from '../../store/actions'
import { validateBirthDay } from '../Auth/utils/validateForm';
import { shortDate } from '../../utils/parseDate/formatDisplay';
import { getStartOfDay } from '../../utils/parseDate/getValues';
class UserModal extends Component {

  state = {
    user: {
      ...this.props.user,
      attributes: {
        ...this.props.user.attributes,
        birth_date: shortDate(this.props.user.attributes.birth_date),
        due_date: shortDate(this.props.user.attributes.due_date),
      }
    },
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { user } = this.state;
    const { created_at, updated_at, payment_id, ...rest } = user.attributes;
    const { users, setNotification, messages, handleCloseModal, dispatch } = this.props;

    //Se le saca el payment_id porque puede ser que este usuario no tenga este atributo, por lo que ...
    //daría error si se trata de enviar porque es undefined, de igual manera no afecta en nada que no se incluya

    dispatch(setLoading(true))

    const attributes = {
      ...rest,
      birth_date: getStartOfDay(rest.birth_date),
      due_date: getStartOfDay(rest.due_date),
    }

    const body = objectToSend('user', attributes)

    //debugger

    try {
      await updateUser(user.id, body)
      await dispatch(updateUsersState(users, user, 'EDIT'))
      setNotification(true, messages.successUpdate, 'success')
      dispatch(setLoading(false))
      handleCloseModal()

    } catch (error) {
      console.log(error)

      setNotification(true, messages.errorUpdate, 'danger')
      dispatch(setLoading(false))
      handleCloseModal()
    }
  }

  handleChangeBirthDate = ({ target }) => {
    validateBirthDay(target, this.setUserKey)
  }

  setUserKey = (target) => {
    this.setState({
      user: {
        ...this.state.user,
        attributes: {
          ...this.state.user.attributes,
          [target.name]: target.value
        }
      }
    })
  }

  handleChange = ({ target }) => {
    if (target.name === 'email') {
      this.setState({
        user: {
          ...this.state.user,
          attributes: {
            ...this.state.user.attributes,
            [target.name]: target.value.toLowerCase()
          }
        }
      })
    } else {
      this.setUserKey(target)
    }
  }

  handleInvalid = () => {
    this.form.classList.add('was-validated')
  }

  setRef = element => {
    this.form = element
  }

  render() {
    
    const { loading, handleCloseModal } = this.props
    const { user } = this.state
    
    return (
      <Modal
        title="Editar Usuario"
        setRef={this.setRef}
        handleSubmit={this.handleSubmit}
        handleInvalidSubmit={this.handleInvalid}
      >
        <InputModal
          type="text"
          id="name"
          title="Nombre de usuario"
          className="form-control"
          handleChange={this.handleChange}
          value={user.attributes.name}
        />
        
        <InputModal
          type="email"
          id="email"
          title="Email"
          className="form-control"
          handleChange={this.handleChange}
          value={user.attributes.email}
        />
        
        <InputDateModal
          type="date"
          title="Fecha de Nacimiento"
          id="birth_date"
          className="form-control"
          handleChange={this.handleChangeBirthDate}
          defaultValue={user.attributes.birth_date}
        />
        
        <InputDateModal
          type="date"
          title="Fecha de Vencimiento del Plan"
          id="due_date"
          className="form-control"
          handleChange={this.handleChangeBirthDate}
          defaultValue={user.attributes.due_date}
        />

        <FooterModal>
          <Button loading={loading} color="success" type="submit" text="Guardar" />
          <Button loading={loading} color="danger" handleClick={handleCloseModal}>
            Cerrar
          </Button>
        </FooterModal>
      </Modal>
    )
  }
}

const mapStateToProps = ({ Login: { loading, total_users } }) => {
  return {
    loading,
    users: total_users
  }
}

export default connect(mapStateToProps)(UserModal);