import React, { Component, useRef, useState } from 'react'
import Modal from '../../widgets/modal'
import FooterModal from '../../widgets/footerModal'
import Button from '../../widgets/button'
import InputModal from '../../widgets/inputModal.js'
import InputDateModal from '../../widgets/inputDateModal'
import { objectToSend } from '../../widgets/utils/objectToSend'
import { updateUser } from '../../helpers/sendData'
import { validateBirthDay } from '../Auth/utils/validateForm';
import { shortDate } from '../../utils/parseDate/formatDisplay';
import { getStartOfDay } from '../../utils/parseDate/getValues';
import { findAndReplace } from '../../helpers/utils';

const UserModal = (props) => {
  const { users, selectedUser, loading, setUsers } = props
  const { setLoading, handleCloseModal, setNotification } = props
  
  const { birth_date, due_date } = selectedUser.attributes

  const [user, setUser] = useState({
    ...selectedUser.attributes,
    birth_date: shortDate(birth_date),
    due_date: shortDate(due_date),
  })
  
  const messages = {
    successUpdate: 'El usuario se ha actualizado con éxito',
    errorUpdate: 'Ha ocurrido un error con la actualización del usuario',
  }

  const $form = useRef(null)

  const handleSubmit = async event => {
    event.preventDefault();
    const { created_at, updated_at, payment_id, ...rest } = user;

    //Se le saca el payment_id porque puede ser que este usuario no tenga este atributo, por lo que ...
    //daría error si se trata de enviar porque es undefined, de igual manera no afecta en nada que no se incluya

    setLoading(true)

    const attributes = {
      ...rest,
      birth_date: getStartOfDay(rest.birth_date),
      due_date: getStartOfDay(rest.due_date),
    }

    const body = objectToSend('user', attributes)

    try {
      const updatedUser = await updateUser(user.id, body)
      const updatedUserList = findAndReplace(updatedUser, users)
      
      setUsers(updatedUserList)
      
      setNotification({
        state: true,
        color: 'success',
        message: messages.successUpdate,
      })
      setLoading(false)
      handleCloseModal()

    } catch (error) {
      console.log(error)

      setNotification({
        state: true,
        color: 'danger',
        message: messages.errorUpdate,
      })
      setLoading(false)
      handleCloseModal()
    }
  }

  const handleChange = ({ target }) => {
    const { name, value } = target
    if (name === 'birth_date' || name === 'due_date') {

      validateBirthDay(target, ({ name, value }) => {
        setUser({
          ...user,
          [name]: value
        })
      })

    } else if (name === 'email') {
      setUser({
        ...user,
        [name]: value.toLowerCase()
      })
    } else {
      setUser({
        ...user,
        [name]: value
      })
    }
  }

  return (
    <Modal
      title="Editar Usuario"
      ref={$form}
      handleSubmit={handleSubmit}
      handleInvalidSubmit={() => $form.current.classList.add('was-validated')}
    >
      <InputModal
        type="text"
        id="name"
        title="Nombre de usuario"
        className="form-control"
        handleChange={handleChange}
        value={user.name}
      />

      <InputModal
        type="email"
        id="email"
        title="Email"
        className="form-control"
        handleChange={handleChange}
        value={user.email}
      />

      <InputDateModal
        type="date"
        title="Fecha de Nacimiento"
        id="birth_date"
        className="form-control"
        handleChange={handleChange}
        defaultValue={user.birth_date}
      />

      <InputDateModal
        type="date"
        title="Fecha de Vencimiento del Plan"
        id="due_date"
        className="form-control"
        handleChange={handleChange}
        defaultValue={user.due_date}
      />

      <FooterModal>
        <Button loading={loading} color="success" type="submit" text="Guardar" />
        <Button loading={loading} color="danger" handleClick={handleCloseModal}>
          Cerrar
        </Button>
      </FooterModal>
    </Modal>
  )
}

export default UserModal;