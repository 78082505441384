export const warningTime = (warningMessage) => {
  alert(warningMessage)
}

export const clearBothTimes = (workshop, setWorkshop) => {
  alert('Horas invalidas!')
  setWorkshop({
    ...workshop,
    start: '',
    end: '',
  })
}

export const findWorkshopGuests = (workshopID, workshopsGuests) => {
  //workshopsGuests = [ [{...},{...}], [{...}], [] ]

  const foundGuests = workshopsGuests.find(guests => {
    if (guests.length) {
      //guests[n].id es el id del workshop al que pertenece
      return workshopID === guests[0].id;
    }
    return false
  }) || [];

  return foundGuests;
}

export const filterGuests = (workshopsGuests, total_guests) => {
  const guests = workshopsGuests.map(({ attributes: guest }) => {

    //Se encuentran los atributos del invitado y se le coloca en los atributos el sign_in_code
    const FoundGuest = total_guests.find(guestToVerify => {
      return guest.guest_id === guestToVerify.id
    })
    FoundGuest.attributes.sign_in_code = guest.sign_in_code

    return FoundGuest;
  })

  return guests;
}

export const removeGuestFrom = (workshopsGuests, workshop, guest) => {
  //workshopsGuests = [ [{...},{...}], [{...}], [] ]

  const newWorkshopsGuests = workshopsGuests.map(guests => {
    if (guests.length && guests[0].id === workshop.id) {
      
      const newGuests = guests.filter(guestToReplace => {
        return guestToReplace.attributes.guest_id !== guest.id
      })

      return newGuests;
    }

    return guests;
  })

  return newWorkshopsGuests;
}

// export const getGuestsIDs = (workshopsGuests) => {
//   //workshopsGuests = [ [{...},{...}], [{...}], [] ]

//   let guests = workshopsGuests.map(guests => {
//     if (guests.length) {
//       return guests.map(guest => guest.attributes.guest_id)
//     }
//     return guests; //[]
//   }).flat();

//   guests = guests.filter(guest => !!guest);
//   return [...new Set(guests)];
// }