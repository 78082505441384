import { addHours } from 'date-fns';
import React from 'react'

const ExpirationBadge = ({today, end, code}) => {
  
  const oneHourFrom = dateTime => {
    return addHours(new Date(dateTime), 1).toISOString();
  }

  const isExpired = today > oneHourFrom(end);

  let badgeColor = "";
  let badgeInnerText = "";
  
  if(isExpired){
    badgeColor = "danger";
    badgeInnerText = "Sesión vencida";
    
  }else{
    badgeColor = "info";
    badgeInnerText = `Clave: ${code}`;
  }

  return (
    <span className={`badge bg-${badgeColor} text-left`}>
      <strong>{badgeInnerText}</strong>  
    </span>
  )
}

export default ExpirationBadge
