import React from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../layout';
import WidthLimit from '../../layout/widthLimit';
import { Component } from 'react';
import Table from '../Users/table';
import { searchGuests, setLoading, setTotalGuestsAtStore, updateGuests, updateGuestsState } from '../../store/actions'
import GuestModal from './guestModal';
import { deleteGuest } from '../../helpers/deleteData';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '../../widgets/button';
import { getTotalGuest } from '../../helpers/getDashboardData';

class Guests extends Component {

  state = {
    // loading: false,
    visibleModal: false,
    visibleAlert: false,
    confirmMsg: '¿Estás seguro de que quieres eliminar este invitado?',
    guest: null,
    messages: {
      successUpdate: 'El invitado se ha actualizado con éxito',
      errorUpdate: 'Ha ocurrido un error con la actualización del invitado',
      successDelete: 'Se ha eliminado el invitado con éxito',
      errorDelete: 'Ha ocurrido un error con la eliminación del invitado',
    },
    notification: {
      state: false,
      color: '',
      message: '',
    }
  }

  handleEdit = (guest) => {
    this.setState({
      visibleModal: true,
      guest,
    })
  }
  handleOpenAlert = (guest) => {
    this.setState({
      visibleAlert: true,
      guest,
    })
  }
  handleCloseModal = () => {
    this.setState({
      visibleModal: false
    })
  }
  handleCloseAlert = () => {
    this.setState({
      visibleAlert: false,
      guest: null
    })
  }

  handleDeleteGuest = async () => {
    const { userGuests, dispatch } = this.props;
    const { guest, messages } = this.state;
    const { guestID } = guest;

    //debugger

    try {
      await deleteGuest(guestID)
      dispatch(updateGuestsState(userGuests, guest, 'DELETE'))
      this.setNotification(true, messages.successDelete, 'success')
      this.handleCloseAlert()

    } catch (error) {
      console.log(error)
      this.setNotification(true, messages.errorDelete, 'danger')
      this.handleCloseAlert()
    }
  }

  handleUpdateGuests = async () => {
    const { user, dispatch } = this.props;
    dispatch(setLoading(true))

    try {
      //Se obtienen todos los invitados
      const guests = await getTotalGuest()
      dispatch(setTotalGuestsAtStore(guests)) // "userGuests"
      
      await dispatch(updateGuests(user))
      alert('Se han actualizado los invitados')
      dispatch(setLoading(false))

    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch(setLoading(false))
    }
  }

  setNotification = (state, message, color) => {
    this.setState({
      notification: {
        state,
        color,
        message
      }
    })
  }
  resetNotification = () => {
    this.setNotification(false, '', '')
  }

  render() {
    const { loading } = this.props;
    const { handleUpdateGuests } = this;
    const { notification, guest, messages } = this.state;
    const { visibleModal, visibleAlert } = this.state;
    return (
      <Layout>
        {
          visibleModal &&
          <GuestModal
            guest={guest}
            messages={messages}
            setNotification={this.setNotification}
            handleCloseModal={this.handleCloseModal}
          />
        }
        {
          visibleAlert &&
          <SweetAlert
            title={this.state.confirmMsg}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.handleDeleteGuest}
            onCancel={this.handleCloseAlert}
          >
            Esta acción no se puede deshacer
          </SweetAlert>
        }
        <WidthLimit>
          <Card className="width-limit__container">
            <CardBody>
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                <h4 className="card_title">INVITADOS</h4>
                <Button
                  type="submit"
                  handleClick={handleUpdateGuests}
                  color="success mb-3"
                  loading={loading}
                  text="Actualizar"
                />

              </div>
              <Table
                start={0}
                end={20}
                type="guests"
                action={searchGuests}
                users={this.props.userGuests || { rows: [], columns: [] }}
                searchData={this.props.guests_search}
                handleEdit={this.handleEdit}
                handleDesactivate={this.handleOpenAlert}
              />
              {
                notification.state &&
                <Alert onClick={this.resetNotification} className="mt-4" color={notification.color}>
                  {notification.message}
                </Alert>
              }
            </CardBody>
          </Card>
        </WidthLimit>
      </Layout>
    )
  }
}

const mapStateToProps = ({ Login: { loading, userGuests, user: { id, attributes: { is_admin } } }, Guests: { guests_search } }) => ({
  user: {
    id, is_admin
  },
  loading,
  userGuests,
  guests_search,
})

export default withRouter(connect(mapStateToProps)(Guests));