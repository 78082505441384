import React from 'react';
import { Alert } from 'reactstrap';

const SuccessFail = ({result, message}) => {
  return (
    <div className="px-3">
      {
        // Indica si fue exitoso o no...
        result === 'OK'?
          <Alert color="success">
            {message}
          </Alert>
        :
        result === 'ERROR'?
          <Alert color="danger">
            {message}
          </Alert>
        :
          null
      }
    </div>
  )
}

export default SuccessFail;
