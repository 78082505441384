const { REACT_APP_API_URL, REACT_APP_PAY_URL } = process.env;
const { REACT_APP_ADMIN_URL, REACT_APP_WEB_URL } = process.env;

console.log(`API_URL: ${REACT_APP_API_URL}`)

export const API = REACT_APP_API_URL;
export const API_AFIP = process.env.REACT_APP_API_AFIP

export const API_MERCADO_PAGO = `${REACT_APP_PAY_URL}/api/v1/vincor/mp/createPlan`;
export const API_PAYPAL = `${REACT_APP_PAY_URL}/api/v1/vincor/paypal`;

export const userUrlRequest = '/users';
export const guestUrlRequest = '/guests';
export const emails = '/emails';
export const plans = '/plans';
export const workshopUrlRequest = '/workshops';
export const messages = '/messages';
export const resetPassword = '/resetpassword';
export const guestRegister = '/guestregistry';
export const guestInvitation = '/guestinvitation';
export const adminUserRenewal = '/adminuserrenewal';
export const workshopCreation  = '/workshopcreation ';

//Payment Buttons Status results URLs
export const success_payment_url = REACT_APP_WEB_URL;
export const failure_payment_url = REACT_APP_WEB_URL;
export const pending_payment_url = REACT_APP_WEB_URL;

//Renewal Buttons Status results URLs
export const success_renewal_url = REACT_APP_ADMIN_URL;
export const failure_renewal_url = REACT_APP_ADMIN_URL;
export const pending_renewal_url = REACT_APP_ADMIN_URL;