import { REGISTER_GUEST, REGISTER_GUEST_SUCCESSFUL, REGISTER_GUEST_FAILED } from './actionTypes';
import { EMPTY_ERROR, REGISTER_RESET } from './actionTypes';

const initialState = {
  registerSuccess: null,
  registrationError: null,
  message: null,
  loading: null
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case EMPTY_ERROR:
      state = {
        registrationError: false,
        user: false,
        loading: false
      }
      break;
    case REGISTER_GUEST:
      state = {
        ...state,
        loading: true,
        registerSuccess: null,
        registrationError: null
      }
      break;
    case REGISTER_GUEST_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registerSuccess: true,
        registrationError: null
      }
      break;
    case REGISTER_GUEST_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: true,
        registerSuccess: null,
      }
      break;

    case REGISTER_RESET:
      state = {
        ...state,
        loading: false,
        registrationError: null,
        registerSuccess: null,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default account;