import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteWorkshopGuest } from '../../helpers/deleteData';
import { setTotalGuestsAtStore, set_WorkshopGuests_AtStore, updateGuests } from '../../store/actions';
import Button from '../../widgets/button';
import Trash from '../../widgets/icons/trash';
import { logError } from '../../widgets/utils/error';
import { removeGuestFrom } from './utils/workshopsUtils';
import { getTotalGuest } from '../../helpers/getDashboardData';

class GuestItem extends Component {
  state = {
    visibleAlert: false,
    alertMessage: '¿Estás seguro de que quieres eliminar este invitado del taller?'
  }

  setWarning = () => {
    this.setState({
      visibleAlert: true
    })
  }

  handleDeleteGuest = async () => {
    const { workshop, workshopsGuests, guest, user, dispatch } = this.props
    try {
      const newWorkshopsGuests = removeGuestFrom(workshopsGuests, workshop, guest)

      await deleteWorkshopGuest(workshop.id, guest.id)

      //Se obtienen todos los invitados
      const guests = await getTotalGuest()
      dispatch(setTotalGuestsAtStore(guests)) // "userGuests"
      
      dispatch(updateGuests(user))
      dispatch(set_WorkshopGuests_AtStore(newWorkshopsGuests))

      alert('se ha eliminado el invitado del taller')
    } catch (error) {
      logError(error)
      alert('Ha habido un error con la eliminación del invitado del taller')
    }
    // alert('se ha elimnado el usuario del taller')
    this.setState({
      visibleAlert: false
    })
  }

  handleCloseAlert = () => {
    this.setState({
      visibleAlert: false
    })
  }

  render() {
    const { guest } = this.props
    const { name, email, sign_in_code } = guest.attributes
    return (
      <>
        {
          this.state.visibleAlert &&
          <SweetAlert
            title={this.state.alertMessage}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.handleDeleteGuest}
            onCancel={this.handleCloseAlert}
          >
            Esta acción no se puede deshacer
          </SweetAlert>
        }
        <li className="GuestItem bg-dark mb-2 p-3">
          <span className="">
            {
              name !== ''
                ? <span className="font-weight-bold">{name}</span>
                : <span>{email}</span>
            }
            {` - ${sign_in_code}`}
          </span>
          <Button color="danger" handleClick={this.setWarning}>
            <Trash />
          </Button>
        </li>
      </>
    )
  }
}

const mapStateToProps = ({ Login, Workshops }) => {
  //workshopsGuests = [ [{...},{...}], [{...}], [] ]

  const { user: { id, attributes: { is_admin } } } = Login;
  const { workshopsGuests } = Workshops;

  return {
    user: { id, is_admin },
    workshopsGuests
  }
}



export default connect(mapStateToProps)(GuestItem);