import React, { useState, useEffect } from 'react';
import Redirection from '../Router/redirection'
import { Card, CardBody, Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Layout from '../../layout';
import AddPlanButton from './addPlanButton';
import WidthLimit from '../../layout/widthLimit';
import SuccessFail from './successFail';
import PlanRow from './planRow';
import SweetAlert from 'react-bootstrap-sweetalert';
import { orderPlans } from './utils/orderPlans';
import SaveButton from './saveButton';
import { deletePlan, savePlans } from '../../helpers/sendData';
import { isArray } from 'lodash';
import { getPlans } from '../../helpers/getDashboardData';
import LoadingMessage from '../Others/LoadingMessage';

const Plans = () => {
  const [loading, setLoading] = useState(false)

  const [orderedPlans, setOrderedPlans] = useState([])
  const [changingOrder, setChangingOrder] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState({
    id: "",
    name: "",
  })

  const [state, setState] = useState({
    //changingOrder es para que se despliegue el boton de guardar
    // changingOrder: false,
    confirmMsg: "",
    confirm_both: false,
  })

  const [status, setStatus] = useState({
    result: "",
    message: "",
  })

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)

        const plans = await getPlans()
        const orderedPlans = orderPlans(plans);
        debugger
        setChangingOrder(false)
        setOrderedPlans(orderedPlans)
        setLoading(false)
        
      } catch (error) {
        console.log(error)
        setLoading(false)
        alert("Ha habido un error con la carga de los planes, recargue la página")
      }
    })()

  }, [])

  const handleDeletePlan = {
    confirm: async () => {
      debugger

      try {
        await deletePlan(selectedPlan.id)

        setStatus({
          result: "OK",
          message: `El plan "${selectedPlan.name}" se eliminó correctamente.`
        })

        orderedPlans = orderedPlans.filter(plan => plan.id !== selectedPlan.id)
        orderedPlans = orderPlans(orderedPlans);

        await savePlans(orderedPlans)

        setChangingOrder(false)
        setOrderedPlans(orderedPlans)

      } catch (error) {
        console.log(error)
        let cantDelete = false

        if (error.response) {
          const { response: { data: { errors } } } = error;

          if (isArray(errors)) {
            cantDelete = errors[0].title.includes('it has users subscribe')
          }

          if (cantDelete) {
            setStatus({
              result: "ERROR",
              message: `El plan "${selectedPlan.name}" tiene usuarios suscritos, no se puede eliminar.`
            })
          }
        }

        if (!cantDelete) {
          setStatus({
            result: "ERROR",
            message: `Ocurrió un error al eliminar el plan "${selectedPlan.name}".`
          })
        }
      }

      setState({
        ...state,
        confirm_both: false,
      })
    },
    cancel: () => setState({
      ...state,
      confirm_both: false,
    })
  }

  const handleDeleteAlert = ({ selectedPlan, ...rest }) => {
    setSelectedPlan(selectedPlan)
    setState(rest)
  }

  const handleSaveOrder = async () => {
    setLoading(true)

    try {
      await savePlans(orderedPlans);
      setLoading(false)

      setChangingOrder(false)
      setOrderedPlans(orderedPlans)

    } catch (error) {
      console.log(error)
      console.log(error.message)
      setLoading(false)
    }
  }

  return (
    <Redirection>
      <Layout>
        <WidthLimit>
          <Card className="width-limit__container">
            <CardBody className="plans">

              <div className="plans-header">
                <h4 className="card_title">PLANES DE SUSCRIPCIÓN</h4>

                <div style={{ display: "grid", gap: "10px" }}>
                  <AddPlanButton url={'/planes/add'} />
                  {
                    changingOrder &&
                    <SaveButton
                      loading={loading}
                      className="font-weight-bold"
                      handleClick={handleSaveOrder}
                    />
                  }
                </div>
              </div>

              <LoadingMessage
                loading={loading}
                itemList={orderPlans}
                message="No se ha encontrado ningún plan registrado"
              />

              {/* Si error Message no está en true se renderiza la tabla */}
              {
                !loading &&
                <Table responsive className="mb-0 table-hover progress-table text-center">
                  <thead className="text-uppercase">
                    <tr>
                      <th scope="col">N°</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Cant. de Invitados</th>
                      <th scope="col">Cant. de Sesiones</th>
                      <th scope="col">Moneda</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Última Modificación</th>
                      <th scope="col">action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      orderedPlans.map(plan =>
                        <PlanRow
                          plan={plan}
                          key={plan.id}
                          setStatus={setStatus}
                          orderedPlans={orderedPlans}
                          setOrderedPlans={setOrderedPlans}
                          setChangingOrder={setChangingOrder}
                          handleDeleteAlert={handleDeleteAlert}
                        />
                      )
                    }
                  </tbody>
                </Table>
              }

            </CardBody>

            {/* Indica si fue exitoso o no... */}
            <SuccessFail {...status} />

            {
              // Pregunta si está seguro de querer borrar el plan
              state.confirm_both &&
              <SweetAlert
                title={state.confirmMsg}
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={handleDeletePlan.confirm}
                onCancel={handleDeletePlan.cancel}
              >
                No se puede revertir!
              </SweetAlert>
            }

          </Card>
        </WidthLimit>
      </Layout>
    </Redirection>
  );
}

export default withRouter(Plans);