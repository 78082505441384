import { aboveAge, isNot_a_FutureDay, isNot_a_PastDay } from "../../../utils/parseDate/validation";

export const validateBirthDay = (target, setUserKey) =>{
  const date = target;
  if(target.name === 'birth_date'){
    
    if(isNot_a_FutureDay(date.value)){
      
      if(!aboveAge(date.value)){
        alert('Fecha invalida: Menor de edad')
        date.value = "";
      }else{
        setUserKey(target)
      }
      
    }else{
      alert('Fecha invalida')
      date.value = "";
    }
    
  }else if(target.name === 'due_date'){
    
    if(isNot_a_PastDay(date.value)){
      
      setUserKey(target)
    }else{
      alert('Fecha invalida')
      date.value = "";
    }
    
  }else{
    setUserKey(target)
  }
}