import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { createBilling, getDocumentTypes, getSalesPoints, getVoucherTypes } from '../../api/afip';
import { documentDigitLimits, getDocumentTypeByVoucher } from '../../helpers/utils';
import Layout from '../../layout';
import WidthLimit from '../../layout/widthLimit';
import { cuteDateUS } from '../../utils/parseDate/formatDisplay';
import { getTodaysDate } from '../../utils/parseDate/getValues';
import InputDateModal from '../../widgets/inputDateModal';
import InputModal from '../../widgets/inputModal';
import InputSelect from '../../widgets/inputSelect';
import Redirection from '../Router/redirection';
import ErrorBillingNotification from './ErrorBillingNotification';

const NewBilling = ({ plans }) => {

  const [loading, setLoading] = useState(false)
  const [isError, setError] = useState(false)

  const [availableOptions, setAvailableOptions] = useState({
    documentTypes: [
      {
        "id": 0,
        "label": ""
      },
    ],
    voucherTypes: [
      {
        "id": 0,
        "label": ""
      },
    ],
    salesPoints: [],
  })

  // Documento seleccionado debido al tipoDeComprobante
  // Factura A -> CUIT
  // Factura B -> DNI
  const [document, setDocument] = useState({
    number: "",
    type: {
      id: 0,
      label: "----",
    },
    limits: {
      minLength: 0,
      maxLength: 0,
    },
  })

  const $form = useRef(null)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)

        const documentTypes = await getDocumentTypes()
        console.log("Tipos de documento", documentTypes)

        const voucherTypes = await getVoucherTypes()
        console.log("Tipos de comprobantes", voucherTypes)

        const salesPoints = await getSalesPoints()
        console.log("Tipos de puntos de venta", salesPoints)

        const DEFAULT_DOCUMENT_NAME = "CUIT".toLocaleLowerCase()

        const documentType = getDocumentTypeByVoucher(DEFAULT_DOCUMENT_NAME, documentTypes)
        const documentLimits = documentDigitLimits[DEFAULT_DOCUMENT_NAME]

        setDocument({
          ...document,
          type: documentType,
          limits: documentLimits
        })

        setAvailableOptions({ documentTypes, salesPoints, voucherTypes })
        setLoading(false)

      } catch (error) {
        console.log(error)
        setError(true)
        setLoading(false)
      }
    })()
  }, [])

  const setSelectFormatValues = (items) => (
    items.map(({ id, label }) => ({ label, value: id }))
  )

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const form = new FormData($form.current)
      const formValues = Object.fromEntries(form.entries())

      const { fechaServicioDesde, fechaServicioHasta, observaciones, email } = formValues
      const { planID, numeroPuntoVenta, codigoTipoComprobante } = formValues

      const selectedPlan = plans.find(item => item.id === planID)

      if (
        fechaServicioDesde < fechaServicioHasta &&
        fechaServicioDesde !== fechaServicioHasta
      ) {

        const body = {
          email,
          observaciones,
          
          codigoConcepto: 2, // siempre será 2, que corresponde a servicios.
          codigoTipoDocumento: parseInt(document.type.id),
          codigoTipoComprobante: parseInt(codigoTipoComprobante),
          
          numeroDocumento: parseInt(document.number),
          numeroPuntoVenta: parseInt(numeroPuntoVenta),
          
          fechaServicioDesde: cuteDateUS(fechaServicioDesde),
          fechaServicioHasta: cuteDateUS(fechaServicioHasta),
          
          items: [{
            codigo: "ADM001",
            descripcion: selectedPlan.attributes.name,
            precioUnitario: selectedPlan.attributes.price,
          }]
        }

        console.log("formValues:", formValues)
        console.log("body:", body)
        
        try {

          const response = await createBilling(body)
          console.log("response:", response)

          alert("El Comprobante se ha creado con éxito!")

          $form.current.reset()
          $form.current.classList.remove("was-validated")

        } catch (error) {
          console.log(error)
          alert("Ha ocurrido un error con la creación del comprobante")
        }

      } else {
        alert(
          "- Las fecha de inicio no puede ser mayor a la de finalización\n" +
          "- Las fecha de inicio y finalización no pueden ser iguales"
        )
      }

      setLoading(false)

    } catch (error) {
      console.log(error)
      alert("Ha ocurrido un error creando el comprobante, intente de nuevo!")
      setLoading(false)
    }
  }

  const handleChange = ({ target }) => {

    // "FACTURA A" -> "CUIT"
    // "FACTURA B" -> "DNI"

    const { name, value } = target

    if (name === "codigoTipoComprobante") {
      const FACTURA_A = voucherTypes.find(({ label }) =>
        label.toLocaleLowerCase() === "Factura A".toLocaleLowerCase()
      )

      const ID_COMPARISON = parseInt(value) === FACTURA_A.id
      const documentName = (ID_COMPARISON ? "CUIT" : "DNI").toLocaleLowerCase()

      const documentLimits = documentDigitLimits[documentName]

      setDocument({
        number: document.number.substring(0, documentLimits.maxLength),
        type: getDocumentTypeByVoucher(documentName, documentTypes),
        limits: documentLimits,
      })
    } else if (name === "numeroDocumento") {
      setDocument({
        ...document, number: value,
      })
    }
  }

  const { documentTypes, salesPoints, voucherTypes } = availableOptions

  const formattedPlans = plans.map(({ id, attributes: { name } }) => ({ label: name, value: id }))

  return (
    <Redirection>
      <Layout>
        <WidthLimit>
          <Card className="width-limit__container">
            <CardBody className="plans">
              {
                isError ?
                  <ErrorBillingNotification
                    message="Ha ocurrido un error con la carga de los elementos del formulario."
                  />
                  :
                  <section className="NewBilling py-3">
                    <div className="main-container">

                      <h1 className="card_title">Nueva Facturación</h1>

                      <form
                        ref={$form}
                        className="needs-validation"
                        onSubmit={handleSubmit}
                        onInvalid={() => {
                          // alert('No se han llenado todos los campos')
                          $form.current.classList.add('was-validated')
                        }}
                      >
                        <InputModal
                          id="email"
                          type="email"
                          title="Correo electrónico"
                          className="form-control"
                          placeholder="vincor@gmail.com"
                          required={true}
                        />

                        <InputSelect
                          id="planID"
                          title="Plan"
                          className="form-control"
                          options={formattedPlans}
                        />

                        <InputSelect
                          id="numeroPuntoVenta"
                          title="Tipo de punto de venta"
                          className="form-control"
                          options={setSelectFormatValues(salesPoints)}
                        />

                        <div className="columns-2">
                          <InputSelect
                            id="codigoTipoComprobante"
                            title="Tipo de Comprobante"
                            className="form-control w-100"
                            handleChange={handleChange}
                            options={setSelectFormatValues(voucherTypes)}
                          />

                          <InputModal
                            type="text"
                            id="numeroDocumento"
                            title={document.type.label}
                            pattern="^[0-9-+\s()]*$"
                            className="form-control"
                            value={document.number}
                            handleChange={handleChange}
                            minLength={document.limits.minLength}
                            maxLength={document.limits.maxLength}
                            required={true}
                          />
                        </div>

                        <div className="columns-2">
                          <InputDateModal
                            type="date"
                            id="fechaServicioDesde"
                            title="Fecha desde..."
                            className="form-control"
                          />

                          <InputDateModal
                            type="date"
                            id="fechaServicioHasta"
                            title="Fecha hasta..."
                            className="form-control"
                          />
                        </div>

                        <label htmlFor="observaciones" className="form-group">
                          <span>Observaciones</span>
                          <textarea
                            id="observaciones"
                            name="observaciones"
                            className="form-control"
                            placeholder="..."
                          />
                        </label>

                        <Button
                          type="submit"
                          color="success"
                          disabled={loading}
                          className="btn font-weight-bold"
                        >
                          {loading ?
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                              <span>Cargando...</span>
                            </div>
                            :
                            'Enviar'
                          }
                        </Button>

                      </form>
                    </div>
                  </section>
              }

            </CardBody>
          </Card>
        </WidthLimit>
      </Layout>
    </Redirection>
  )
}

const mapStateToProps = ({ Login: { plans } }) => {
  return {
    plans
  }
}

export default connect(mapStateToProps)(NewBilling);
