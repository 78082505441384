import React from 'react'
import Button from '../../../widgets/button';
import Trash from '../../../widgets/icons/trash';

const GuestListItem = ({email, handleDelete}) => {
  return (
    <li className="py-2 px-2 pl-3">
      <span>{email}</span>
      <Button
        color="danger"
        className="rounded-pill"
        handleClick={()=> handleDelete(email)}
      >
        <Trash/>
      </Button>
    </li>
  )
}

export default GuestListItem;
