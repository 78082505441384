import React from 'react'

const Modal = ({setRef, handleSubmit, handleInvalidSubmit, title, children}) =>(
  <div className="modal-container p-2 p-sm-4">
    <form 
      ref={setRef}
      onSubmit={handleSubmit}
      onInvalid={handleInvalidSubmit}
      className="needs-validation modal card p-3 p-sm-4"
    >
      <div className="modal__header pb-3 pb-sm-4">
        <span>{title}</span>
      </div>
      <div className="modal__body">
        {
          children
        }
      </div>
    </form>
  </div>
)

export default Modal;