import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Card, CardBody } from 'reactstrap'
import Alert from 'reactstrap/lib/Alert'
import { getUsers } from '../../helpers/getDashboardData'
import { updateUser } from '../../helpers/sendData'
import Layout from '../../layout'
import { getStartOfDay } from '../../utils/parseDate/getValues'
import Button from '../../widgets/button'
import { objectToSend } from '../../widgets/utils/objectToSend'
import Redirection from '../Router/redirection'
import UserModal from './UserModal2'
import UserTable from './UserTable'

const NewUsers = () => {

  const [loading, setLoading] = useState(false)

  const [users, setUsers] = useState([])
  const [searchedUsers, setSearchedUsers] = useState([])

  const [user, setUser] = useState({
    id: "",
    attributes: {
      birth_date: "", 
      due_date: "",
    }
  })

  const [visibleModal, setVisibleModal] = useState(false)
  const [alertModal, setAlert] = useState({
    visibleAlert: false,
    confirmMsg: '¿Estás seguro de que quieres activar a este usuario?',
  })

  const [notification, setNotification] = useState({
    state: false,
    color: "",
    message: "",
  })

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers()
        console.log("users: ", users)
        setUsers(users)
      } catch (error) {
        console.log(error)
        alert("Ha ocurrido un error con la carga de los usuarios")
      }
    })()
  }, [])

  const handleUpdateUsers = async () => {
    setLoading(true)

    try {
      const updatedUsers = await getUsers()
      setUsers(updatedUsers)
      alert('Se han actualizado los consteladores')
      setLoading(false)

    } catch (error) {
      console.log(error)
      console.log(error.message)
      setLoading(false)
    }
  }

  const resetNotification = () => {
    setNotification({ state: false, color: "", message: "" })
  }

  const handleUser = {
    edit: (user) => {
      setUser(user)
      setVisibleModal(true)
      resetNotification()
    },
    desactivate: (user) => {
      setUser(user)
      setAlert({
        visibleAlert: true,
        confirmMsg: `¿Estás seguro de que quieres ${!user.attributes.is_active ? 'activar' : 'desactivar'}  a este usuario?`
      })
      resetNotification()
    }
  }

  const handleAlert = {
    confirm: async () => {

      setLoading(true)

      const { created_at, updated_at, payment_id, ...rest } = user.attributes;

      const attributes = {
        ...rest,
        is_active: !user.attributes.is_active,
      } 
      debugger
      const body = objectToSend('user', attributes)

      try {
        const updatedUser = await updateUser(user.id, body)
        const updatedUsers = users.map(item => (item.id === user.id) ? updatedUser : item)

        setUsers(updatedUsers)

        setNotification({
          state: true,
          color: "success",
          message: `El usuario se ha ${user.attributes.is_active ? "activado" : "desactivado"} correctamente`
        })
        setLoading(false)

      } catch (error) {
        console.log(error)
        setNotification({
          state: true,
          color: "danger",
          message: `Ha ocurrido un error con la ${!user.attributes.is_active ? 'activación' : 'desactivación'} del usuario`,
        })
        setLoading(false)
      }

      handleAlert.close()
    },
    close: () => {
      setAlert({
        ...alertModal,
        visibleAlert: false
      })
    },
  }

  const { visibleAlert, confirmMsg } = alertModal

  return (
    <Redirection>
      <Layout>
        {
          visibleAlert &&
          <SweetAlert
            title={confirmMsg}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleAlert.confirm}
            onCancel={handleAlert.close}
          >
            Puede desahacerse en cualquier momento
          </SweetAlert>
        }

        {
          visibleModal &&
          <UserModal
            selectedUser={user}
            setUsers={setUsers}
            setLoading={setLoading}
            setNotification={setNotification}
            resetNotification={resetNotification}
            handleCloseModal={() => setVisibleModal(false)}
          />
        }

        <div className="users-container">
          <Card>
            <CardBody>

              <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                <h4 className="card_title pb-3 m-0">Consteladores</h4>
                <Button
                  type="submit"
                  text="Actualizar"
                  loading={loading}
                  color="success mb-3"
                  handleClick={handleUpdateUsers}
                />
              </div>

              <UserTable
                users={users}
                loading={loading}
                handleUser={handleUser}
                searchedUsers={searchedUsers}
                setSearchedUsers={setSearchedUsers}
              />

              {
                notification.state &&
                <Alert
                  className="mt-4"
                  color={notification.color}
                  onClick={resetNotification}
                >
                  {notification.message}
                </Alert>
              }

            </CardBody>
          </Card>
        </div>
      </Layout>
    </Redirection>
  )
}

export default NewUsers
