export const handleError = (error, setLoading) =>{
//debugger
  if(error.response.data){
    const {errors} = error.response.data
    let duplicate = false
    
    if(errors[0].detail){
      duplicate = errors[0].detail.includes('Duplicate entry')
      
    }else if(errors[0].title){
      duplicate = errors[0].title.includes('already exists')
      
    }
    
    if(!duplicate){
      setLoading(false, 'error')
    }else{
      setLoading(false, 'error', 'Ha ingresado un correo ya existente')
    }
    
  }else{
    setLoading(false, 'error')
  }
}