import React from 'react'
import Button from './button'
import PowerOff from './icons/powerOff';
import Trash from './icons/trash';

const OptionButton = ({type, user, is_admin, handleOptionAction}) =>(
  <>
    {
      is_admin &&
      <>
        {
          type === 'users'?
            <td className="" onClick={handleOptionAction}>
              <Button color={user.attributes.is_active? "success" : "dark"}>
                <PowerOff color='#fff' />
              </Button>
            </td>
          :
            <td className="" onClick={handleOptionAction}>
              <Button color="danger">
                <Trash color='#fff' />
              </Button>
            </td>
        }
      </>
    }
  </>
)

export default OptionButton;