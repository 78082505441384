import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Redirection = props =>(
  <>
    {
      !props.is_admin ? 
        <Redirect to="invitados"/> 
      : 
        props.children
    }
  </>
)
const mapStateToProps = ({Login:{ user:{ attributes:{ is_admin }}}}) => ({
  is_admin: is_admin 
})
export default connect(mapStateToProps)(Redirection)