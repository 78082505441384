import React, { Fragment, useEffect, useState } from 'react'
import Layout from '../../layout';
import { Card, CardBody } from 'reactstrap';
import WidthLimit from '../../layout/widthLimit';
import Button from '../../widgets/button';
import { useParams } from 'react-router-dom';
import { getBillings, getDocumentTypes, getVoucherTypes } from '../../api/afip';
import getUrlParameter from '../../helpers/getUrlParameter.js'
import { cuteDate, getCuteFormat } from '../../utils/parseDate/formatDisplay';
import { getCurrencyFormat } from '../../utils';
import Redirection from '../Router/redirection';
import html2pdf from "html2pdf.js"
import PDFTemplate from './PDFTemplate';

const BillingDetails = ({ history }) => {

  const [voucher, setVoucher] = useState({
    codigoTipoComprobante: 0,
    numeroPuntoVenta: "0",
    numeroComprobante: "0",
    fechaEmision: "2022-11-28T00:00:00.000Z",
    codigoTipoAutorizacion: "E",
    codigoAutorizacion: 0,
    fechaVencimiento: "2022-12-08T00:00:00.000Z",
    codigoTipoDocumento: 0,
    numeroDocumento: 0,
    importeGravado: "0",
    importeNoGravado: "0",
    importeExento: "0",
    importeSubtotal: "0",
    importeTotal: "0",
    codigoMoneda: "PES",
    cotizacionMoneda: 1,
    codigoConcepto: 2,
    fechaServicioDesde: "2022-11-03T00:00:00.000Z",
    fechaServicioHasta: "2022-11-28T00:00:00.000Z",
    fechaVencimientoPago: "2022-11-28T00:00:00.000Z",
    arrayItems: {
      item: [
        {
          unidadesMtx: 0,
          codigoMtx: "0123456779914",
          codigo: "P0001",
          descripcion: "Descripción del producto P000",
          cantidad: "0",
          codigoUnidadMedida: 0,
          precioUnitario: "0",
          codigoCondicionIVA: 0,
          importeItem: "0"
        }
      ]
    },
    arraySubtotalesIVA: {
      subtotalIVA: [
        {
          codigo: 0,
          importe: "0"
        }
      ]
    }
  })

  const [loading, setLoading] = useState(false)

  const [infoTypes, setInfoTypes] = useState({
    voucherTypes: [
      {
        id: 0,
        label: "",
        description: ""
      },
    ],
    documentTypes: [
      {
        id: 0,
        label: "",
      },
    ],
  })

  const { nroComprobante } = useParams()

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)

        const ptoVenta = getUrlParameter("ptoVenta")
        const tipoComprobante = getUrlParameter("tipoComprobante")

        if (ptoVenta && tipoComprobante) {
          const params = { nroComprobante, ptoVenta, tipoComprobante }

          const voucher = await getBillings(params)
          console.log("voucher:", voucher)

          const documentTypes = await getDocumentTypes()
          console.log("documentTypes:", documentTypes)

          const voucherTypes = await getVoucherTypes()
          console.log("voucherTypes:", voucherTypes)

          if (voucher === "") {
            //Si se manda un nroComprobante que no existe en la BD, entonces este te devuelve un 200 y string vacío
            history.push("/facturaciones")
          }

          setVoucher(voucher)
          setInfoTypes({ documentTypes, voucherTypes })

        } else {
          history.push("/facturaciones")
        }

        setLoading(false)

      } catch (error) {
        console.log(error)
        history.push("/facturaciones")
      }
    })()
  }, [])

  const createPDF = () => {
    console.log("Se empieza a crear")
    html2pdf()
      .set({
        margin: 0.5,
        filename: "comprobante.pdf",
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          scale: 3,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait"
        }
      })
      .from(document.getElementById("voucher"))
      // .save()
      .output("dataurlnewwindow")
      .then(() => console.log("Guardado!!!"))
      .catch(error => console.log(error))
      .finally(() => console.log("Finally!!!"))
  }

  const getType = (infoItems, idType) => {
    const type = infoItems.find(item => item.id === idType)
    return type ? type : {};
  }

  const { documentTypes, voucherTypes } = infoTypes

  const { arrayItems, importeTotal, codigoTipoDocumento } = voucher
  const { fechaEmision, numeroComprobante, numeroDocumento, codigoTipoComprobante } = voucher

  let VOUCHER_TYPE = ""
  let ID_DOCUMENT_TYPE = ""

  if (voucher.numeroComprobante !== "0") {
    VOUCHER_TYPE = getType(voucherTypes, codigoTipoComprobante)
    ID_DOCUMENT_TYPE = getType(documentTypes, codigoTipoDocumento)
  }

  return (
    <Redirection>
      <Layout>
        <WidthLimit>
          <Card className="width-limit__container">
            <CardBody className="BillingDetails">

              {
                (voucher.numeroComprobante !== "0") ?
                  <div className="main_container">
                    <section>
                      {/* <h2 className="">Detalles - Receptor (Nombre y Apellido) - {cuteDate(fechaEmision)}</h2> */}
                      <h2 className="">Detalles - {cuteDate(fechaEmision)}</h2>

                      <ul className="Details pt-4">
                        <li>Nro. Comprobante: #{numeroComprobante}</li>
                        <li>Fecha de emisión: {getCuteFormat(fechaEmision)}</li>
                        <li>Tipo: {VOUCHER_TYPE.label} - {VOUCHER_TYPE.description}</li>
                        <li>{ID_DOCUMENT_TYPE.label}: {numeroDocumento}</li>
                        <br />
                        {/* <li>Apellido y Nombre / Razón Social: -</li> */}
                        {/* <li>Domicilio: -</li> */}
                        <li>Productos / Servicios:</li>
                      </ul>

                      <ul className="BillList">

                        {/* Titulos */}
                        <Fragment>
                          <li className="title_item">Product/Servicio</li>
                          <li className="title_item">Cantidad</li>
                          <li className="title_item">Precio Unitario</li>
                          <li className="title_item">SubTotal</li>
                        </Fragment>

                        {/* Items */}
                        {
                          arrayItems.item.map(({ descripcion, cantidad, precioUnitario, importeItem }, i) => (
                            <Fragment key={i}>
                              <li>{descripcion}</li>
                              <li>{parseFloat(cantidad).toFixed(2)}</li>
                              <li>{getCurrencyFormat(precioUnitario)}</li>
                              <li>{getCurrencyFormat(importeItem)}</li>
                            </Fragment>
                          ))
                        }

                      </ul>

                      <span className="font-weight-bold">Importe Total: {getCurrencyFormat(importeTotal)}</span>

                    </section>

                    <aside>
                      <Button
                        text="Volver a facturaciones"
                        color="light font-weight-bold py-2 rounded-pill"
                        className="flex-fill"
                        handleClick={() => history.push("/facturaciones")}
                      />

                      <Button
                        text="Descargar Factura"
                        color="success py-2 rounded-pill"
                        className="flex-fill"
                        handleClick={createPDF}
                      />
                      
                      <PDFTemplate
                        id="voucher"
                        voucher={voucher}
                        voucherTypes={voucherTypes}
                        documentTypes={documentTypes}
                      />
                      
                      {/* <img src="https://es.habcdn.com/photos/business/medium/codigo-qr-de-la-empresa-guardarlo-en-tu-movil_385096.jpg" alt="código QR" /> */}
                    </aside>
                  </div>
                  :
                  <span className="LoadingMessage">
                    Cargando...
                  </span>
              }

            </CardBody>
          </Card>
        </WidthLimit>
      </Layout>
    </Redirection>
  )
}

export default BillingDetails;