export const initialLogin = {
  loginError: null,
  message: null,
  loading: false,
  is_logged: false,
  known_error: false,
  messageErrorLogin: '',
  user:{
    attributes:{
      is_admin: false
    }
  },
  plans:[],
  orderedPlans:[],
  changingOrder: false,
  total_users: {
    rows:[],
    columns:[],
  },
  total_guests: [],
}