export const setState_At_LocalStorage = (state, dataToSet) => {
  localStorage.setItem(state, JSON.stringify(dataToSet))
}

export const getState_from_LocalStorage = state => {
  return JSON.parse(localStorage.getItem(state))
}

export const setDataToLogin = (email, password) => {
  return {
    data: {
      attributes: { email, password }
    }
  }
}

export const validateIsDate = date => {
  // debugger
  date = date.toString()
  return date.substr(4, 5).includes('-') && date.substr(7, 8).includes('-')
}

export const findAndReplace = (itemToReplace, array) => {

  const newArray = array.map(item => {
    if (item.id === itemToReplace.id) {
      return itemToReplace;
    }
    return item;
  })

  return newArray;
}

export const sortByEarliestDate = (workshops) => {
  const sortedWorkshops = workshops.sort((workshop1, workshop2) => {

    const { start: start1 } = workshop1.attributes;
    const { start: start2 } = workshop2.attributes;

  //debugger

    if (start1 > start2) {
      return 1;
    }
    if (start1 < start2) {
      return -1;
    }
    // start1 must be equal to start2
    return 0;
  }).reverse();

  return sortedWorkshops;
}

export const getDocumentTypeByVoucher = (documentByVoucher, documentTypes) => {
  return documentTypes.find(item => {
    const label = item.label.toLowerCase()
    return label.includes(documentByVoucher.toLowerCase())
  })
}

export const documentDigitLimits = {
  dni: {
    minLength: 7,
    maxLength: 8,
  },
  cuit: {
    minLength: 10,
    maxLength: 11,
  },
};