import React from 'react';
import { Card, CardBody } from 'reactstrap';
import WidthLimit from '../../layout/widthLimit';

const TyCInter = () => {
  return (
    <WidthLimit>
      <Card className="width-limit__container mt-md-5 pt-4 px-md-5">
        <CardBody>
          <h1 className="card_title font-weight-bold" style={{ color: '#ec7967' }}>Términos y Condiciones de Uso y Políticas de Privacidad para el uso del Sitio de Vincor Online</h1>

          <section className="text-justify">
            <p>Bienvenido al Sitio de Vincor Online (en adelante el “Sitio” o “Sitio Web”) el que pertenece a Damián Ariel Romero en su carácter de titular de Vincor Online (en adelante <strong>“Vincor Online”</strong>) con domicilio en Gallo 1225 piso 5, departamento “B” de la Ciudad Autonoma de Buenos Aires. Vincor Online es titular de la Tecnología que a continuación se lista (en adelante la <strong>“Tecnología”</strong> o la <strong>“Tecnología de Vincor Online”</strong>): </p>

            <ul className="tyc-list">
              <li>1.- La plataforma virtual Vincor Online (en adelante la <strong>"Plataforma”</strong>) a través de la cual se accede a los productos y servicios de Vincor Online disponibles para Usuarios registrados.</li>
              <li>2.- Las Salas Virtuales de Vincor Online para la realización de sesiones de constelaciones remotas (en adelante las <strong>“Salas Virtuales”</strong> a las que acceden los Usuarios registrados tanto Anfitriones como Participantes y/o consultante según se definen en el presente. Las Salas Virtuales a su vez, contienen diversos productos y servicios tecnológicos asociados.</li>
              <li>3.- El Sitio Web de Vincor Online que brinda información, y acceso a diversos servicios, y permite el acceso a la Plataforma y a las Salas (en adelante el <strong>“Sitio”</strong>).</li>
              <li>4.- La <strong>Aplicación Móvil de Vincor Online</strong> que permite el acceso a la Plataforma, a las Salas Virtuales, al Sitio en su versión móvil (en adelante la <strong>“Aplicación Móvil”</strong>).</li>
              <li>5.- Todo otro producto y/o servicio disponible o accesible a través de la Plataforma, las Salas Virtuales y/o el Sitio.</li>
            </ul>

            <p>Vincor Online pone a disposición de sus usuarios (en adelante referidos como los <strong>“Usuarios”</strong> o <strong>“Usuario”</strong>) los presentes términos y condiciones de uso (<strong>“Términos y Condiciones de Uso”</strong>) que describen los derechos y responsabilidades de los Usuarios de Vincor Online con relación al Sitio Web de Vincor Online. </p>
            <p>Al acceder a, o al utilizar y/o al rellenar cualquier formulario disponible en el Sitio, el Usuario reconoce que ha leído y comprendido los siguientes Términos y Condiciones de Uso y sus respectivas Políticas de Privacidad, las que forman parte integral del presente documento, así como todo otro documento complementario a los mismos, y acepta que quedará vinculado por ellos y que, asimismo, cumplirá con todas las leyes y regulaciones aplicables con respecto al uso del Sitio.</p>
            <p><strong>SI USTED NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES DE USO, NO ACCEDA NI UTILICE NI RELLENE NINGUNO DE LOS FORMULARIOS DEL SITIO DE NINGUNA FORMA. </strong></p>
            <p>Los Términos y Condiciones de Uso y las Políticas de Privacidad de Vincor Online pueden ser revisados y actualizados por Vincor Online frecuentemente, con o sin previo aviso. El acceso y uso del Sitio de cualquier forma, se encuentra sujeto, asimismo, a todos los avisos, instrucciones, políticas de privacidad, términos particulares, términos y condiciones complementarias presentes o que en el futuro pueda publicar Vincor Online en diversas plataformas digitales, ya sea en su sitio web y/o en su Plataforma, y/o en sus Productos y/o Servicios que contrate en su caso el Usuario, las cuales serán comunicadas a los Usuarios. Puede revisar y/o descargar la versión más actualizada y vigente de los Términos y Condiciones de Uso en cualquier momento. </p>
            <p>Se informa a los Usuarios que la adquisición y utilización de los Servicios ofrecidos por Vincor Online en el Sitio puede encontrarse sujeta a términos y condiciones de contratación y a Términos y Condiciones particulares que, según los casos, sustituyen, complementan y/o modifican estos Términos y Condiciones de Uso y sus Políticas de Privacidad (<strong>“PP”</strong>). </p>
            <p>Tanto los Términos y Condiciones de Uso como las PP del Sitio pueden ser modificados en forma periódica, lo que informaremos a nuestros Usuarios. La continuación en el uso del Sitio de Vincor Online implica su aceptación expresa de las actualizaciones y/o modificaciones tanto de Términos y Condiciones de Uso como de las PP. </p>
          </section>

          <section className="text-justify pb-3">
            <h2 className="h4 pb-3">1. Acceso al y Uso del Sitio </h2>
            <h4 className="h5 pb-3">1.1 Compromiso del Usuario. </h4>
            <p>Sin perjuicio de los términos a los que se encuentre sujeto cada producto y/o servicio ofrecido por Vincor Online, cada Usuario será responsable por sí y hará responsable a la entidad y/o persona jurídica para la cual trabaja o presta servicios -en caso de que su uso de Sitio o de los Productos y/o Servicios de Vincor Online sea con relación a dicha persona jurídica- , de todas las actividades que ocurran durante la navegación del Usuario en el Sitio. Vincor Online no se responsabiliza por ninguna pérdida o daño generado como consecuencia de que Usted no haya proporcionado a Vincor Online información exacta, veraz o íntegra. </p>
            <p>Usted entiende y acepta que Usted y la entidad y/o persona jurídica para la cual trabaja o presta servicios, son los únicos responsables del contenido generado por Usted y de la información ingresada por Usted en nuestros entornos digitales, tales como los formularios disponibles en nuestro Sitio. En este sentido, Usted libera a Vincor Online de cualquier reclamo suyo o de terceros relacionado con la información por Usted ingresada. </p>
            <p>En particular, el Usuario se compromete a: </p>
            <ul className="ml-5 tyc-list list-raya">
              <li className="pt-2"> -.  No usar el Sitio ni los recursos disponibles en el Sitio, para fines ilícitos o no autorizados por Vincor Online. </li>
              <li className="pt-2"> -.  No utilizar cualquier tecnología, proceso o método de acceso que permita recuperar, indexar y/o extraer cualquier información del Sitio, de forma no autorizada</li>
              <li className="pt-2"> -.  No interferir ni interrumpir el funcionamiento del Sitio, o de los servidores o redes que alojan al Sitio, ni de los productos y/o servicios de Vincor Online, ni incumplir las leyes o reglamentos o requisitos, procedimientos, políticas o regulaciones de dichos servidores o redes</li>
              <li className="pt-2"> -.  No crear, cargar, validar, transmitir, distribuir, mostrar ni hacer disponible a través de o en conexión con Vincor Online cualquier contenido, texto o información que pueda ser considerado abusivo, amenazante, obsceno, difamatorio, calumnioso, o discriminatorio por razones raciales, sexuales, etarias o de género, o por cuestiones políticas, religiosas o información de cualquier índole que pudiera ser considerada desagradable, dañina, inapropiada u ofensiva para terceros. </li>
              <li className="pt-2"> -.  No crear, cargar, validar, transmitir, distribuir, mostrar ni hacer disponible a través de o en conexión con Vincor Online cualquier contenido, incluyendo contenido generado por el Usuario, que pueda atentar contra los derechos de terceros, incluyendo los derechos de propiedad intelectual y los derechos de privacidad, o a la intimidad, o que puede contener cualquier contenido ilegal. </li>
              <li className="pt-2"> -.  No hacerse pasar por otra persona o entidad ni proporcionar información falsa en los formularios que se rellenen en el Sitio, ya sea directa o indirectamente. No declarar falsamente o tergiversar su filiación con cualquier persona o afiliación a entidad, ni expresar o publicar información falsa o inexacta sobre Usted, terceros, o sobre Vincor Online. </li>
              <li className="pt-2"> -.  No llevar a cabo acciones que impongan o puedan imponer, a nuestro criterio, una carga elevada en nuestras infraestructuras de plataformas digitales.</li>
              <li className="pt-2"> -.  No evadir las medidas de seguridad físicas o informáticas que Vincor Online disponga a los fines de evitar o restringir el acceso al Sitio. </li>
              <li className="pt-2"> -.  No copiar, modificar, alterar, adaptar, poner a disposición, traducir, realizar ingeniería inversa, descompilar ni desensamblar cualquier parte del Sitio, de su código fuente, ni crear un browser, marco (framing), sin autorización expresa y por escrito de Vincor Online. </li>
              <li className="pt-2"> -.  No reproducir, duplicar, copiar, vender, revender, distribuir o explotar, total o parcialmente el Sitio, ni las herramientas o contenidos de Vincor Online sin el consentimiento expreso y por escrito de Vincor Online. </li>
              <li className="pt-2"> -.  No violar ni incumplir las políticas y/o Términos y Condiciones de Uso, y/o términos particulares si los hubiera, u otros contratos con Vincor Online. </li>
              <li className="pt-2"> -.  Utilizar el Sitio de conformidad con los presentes. </li>
            </ul>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">2. Responsabilidad por el contenido del Sitio </h2>
            <h4 className="h5 pb-3">2.1 Responsabilidad por la información contenida en el Sitio:  </h4>
            <p>Vincor Online no se responsabiliza por los daños y perjuicios que pudiera ocasionar el uso de la información que se provee en el Sitio. Vincor Online no garantiza la veracidad o vigencia de esa información, ni que dicha información no haya sido alterada, luego de su inserción en el Sitio. En caso de que el Usuario se sienta agraviado o perjudicado por esa información, deberá dirigir su acción exclusivamente contra quien resulte proveedor de la misma. Vincor Online tampoco se responsabiliza por la inviolabilidad de la transmisión de los datos cursados por las redes públicas de telecomunicaciones o por cualquier otro medio telemático. </p>

            <h4 className="h5 pb-3">2.2 Responsabilidad por enlaces insertos en el Sitio   </h4>
            <p>Vincor Online puede facilitar enlaces, websites o servicios de terceros a través del Sitio. El Usuario entiende y acepta que Vincor Online no controla y no es responsable de los sitios de terceros cuyos enlaces se encuentren insertos en el Sitio, ni de sus productos y servicios relacionados. El Usuario acepta y reconoce que Vincor Online no controla, ni avala, ni supervisa, ni asume responsabilidad alguna por la calidad, disponibilidad, seguridad, características ni demás elementos de la información, contenidos, anuncios, servicios, productos, archivos o materiales disponibles en los sitios de terceros. El Usuario acepta y reconoce que Vincor Online no será responsable ni estará obligado directa o indirectamente a resarcir y/o responder por los daños o pérdidas que pudiera sufrir el Usuario en relación con los sitios de terceros. Si el Usuario decide acceder a cualquiera de los sitios independientes de terceros cuyos enlaces se encuentren insertos en el Sitio, lo hará por su cuenta y riesgo. Vincor Online le recomienda al Usuario tomar la precaución debida al operar en los sitios web de terceros y a prestar especial atención a los términos y condiciones y políticas de privacidad de dichos terceros antes de realizar descargas, brindar sus datos personales o realizar una transacción con terceros. </p>
            
            <h4 className="h5 pb-3">2.3 Responsabilidad por Descargas    </h4>
            <p>El Usuario es el único responsable de verificar la inexistencia de todo tipo de virus, malware o archivo corrupto en los programas y materiales que el Usuario descargue del Sitio. En ningún caso Vincor Online será responsable por los daños causados por elementos destructivos que pudieran haber introducido terceros a los archivos descargables provistos por Vincor Online. Los archivos se suministran sin garantía expresa o implícita de ningún tipo, incluyendo las garantías de comerciabilidad, propiedad, ausencia de infracción de la propiedad intelectual o idoneidad para un determinado fin. En ningún caso se podrá considerar a Vincor Online responsable por los daños (incluidos a título informativo, pero no limitativo, los de lucro cesante, interrupción de las actividades comerciales o pérdida de información) derivados del uso o la imposibilidad de uso de los archivos, incluso aunque Vincor Online haya recibido aviso de la posibilidad de este tipo de daños. Vincor Online no garantiza la exactitud de la información ni que ésta sea completa, incluidos el texto, los gráficos, los vínculos y demás elementos incluidos en los archivos. Vincor Online puede modificar en cualquier momento y sin previo aviso los archivos que se describen, pero no se compromete a actualizarlos. </p>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">3. Titularidad del Sitio </h2>
            <h4 className="h5 pb-3">3.1 Propiedad de Vincor Online   </h4>
            <p>El Sitio, sus contenidos y archivos descargables, la Plataforma, los productos y servicios asociados a ella, las marcas y el logotipo de Vincor Online y todos los derechos de Propiedad Intelectual relacionados a las mismas, son propiedad y/o licencia de Vincor Online y están sujetas a derechos de autor y otros derechos de propiedad intelectual y/o industrial aplicables en virtud de las leyes aplicables y los convenios internacionales. </p>

            <h4 className="h5 pb-3">3.2 Propiedad intelectual de terceras personas  </h4>
            <p>Complementado lo establecido en el presente apartado, Vincor Online manifiesta que en el Sitio se podrá desplegar software, información y otros contenidos de titularidad de terceras personas siendo estas personas las autoras de las mismas y propietarias exclusivas de todo este material. Los nombres, marcas y/o logos que puedan exhibirse son propiedad de aquellas personas humanas y/o jurídicas que a su nombre los hubieran inscripto en los registros correspondientes. </p>

            <h4 className="h5 pb-3">3.3 Licencia de Uso  </h4>
            <p>Vincor Online concede al Usuario una licencia de uso intransferible, gratuita, no exclusiva y revocable para utilizar el Sitio y descargar los archivos que se pongan a su disposición; salvo que existan otros términos y condiciones que establezcan una licencia con distinto alcance. La retransmisión, modificación o publicación de cualquier contenido o material del Sitio se encuentra prohibida sin el previo consentimiento escrito de Vincor Online. </p>

          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">4. Actualizaciones del Sitio. </h2>
            <p>Vincor Online se reserva el derecho a su entera discreción de implementar nuevos elementos como parte o complemento del Sitio y cualquier tecnología de Vincor Online, incluyendo cambios que puedan afectar el modo previo de funcionamiento del Sitio. Los cambios pueden implicar una limitación a la naturaleza o tamaño del procesamiento a su disposición, y/o la cantidad de registros procesables y/u otros aspectos de las funcionalidades y herramientas disponibles. </p>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">5. Limitación de responsabilidad con relación al funcionamiento del Sitio  </h2>
            <p>El Sitio, incluyendo en adelante sus contenidos, archivos descargables, formularios de contacto y la información desplegada en el mismo, se ponen a disposición en el estado en que se encuentran (“as is”) y según disponibilidad, sin garantías de ningún tipo, ni expresas ni implícitas. </p>
            <p>La disponibilidad y funcionamiento del Sitio pueden depender de terceras partes tales como las redes de comunicación, software, hardware, servidores y los proveedores de servicios de los Usuarios. Vincor Online no garantiza ni asegura que el Sitio funcionará en todo momento y sin interrupción, o que esté libre de errores. Vincor Online tampoco será responsable por cualquier daño, perjuicio, lucro cesante, daño emergente o pérdida en él o los equipos de Usuario ni en los negocios del Usuario, ni en la persona del Usuario y/o sus autorizados y/o terceros originados por fallas en el sistema, la tecnología, el Sitio, los servidores o en Internet. </p>
            <p>Usted reconoce y acepta expresamente que el acceso y uso del Sitio se realiza bajo su propio riesgo. Vincor Online no garantiza que: </p>
            <ul className="tyc-list pl-4">
              <li>i.  El Sitio cumplirá con todos sus requisitos;</li>
              <li>ii. el acceso al Sitio, sea ininterrumpido, oportuno, seguro o libre de errores o riesgos; ni </li>
              <li>iii. que todos los errores en el Sitio serán corregidos o lo serán de forma oportuna, aun cuando Vincor Online haya sido notificado de los mismos. </li>
            </ul>
            <p>Vincor Online informa al Usuario que cualquier material descargado u obtenido a través del Sitio corre por su cuenta y riesgo y el Usuario será el único responsable de cualquier daño en sus equipos o por la pérdida de datos que resulte de la descarga y/o uso de dicho material y asimismo, que ningún consejo o información, oral o escrita, que el Usuario obtenga de parte de Vincor Online, o a través del Sitio, creará ninguna garantía diferente a las expresamente estipuladas en estos Términos y Condiciones de Uso. </p>
            <p>Consecuentemente, Vincor Online no será responsable por ninguna clase de daños ni pérdidas para el Usuario o terceros resultantes de: </p>
            <ul className="tyc-list pl-4">
              <li>i.  El uso o imposibilidad de usar el Sitio;</li>
              <li>ii. el costo de adquisición de bienes y servicios resultantes de cualquier bien, producto, dato, información o servicio adquirido u obtenido, o mensajes recibidos o transacciones realizadas a través de o desde el Sitio; y </li>
              <li>iii.  el acceso no autorizado a, o la pérdida, corrupción o alteración de sus transmisiones, contenido o datos.  </li>
            </ul>
          </section>


          <section className="text-justify">
            <h2 className="h4 pb-3">6. Seguridad y Confidencialidad </h2>
            <p>Proteger la seguridad y la confidencialidad de la información de los Usuarios es fundamental para Vincor Online, por lo que Vincor Online ha adoptado medidas de seguridad razonables a su alcance y disponibilidad tecnológica y económica para proteger la información y la privacidad del Usuario, e impedir el acceso no autorizado a los datos del Usuario o cualquier modificación, divulgación o destrucción no autorizada de los mismos. Las medidas de seguridad tomadas por Vincor Online cumplen con los estándares legales en materia de seguridad y confidencialidad contenidos en la normativa aplicable. </p>
            <p>Con el único límite de los recursos tecnológicos, humanos y económicos de los que dispone Vincor Online, Vincor Online contrata servicios adecuados y confiables para cuidar que la información que viaja desde su ordenador y/o cualquier otro dispositivo hacia los servidores que utilizamos y viceversa, sea transmitida de la manera más segura posible dentro de las posibilidades de monitoreo de Vincor Online.</p>
            <p>Vincor Online exige a sus proveedores los mismos estándares de confidencialidad.</p>
            <p>Vincor Online podrá revelar información personal de Usuario en situaciones tales que comprometan la seguridad de Vincor Online ante un intento de ruptura, o de peligro físico o amenaza en contra suya o de otros usuarios, nuestra propia protección y/o de terceros. </p>
            <p>No obstante, lo expresado precedentemente, aunque Vincor Online ponga su mayor esfuerzo en proteger su información y su privacidad, Vincor Online recuerda a sus Usuarios que el cuidado de su privacidad y seguridad en entornos digitales depende también de cada uno y de cómo  utiliza su propia información, así como la forma en que utiliza los dispositivos y/o programas y/o redes al realizar actividades y/o transacciones. En este sentido, Vincor Online sugiere siempre utilizar dispositivos y conexiones a Internet seguros al momento de ingresar sus datos personales y/u otros datos requeridos, de forma de proteger debidamente su seguridad. </p>
            <p>El Usuario asume que, a pesar de todos los esfuerzos y los recursos tecnológicos, económicos y humanos que utiliza Vincor Online para garantizar la seguridad y confidencialidad del Usuario, las medidas tomadas no resultan infalibles, por lo que Vincor Online no puede garantizar confidencialidad y seguridad absoluta, la que podría eventualmente ser afectada por acciones de terceros. El Usuario renuncia a cualquier reclamo contra Vincor Online ante tal eventualidad, entendiendo que Vincor Online ha realizado el máximo esfuerzo posible para evitarlo con los recursos tecnológicos, económicos y humanos con los que cuenta. En caso de que ocurra una eventualidad de este tipo, Vincor Online se compromete a notificar a los Usuarios afectados. </p>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">7. Indemnidad </h2>
            <p>Usted acepta que Vincor Online no será responsable en ningún caso de cualquier daño ni inconveniente ni afectación ni violación de derechos a través del Sitio, la Salas Virtuales, la Plataforma, y/o los contenidos ni herramientas que provee Vincor Online o las que proveen otras tecnologías digitales en él o a través del entorno digital de Vincor Online, cualquiera sea su entidad, que pueda sufrir el Usuario en su persona o en sus bienes, o en la persona o bienes de sus, dependientes, socios, contratistas, allegados, familiares, y/o cualquier tercero relacionado de cualquier forma con el Usuario. </p>
            <p>Usted acepta y reconoce que ni Vincor Online, ni sus subsidiarias, sociedades relacionadas, afiliados, funcionarios, agentes, empleados, directivos, dependientes, anunciantes, socios, distribuidores, contratistas, y/o subcontratistas serán responsable por daños y perjuicios, lucro cesante o cualquier otro daño emergente, directo o indirecto en virtud del uso que hagan los Usuarios del Sitio, sus contenidos ni herramientas que provee Vincor Online o las que proveen otras tecnologías digitales en él o a través del entorno digital de Vincor Online, y que Vincor Online ponga a disposición de sus Usuarios. </p>
            <p>Usted se compromete a indemnizar y exonerar de toda responsabilidad a Vincor Online, sus sociedades controladas y/o controlantes, y relacionadas y/o sus continuadoras, así como sus afiliados, funcionarios, agentes, empleados, directivos, dependientes, anunciantes, socios, distribuidores, contratistas y/o subcontratistas por y contra cualquier reclamo, responsabilidad, daños y perjuicios, lucro cesante o cualquier otro daño emergente, directo o indirecto, por pérdidas y gastos (incluyendo honorarios legales y profesionales) que surjan de o que de alguna forma estén relacionados con reclamos de terceros en relación con su uso del Sitio, cualquier violación de estos Términos y Condiciones de Uso o cualquier otra acción en conexión con el uso mencionado (incluyendo todas las acciones tomadas por su cuenta). En caso de reclamo, se le informará del mismo, al contacto que Usted haya provisto en su cuenta de Usuario siempre que ello fuera legal y fácticamente posible. Sin perjuicio de ello, Vincor Online hace saber que la falta de notificación respecto de cualquier reclamo no eliminará o reducirá su obligación de indemnización en virtud del presente. </p>
            <p>La Empresa se reserva el derecho de bloquear el acceso a la Tecnología de Vincor Online y/o limitar temporal o definitivamente al Usuario Anfitrión, suspender o interrumpir la cuenta del Anfitrión, sus contenidos asociados, por cualquier motivo, a su entera discreción, además de otros recursos que puedan estar disponibles bajo cualquier ley que resulte aplicable. En particular, la Empresa podrá tomar este tipo de acciones cuando considere que el Anfitrión ha violado los presentes Términos y Condiciones.</p>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">8. Política de Cookies </h2>
            <p>Vincor Online utilizará “cookies” con el fin de mejorar la experiencia del Usuario en el Sitio. En virtud de ello, a fin de hacer un uso completo de las características y servicios del Sitio, el Usuario deberá aceptar determinadas cookies. Al utilizar y navegar el Sitio, el usuario acepta el uso de cookies por parte de Vincor Online bajo las características descriptas a continuación. Las “cookies” son pequeños ficheros de texto que un sitio web coloca en la PC, teléfono o cualquier otro dispositivo utilizado para acceder al mismo, con información sobre la navegación del Usuario en dicho sitio. Las cookies son necesarias para facilitar la navegación y hacerla más amigable, y no dañan la computadora. </p>
            <p>Las “cookies” no contienen información que permiten identificar al Usuario y por sí mismas no permiten obtener información personal de contacto del Usuario, como su dirección de correo electrónico. Si el Usuario decide proporcionar información personal al Sitio, por ejemplo a través de formularios, dicha información puede estar vinculada a los datos almacenados en la cookie. La información que recopilamos consiste en información sobre el navegador que utiliza, el tipo de computadora, sus sistemas operativos, sus proveedores del servicio de Internet, las secciones del Sitio que Usted visita, los vínculos que Usted establece y otra información de similares características. Las “cookies” son una parte esencial de cómo el Sitio Web funciona. El objetivo principal de utilizar las “cookies” es a fin de mejorar la experiencia del Usuario en la navegación. Por ejemplo, las “cookies” ayudan a recordar las preferencias del usuario (idioma, país, etc.) durante la navegación y en futuras visitas. La información recogida en las “cookies” le permite a Vincor Online, por ejemplo, mejorar el Sitio, mediante estimaciones sobre números y patrones de uso, la adaptación del Sitio a los intereses individuales del Usuario, la aceleración de las búsquedas. Vincor Online no utiliza las “cookies” para almacenar información sensible de identificación personal como dirección, contraseñas, etc. Vincor Online tampoco utiliza las “cookies” para orientar publicidad a sus Usuarios en función de su navegación ni para otros fines publicitarios, ni propios ni de terceros. </p>
            <p>Vincor Online de ningún modo obliga al Usuario a mantener activas las “cookies”, pero le hace saber que algunas funciones y secciones del Sitio pueden quedar deshabilitadas o no funcionar correctamente. Para mayor información sobre cómo desactivar en su navegador la opción de aceptar Cookies, le aconsejamos se dirija a los sitios webs proveedores de los diferentes browsers que se utilizan en el mercado actual, sean estos Internet Explorer, Google Chrome, Mozilla Firefox, entre otros, pudiendo verificar en dichos sitios sus tutoriales de configuración. Es habitual que la configuración de las cookies se realice en el menú de “Preferencias” o “Herramientas”. </p>
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">9. Política de Privacidad  </h2>
            <p>Vincor Online tiene una estricta política de privacidad y protección de la información de los Usuarios. Esta sección tiene por objeto informar al Usuario acerca de la política de privacidad que sigue Vincor Online en lo que hace a la recolección y el tratamiento de sus Datos Personales (entendiéndose por <strong>“Datos Personales”</strong> a toda información referida a los Usuarios que permita su identificación de manera directa o indirecta) así como de las acciones con las que cuenta respecto del tratamiento y uso de sus datos que se recolecten en el Sitio.  </p>
            <p>Esta política puede ser modificada por múltiples razones, como ser el empleo de nuevas tecnologías de procesamiento de información, cambios que pudiera introducir Vincor Online en el Sitio, cambios en la normativa aplicable, etc. Las modificaciones a la Política de Privacidad entrarán en vigencia a partir de la publicación en el Sitio o de la notificación al Usuario, lo que ocurra primero.</p>
            <p>La adquisición y utilización de cada producto y/o servicio ofrecido por Vincor Online en el Sitio puede encontrarse sujeta a términos y condiciones de contratación particulares que, según los casos, sustituyen, complementan y/o modifican esta política. </p>
          
            <h4 className="h5 pb-3">9.1 Recolección de Datos  </h4>
            <p>El Usuario reconoce y acepta que Vincor Online podrá recolectar y almacenar la siguiente información acerca de los Usuarios que operan en el Sitio y/o se registran en la Plataforma y/o acceden a cualquier servicio y/o producto asociado a la Tecnología de Vincor Online: nombre, DNI, e-mail, domicilio, sexo, número de celular, fecha de nacimiento, nacionalidad, entre otros datos de identificación, dirección de IP, tipo de dispositivo utilizado, tipo de navegador web, sistema operativo y ubicación geográfica. </p>
            <p>Los Datos Personales de los Usuarios serán tratados en forma confidencial y con las debidas medidas de seguridad razonables, que se detallan a continuación. </p>

            <h4 className="h5 pb-3">9.2 Finalidades </h4>
            <p>Usted consiente expresamente que Vincor Online podrá utilizar los Datos Personales brindados por el Usuario en forma voluntaria o los Datos Personales descriptos con las siguientes finalidades, que incluyen:</p>
            <ul className="ml-5 tyc-list list-raya">
              <li className="pt-2"> -. Identificar a los Usuarios y a sus dispositivos; </li>
              <li className="pt-2"> -. Análisis, almacenamiento y tratamiento a dicha información, incluyendo su agrupación conforme a perfiles determinados con fines de control de calidad, marketing e investigación, promocionales, comerciales o publicitarios; </li>
              <li className="pt-2"> -. Brindarle información útil al Usuario y/o a otros Usuarios que se encontrarán colaborando en su entorno, facilitando así una mejor operatoria; </li>
              <li className="pt-2"> -. Envío de información promocional de productos y/o servicios de Vincor Online, a través del envío de newsletters u otro tipo de formatos, siempre con opción para el Usuario de darse de baja;  </li>
              <li className="pt-2"> -. Análisis y seguimiento del uso de Vincor Online y sus funcionalidades con fines de negocios, estadísticos o promocionales; y </li>
              <li className="pt-2"> -. Uso de diversas herramientas creadas por Vincor Online a fin de brindar los servicios de forma más eficaz.  </li>
            </ul>

            <h4 className="h5 pb-3">9.3 Cesión </h4>
            <p>El Titular de los datos presta su consentimiento expreso e informado para que Vincor Online pueda ceder sus Datos Personales a terceras partes con las que Vincor Online tenga relación de negocios u opere la Plataforma, operando el presente como consentimiento expreso para dicha cesión. </p>
            <p>En este sentido Vincor Online podrá revelar o transmitir los Datos Personales a terceros a los efectos y/o en el marco de procesos relacionados con la cesión, fusión, consolidación, reorganización societaria, venta de la totalidad o parte sustancial de sus activos o cuotas, adquisición de créditos y/o en el marco de cualquier proceso de auditoría interna propio de Vincor Online y/o de terceros.</p>
            <p>En todo momento el titular tendrá derecho a revocar su consentimiento para la cesión de sus Datos Personales, lo cual acarreará la suspensión inmediata del Servicio, toda vez que la cesión es indispensable para que Vincor Online pueda cumplir con las finalidades de la recolección de datos.  </p>
            

            <h4 className="h5 pb-3">9.4 Transferencia Internacional de Datos  </h4>
            <p>El Usuario brinda su consentimiento expreso e informado para que Vincor Online pueda transferir sus Datos Personales a las empresas a continuación listadas que los procesarán en calidad de encargadas de tratamiento (processors) con las finalidades indicadas en la siguiente lista. En particular, el Usuario entiende que estas empresas pueden estar y/o procesar los Datos Personales en jurisdicciones que no garantizan niveles adecuados de protección en los términos de la Ley de Protección de Datos Personales N.º 25.326 y la Disposición DNPDP 60-E/2016, consintiendo expresamente el Usuario la transferencia internacional y el procesamiento de sus Datos Personales en dichas jurisdicciones. Las empresas referidas en este párrafo son: </p>
            <p>Por último, se hace saber que los servicios arriba indicados se brindan con sujeción a los términos y condiciones disponibles en los sitios web de cada empresa listada en esta sección. </p>
          
            <h4 className="h5 pb-3">9.5 Confidencialidad de los Datos  </h4>
            <p>Vincor Online utilizará los Datos Personales provistos por el Usuario conforme a lo establecido en la presentes Política de Privacidad y a la normativa vigente, y no la divulgará salvo en cumplimiento de procedimientos legales tales como una orden de allanamiento, una orden judicial, o bien a requerimiento de autoridades públicas, legales o administrativas emanadas siempre de órganos competentes en la jurisdicción de la que se trate, y que puedan ser consideradas válidas bajo las leyes aplicables. Vincor Online podrá también revelar información personal de Usuarios en situaciones tales que comprometan la seguridad de Vincor Online ante un intento de ruptura, o de peligro físico o amenaza en contra suya o de otros Usuarios, de Vincor Online y/o de terceros. </p>
          
            <h4 className="h5 pb-3">9.6 Derecho de Acceso  </h4>
            <p>El Usuario que se registre en la Plataforma podrá acceder a sus datos de perfil directamente desde su cuenta de usuario en la Plataforma. En cuanto al resto de los Datos Personales que Vincor Online recolecta, para ejercer su derecho de acceso deberá enviarnos un correo electrónico a [legales@vincoronline.com] con el título “Derecho de Acceso” desde el correo que registró inicialmente y determinando el medio por el cual desea que le sea proporcionado dicho acceso (mail, correo postal, entrega en persona, teléfono).</p>

            <h4 className="h5 pb-3">9.7 Derecho de Rectificación   </h4>
            <p>Cuando algún Dato Personal de los recolectados por Vincor Online sea erróneo o se encuentre desactualizado, el Usuario podrá actualizarlo enviando un correo a [legales@vincoronline.com] y especificando los Datos Personales que deben ser rectificados. En cualquier momento Vincor Online puede requerirle que le envíe constancia de los Datos Personales que desea rectificar.  </p>

            <h4 className="h5 pb-3">9.8 Derecho de Supresión  </h4>
            <p>En todo momento el Usuario tendrá derecho a la supresión de sus Datos Personales. La supresión de los datos de un Usuario registrado acarreará la cancelación del Servicio. No obstante, Vincor Online podrá retener ciertos datos necesarios para la facturación de sus servicios por el término de 5 años para permitir auditorías. </p>

            <h4 className="h5 pb-3">9.9 Consentimiento   </h4>
            <p>El Usuario, al leer y aceptar la presente Política de Privacidad presta su consentimiento expreso e informado para la recolección y tratamiento de sus datos personales con el alcance y en los términos establecidos en la presente. </p>

            <h4 className="h5 pb-3">9.10 Legislación aplicable y órgano de control </h4>
            <p>Vincor Online cumple con la Ley 25.326 de Protección de Datos Personales y con las reglamentaciones aprobadas por la Dirección Nacional de Protección de Datos Personales dependiente de la Agencia de Acceso a la Información Pública, órgano de control de la Ley, donde el Titular de los datos podrá presentar denuncias por incumplimientos del responsable. Las bases de las que Vincor Online es responsable se encuentran registradas en el Registro Nacional de Bases de Datos, dependiente del mismo organismo. </p>

            <h4 className="h5 pb-3">9.11 Modificaciones</h4>
            <p>Vincor Online podrá modificar en forma periódica la presente política a efectos de reflejar cambios en el resto de los Términos y Condiciones de Uso y/o en la normativa aplicable e informar sobre la forma en la que procedemos respecto del uso de la información que Vincor Online almacena, incluir el tratamiento de nuevos temas, así como informar el tipo de tecnologías que Vincor Online utiliza en el tratamiento de sus Datos Personales. Ante cualquier modificación, Vincor Online pondrá al alcance del Usuario la información sobre los cambios efectuados y los Usuarios tendrán que volver a aceptar las nuevas políticas brindando nuevamente su consentimiento para el tratamiento de sus Datos Personales. En caso de que el Usuario no desee brindar el consentimiento ante una modificación de esta política, el mismo no podrá continuar utilizando el Servicio. </p>
            <p>Por cualquier consulta respecto de las presentes políticas, ponemos a disposición el siguiente medio de contacto: [legales@vincoronline.com]. Además, le hacemos saber que la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender denuncias y reclamos que interpongan quienes resulten afectado en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales. </p>
            
          </section>

          <section className="text-justify">
            <h2 className="h4 pb-3">10. Disposiciones generales </h2>
            <h4 className="h5 pb-3">10.1 Acuerdo completo </h4>
            <p>Los Términos y Condiciones de Uso y sus Políticas de Privacidad, y cualquier otro contrato o acuerdo que pudiera celebrar el Usuario con Vincor Online, expresan todos los términos convenidos entre dicho Usuario y Vincor Online, y reemplazan todo y cualquier acuerdo, compromiso o propuesta previos en relación con la Plataforma, Aplicación Móvil, y/o los productos y/o servicios contratados.  </p>
            <p>Queda establecido que los términos y condiciones particulares sobre la Plataforma, Aplicación Móvil, y/o los productos y/o servicios contratados de Vincor Online, si los hubiera, prevalecerán sobre los términos generales de estos Términos y Condiciones de Uso y sus Políticas de Privacidad. </p>
            
            <h4 className="h5 pb-3">10.2 Nulidad parcial  </h4>
            <p>Si alguna de las cláusulas de estos Términos y Condiciones de Uso resultará inválida, tal invalidez no acarreará la del resto de lo convenido, que permanecerá con plena vigencia y efecto. Las partes negociarán de buena fe una disposición de remplazo para replicar la intención de dicha disposición en la máxima medida permitida por la ley aplicable.  </p>


            <h4 className="h5 pb-3">10.3 Ley y Jurisdicción  </h4>
            <p>Los presentes Términos y Condiciones de Uso y sus Políticas de Privacidad se rigen por la Ley vigente en la República Argentina siendo de aplicación la Jurisdicción de los tribunales sitos en la Ciudad Autónoma de Buenos Aires. </p>

            <h4 className="h5 pb-3">10.4 Cómo contactarnos </h4>
            <p>Para contactarse con nosotros puede hacerlo a través de nuestro correo electrónico [consulta@vincoronline.com]. </p>
          </section>

          

        </CardBody>
      </Card>
    </WidthLimit>
  )
}

export default TyCInter;